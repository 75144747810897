export const hubsInterface = {
  getIPFromAmazon() {
    fetch("https://checkip.amazonaws.com/")
      .then((res) => res.text())
      .then((data) => console.log(data));
  },

  async submitForm(e, formId, persona, message, firstname, lastname, company) {
    var formId = "511e6835-e65d-45b6-8f1e-fa90e2af7ced"; //override form id and use default form
    var portalId = "24367611";
    var xhr = new XMLHttpRequest();
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/" +
      portalId +
      "/" +
      formId;

    // Example request JSON:

    var data = {
      submittedAt: new Date(),

      fields: [
        {
          name: "email",
          value: e,
        },
        {
          name: "hs_persona",
          value: "Website Lead",
        },
        {
          name: "tenant_type",
          value: "website_lead",
        },
        {
          name: "company",
          value: "",
        },
        {
          name: "stripe_customer_id",
          value: "",
        },
        {
          name: "name",
          value: "",
        },
        {
          name: "firstname",
          value: "",
        },
        {
          name: "lastname",
          value: "",
        },
      ],
      context: {
        //hutk: ":hutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: "www.breachlink.com",
        pageName: persona,
        // ipAddress: this.getIPFromAmazon(),
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text: "I agree to allow Breachlink to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Breachlink.",
            },
          ],
        },
      },
    };

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.status === 200) {
        console.log("Hubspot success", response.status);

        return response.text;
      } else {
        console.error("Hubspot Error: ", response);
        return response;
      }
    });
  },
};
