/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useState } from "react";
import headshotOran from "../images/oran.jpg";
import headshotJoel from "../images/joel.jpg";
import headshotOzzie from "../images/ozzie.jpg";

const people = [
  {
    name: "Joel Heft",
    role: "Co-Founder & Chief Executive Officer",
    description:
      "Joel Heft, our CEO, brings over 25 years of experience from Equifax. As SVP of Global Breach and Regulatory Services, he managed all aspects of the business. He also served as SVP - General Counsel - International, overseeing Legal, Regulatory, and Government Relations in 18 countries, leading a team of 24 professionals.",
    imageUrl: headshotJoel,
    // "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    twitterUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/joel-heft-5773b22/",
  },
  {
    name: "Oran Sears",
    role: "Co-Founder & Chief Technical Advisor",
    description:
      "Oran Sears is a Sr. Director, Engineering at Relativity with over 20 years of experience in eDiscovery . As Co-Founder and former CTO of Canopy, he holds 7 patents. A seasoned entrepreneur, Oran is also a speaker, panelist, startup advisor, and mentor, advising Breachlink on its technology and growth path. ",
    imageUrl: headshotOran,
    // "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    twitterUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/oransears/",
  },
  {
    name: "Ozzie Fonseca",
    role: "Co-Founder & Chief Revenue Officer",
    description:
      "Ozzie Fonseca is a data breach response veteran with 20 years of experience. As Sr. Director at Experian Data Breach Resolution, he led breach response for over 55,000 events, setting up call centers and providing identity protection. Ozzie has spoken at major industry conferences and is a published author on Data Breach.",
    imageUrl: headshotOzzie,
    // "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    twitterUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/databreachresponse/",
  },
  // More people...
];

export default function Team() {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1023);

  const [hoveredPerson, setHoveredPerson] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1023);
      console.log(
        "window.innerWidth",
        window.innerWidth,
        window.innerWidth > 1023
      );
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <div className=" bg-white">
      <div className=" mx-auto max-w-7xl py-12 px-4 text-center py-12">
        <div className=" space-y-24">
          <ul
            role="list"
            className="h-full py-8 mx-auto space-y-16 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 lg:gap-16 lg:space-y-0 lg:max-w-5xl "
          >
            {people.map((person) => (
              <li
                key={person.name}
                onMouseEnter={() =>
                  isLargeScreen && setHoveredPerson(person.name)
                }
                onMouseLeave={() => setHoveredPerson(null)}
                className="lg:flip-container  w-full relative h-full col-span-full lg:col-span-1"
              >
                <div
                  className={`flipper  ${
                    hoveredPerson === person.name && isLargeScreen
                      ? "lg:do-flip"
                      : ""
                  } h-full`}
                >
                  {hoveredPerson !== person.name && (
                    <div id="card-front" className="">
                      <img
                        className="mx-auto h-40 w-40 rounded-full lg:h-56 lg:w-56"
                        src={person.imageUrl}
                        alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>{person.name}</h3>
                          <p className="text-indigo-600">{person.role}</p>
                        </div>
                        <ul
                          role="list"
                          className="flex justify-center space-x-5"
                        >
                          <li className="">
                            <a
                              href={person.linkedinUrl}
                              target="_blank"
                              className="text-gray-700 hover:text-gray-800"
                            >
                              <span className="sr-only">LinkedIn</span>
                              <span className="flex items-center ">
                                <span className="ml-2">Read Bio &rarr;</span>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {hoveredPerson === person.name && isLargeScreen && (
                    <div
                      id="card-back"
                      className="  items-start bg-white shadow-lg border border-gray-100 rounded-lg p-6 w-full h-full"
                    >
                      <div
                        id="card-back-content"
                        className="overflow-hidden flex flex-col  space-y-2 justify-between p-6 rotate-y-180"
                      >
                        <div className="">{person.description}</div>
                        <div className="flex flex-row justify-end items-end">
                          <a
                            href={person.linkedinUrl}
                            target="_blank"
                            className="text-indigo-700 hover:text-indigo-800"
                          >
                            <span className="sr-only">LinkedIn</span>
                            <span className="flex items-center ">
                              <svg
                                className="h-5 w-5 flex"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                  clipRule="evenodd"
                                />
                              </svg>{" "}
                              <span className="ml-2">Learn more &rarr;</span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* <div className="mt-12">Learn more about our company</div> */}
      </div>
    </div>
  );
}
