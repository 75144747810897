import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/20/solid";
import OnboardingImage from "../images/onboarding-image.jpg";
import OnboardingDecoration from "../images/auth-decoration.png";
import { _onboarding_options } from "../modules/_onboarding_options";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function StackedList(props) {
  const title = props.title;
  const options = props.options;
  const [selectedOption, setselectedOption] = useState(options[0]);
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto bg-white">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            {title}
          </h1>
        </div>
      </div>

      {/* Content */}
      {/* <div className="max-w-md mx-auto">
        <div className="min-h-screen h-full flex flex-col after:flex-1">
          <div className="m-1.5"> */}
      {/* Modal content */}
      <div className="px-5 pt-4 pb-1 max-w-md mx-auto">
        <div className="text-sm">
          <div className="mb-4">Select an action or click "finish".</div>
          {/* Options */}
          <ul className="space-y-2 mb-4">
            {/* {options.map((option) => (
                    <li>
                      <button className="w-full h-full text-left py-3 px-4 rounded bg-white border-2 border-primary-400 shadow-sm duration-150 ease-in-out">
                        <div className="flex items-center">
                          <div className="w-4 h-4 border-4 border-primary-500 rounded-full mr-3"></div>
                          <div className="grow">
                            <div className="flex flex-wrap items-center justify-between mb-0.5">
                              <span className="font-medium text-slate-800">
                                {option.name}
                              </span>
                            </div>
                            <div className="text-sm">{option.description}</div>
                          </div>
                        </div>
                      </button>
                    </li>
                  ))} */}

            <li>
              <button className="w-full h-full text-left py-3 px-4 rounded bg-white border-2 border-primary-400 shadow-sm duration-150 ease-in-out">
                <div className="flex items-center">
                  {/* <div className="w-4 h-4 border-4 border-primary-500 rounded-full mr-3"></div> */}
                  <div className="grow">
                    <div className="flex flex-wrap items-center justify-between mb-0.5">
                      <span className="font-medium text-slate-800">
                        Invite Collaborators
                      </span>
                    </div>
                    <div className="text-sm">
                      Invite others to manage your company's account.
                    </div>
                  </div>
                </div>
              </button>
            </li>
            <li>
              <button className="w-full h-full text-left py-3 px-4 rounded bg-white border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out">
                <div className="flex items-center">
                  {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                  <div className="grow">
                    <div className="flex flex-wrap items-center justify-between mb-0.5">
                      <span className="font-semibold text-slate-800">
                        Enable MFA
                        <span className="text-xs italic text-primary-500 align-top">
                          Important
                        </span>
                      </span>
                    </div>
                    <div className="text-sm">
                      Configure multi-factor authentication
                    </div>
                  </div>
                </div>
              </button>
            </li>
            <li>
              <button className="w-full h-full text-left py-3 px-4 rounded bg-white border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out">
                <div className="flex items-center">
                  {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                  <div className="grow">
                    <div className="flex flex-wrap items-center justify-between mb-0.5">
                      <span className="font-semibold text-slate-800">
                        Go to settings
                      </span>
                    </div>
                    <div className="text-sm">
                      Check out all the other settings.
                    </div>
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default StackedList;
