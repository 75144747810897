import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  FlagIcon,
  ShoppingCartIcon,
} from "@heroicons/react/20/solid";
import { Paragraph } from "../components/Paragraph";
import { HeadingTwo } from "../components/HeadingTwo";
import { Icon } from "@iconify/react";
import { getThemeStyles } from "../modules/_themeStyles";

const features = [
  {
    name: "Law Firms",
    description:
      "Commodo nec sagittis tortor mauris sed. Turpis tortor quis scelerisque diam id accumsan nullam tempus. Pulvinar etiam lacus volutpat eu. Phasellus praesent ligula sit faucibus.",
    href: "#",
    icon: "ri-group-fill",
  },
  {
    name: "Insurance Carriers",
    description:
      "Pellentesque enim a commodo malesuada turpis eleifend risus. Facilisis donec placerat sapien consequat tempor fermentum nibh.",
    href: "#",
    icon: "ri:shield-user-fill",
  },
  {
    name: "Corporations",
    description:
      "Commodo nec sagittis tortor mauris sed. Turpis tortor quis scelerisque diam id accumsan nullam tempus. Pulvinar etiam lacus volutpat eu. Phasellus praesent ligula sit faucibus.",
    href: "#",
    icon: "ri:building-2-fill",
  },
  // {
  //   name: "Service Providers",
  //   description:
  //     "Pellentesque sit elit congue ante nec amet. Dolor aenean curabitur viverra suspendisse iaculis eget. Nec mollis placerat ultricies euismod ut condimentum.",
  //   href: "#",
  //   icon: "ri:shake-hands-fill",
  //   // <Icon icon="ri:shake-hands-fill" />
  // },
  // {
  //   name: "Go fishing",
  //   description:
  //     "Commodo nec sagittis tortor mauris sed. Turpis tortor quis scelerisque diam id accumsan nullam tempus. Pulvinar etiam lacus volutpat eu. Phasellus praesent ligula sit faucibus.",
  //   href: "#",
  //   icon: CloudArrowUpIcon,
  // },
  // {
  //   name: "Take a vacation",
  //   description:
  //     "Pellentesque enim a commodo malesuada turpis eleifend risus. Facilisis donec placerat sapien consequat tempor fermentum nibh.",
  //   href: "#",
  //   icon: LockClosedIcon,
  // },
  // {
  //   name: "Find more work",
  //   description:
  //     "Pellentesque sit elit congue ante nec amet. Dolor aenean curabitur viverra suspendisse iaculis eget. Nec mollis placerat ultricies euismod ut condimentum.",
  //   href: "#",
  //   icon: ArrowPathIcon,
  // },
];

export default function SectionDivider({
  title,
  description,
  variant,
  bgColor,
  textSize,
}) {
  const {
    backgroundColor,
    gridCols,
    titleTextColor,
    headerTextColor,
    descriptionTextColor,
    featureHeaderTextColor,
    featureDescriptionTextColor,
  } = getThemeStyles(variant, bgColor);
  return (
    <>
      {" "}
      {/* <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
        <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
          Meet the Team Behind Breachlink
        </h2>
        <p className="text-xl text-gray-500">
          At Breachlink, our mission is to revolutionize the legal industry's
          approach to data breach response and eDiscovery. Meet the dedicated
          team driving innovation and excellence.{" "}
        </p>
      </div> */}
      <div className={`  ${backgroundColor} pb-6 pt-12`}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p
              className={`mt-2 ${
                textSize == "md" ? " text-lg " : " text-3xl "
              }  font-semibold tracking-tight ${headerTextColor} sm:text-4xl`}
            >
              {title}
            </p>
            <p
              className={`mt-6 text-xl leading-8 ${descriptionTextColor} max-w-none`}
            >
              {description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
