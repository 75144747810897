/* This example requires Tailwind CSS v2.0+ */

import { getThemeStyles } from "../modules/_themeStyles";

export default function StatsLarge({
  title,
  header,
  description,
  data,
  variant,
  orientation,
  backgroundImage,
  layout,
  gradient,
  featureImage,
  bgColor,
  showBackground,
  showCTA,
  ctaText,
  ctaClick,
}) {
  const {
    backgroundColor,
    gridCols,
    titleTextColor,
    headerTextColor,
    descriptionTextColor,
    featureHeaderTextColor,
    featureDescriptionTextColor,
  } = getThemeStyles(variant, bgColor);

  return (
    <div className={`relative ${backgroundColor} py-12`}>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-1 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pb-24 xl:col-start-1 ">
          <h2
            className={`text-sm font-semibold ${titleTextColor} tracking-wide uppercase`}
          >
            {title}
          </h2>
          <p className={`mt-4 mb-4 text-3xl font-extrabold ${headerTextColor}`}>
            {header}
          </p>
          <p className={`mb-12 text-lg ${descriptionTextColor}`}>
            {description}
          </p>
          <div
            id="ptrBanner"
            className=" grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2"
          >
            {data.map((item) => (
              <p key={item.id}>
                <span
                  className={`block text-2xl font-bold ${featureHeaderTextColor}`}
                >
                  {item.stat}
                </span>
                <span
                  className={`mt-1 block text-base ${featureDescriptionTextColor}`}
                >
                  <span className="font-medium text-white">
                    {item.emphasis}
                  </span>{" "}
                  {item.rest}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className="items-center  w-full">
        <div className="justify-center  text-center inline-flex rounded-md  w-full">
          <a
            href={ctaClick}
            className="bg-opacity-90 w-full hover:w-full md:w-1/2 lg:w-1/3 lg:hover:w-1/2 inline-flex items-center justify-center px-5 py-3  text-base font-medium rounded-full text-white text-opacity-80 bg-brandGreen-500 hover:bg-brandGreen-600  transition-all duration-300"
          >
            {ctaText}
            {/* <ExternalLinkIcon
                  className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                /> */}
          </a>
        </div>
      </div>
      {/* <div className="h-full w-full xl:grid xl:grid-cols-1">
        <img
          className="object-cover opacity-20  w-full h-full xl:absolute xl:inset-0 top:0 left-0 right-0 bottom-0"
          src="https://unsplash.com/photos/r1cu0Uj13Iw/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8N3x8bGlnaHRidWxifGVufDB8MHx8fDE3MjA0NzczNTJ8Mg&force=true&w=1920"
          alt=""
        />
        <div className="absolute inset-0 h-full w-full" />
      </div> */}
    </div>
  );
}
