import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";

const people = [{ name: "Collab", _id: "1", area: "Culture" }];

export default function (props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState(
    props.value ? props.value : []
  );
  const label = props.label;
  const people = props.options;
  const defaultValue = props.defaultValue;
  const value = props.value;
  const fieldName = props.name;
  const fieldType = props.fieldType; //multiple or single

  const dropdown = useRef(null);
  // close if the esc key is pressed

  console.log("Options", people, "selectedPersons", selectedPersons);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!isOpen || dropdown.current.contains(target)) return;
      setIsOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  function isSelected(value) {
    if (fieldType && fieldType == "single") {
      return selectedPersons == value ? true : false;
    }
    return selectedPersons
      ? selectedPersons.find((el) => el === value)
        ? true
        : false
      : false;
  }

  useEffect(() => {
    setSelectedPersons(props.value);
  }, []);

  // useEffect(() => {
  //   // console.log("handleFieldChange useEffect", selectedPersons, fieldName);
  //   props.handleSelect(selectedPersons, fieldName);
  // }, [selectedPersons]);

  function handleSelect(val) {
    console.log("handleSelect", val);
    let value;
    let id;
    let display;
    if (typeof val == "object") {
      id = val[props.idField];
      value = val[props.valueField];
      display = val[props.displayField];
    } else {
      value = val;
    }

    console.log("handleSelect Multi", value);

    if (fieldType && fieldType == "single") {
      setSelectedPersons(display);
      props.handleSelect(val);
      setIsOpen(false);
      return;
    } else {
      let name = fieldName;
      console.log("Selected value: ", name, value);
      if (!isSelected(value)) {
        const selectedPersonsUpdated = [
          ...selectedPersons,
          people.find((el) => el === value),
        ];
        setSelectedPersons(selectedPersonsUpdated);
      } else {
        handleDeselect(value);
      }
      props.handleSelect(selectedPersons, name);
    }
  }

  function handleDeselect(value) {
    const selectedPersonsUpdated = selectedPersons.filter((el) => el !== value);
    console.log("handeSelect deSelect", selectedPersonsUpdated);
    setSelectedPersons(selectedPersonsUpdated);
    setIsOpen(true);
  }
  // close on click outside

  return (
    // <div className="flex items-center justify-center">
    //   <div className="w-full max-w-xs mx-auto">
    <Listbox
      //   as="div"
      //   name={props.name}
      key={props.name}
      className="z-0 space-y-1 cursor-pointer"
      value={selectedPersons}
      onChange={(value) => handleSelect(value)}
      open={isOpen}
    >
      {() => (
        <>
          {/* <Listbox.Label className=" mt-1 block text-sm leading-5 font-medium text-slate-700">
            {label}
          </Listbox.Label> */}
          <div
            id="dropdown"
            ref={dropdown}
            className="z-50 relative mt-1 w-full"
          >
            <span className="z-0 inline-block w-full  ">
              <Listbox.Button
                className={`z-0 cursor-default relative w-full 
                rounded-md border border-gray-300
                hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-secondary-500 hover:border-secondary-500
                bg-white pl-3 pr-10 py-2 text-left 
                cursor-pointer
                ${isOpen ? "border-secondary-500  " : "border-gray-300"}
               transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                onClick={() => setIsOpen(!isOpen)}
                open={isOpen}
              >
                <span className="z-0 block truncate ">
                  {((Array.isArray(selectedPersons) == false &&
                    selectedPersons == "") ||
                    selectedPersons == null) &&
                    `Select ${label}`}

                  {selectedPersons &&
                  Array.isArray(selectedPersons) &&
                  selectedPersons.length < 1
                    ? `Select ${label}`
                    : ` ${
                        selectedPersons && Array.isArray(selectedPersons)
                          ? selectedPersons.length + " Selected"
                          : selectedPersons
                      }`}
                </span>
                <span className="z-0 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <svg
                    className="h-5 w-5 text-slate-400"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Listbox.Button>
            </span>

            <Transition
              unmount={false}
              show={isOpen}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-0"
              leaveTo="opacity-0"
              className="z-50 absolute mt-1 w-full rounded-md bg-white shadow-lg"
            >
              <Listbox.Options
                static
                // onBlur={() => setIsOpen(false)}
                onMouseLeave={() => setIsOpen(false)}
                className="z-40 max-h-60 rounded-md py-1 text-base leading-6 shadow-lg overflow-auto focus:outline-none sm:text-sm sm:leading-5"
              >
                {people.map((person) => {
                  const selected = isSelected(person);
                  return (
                    <Listbox.Option key={person} value={person}>
                      {({ active }) => (
                        <div
                          className={`${
                            active
                              ? "text-white bg-secondary-600"
                              : "text-slate-800"
                          } cursor-default select-none relative py-2 pl-8 pr-4`}
                        >
                          <span
                            className={`${
                              selected ? "font-semibold" : "font-normal"
                            } block truncate`}
                          >
                            {person["Team Name"]}
                          </span>
                          {selected && (
                            <span
                              className={`${
                                active ? "text-white" : "text-primary-600"
                              } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                            >
                              <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
            <div className="pt-1 text-sm">
              {selectedPersons &&
                selectedPersons.length > 0 &&
                Array.isArray(selectedPersons) && (
                  <>Selected: {selectedPersons.join(", ")}</>
                )}
            </div>
          </div>
        </>
      )}
    </Listbox>
    //   </div>
    // </div>
  );
}
