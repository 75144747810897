/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect } from "react";
import { useRef } from "react";
import React, { useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import Fade from "react-reveal/Fade";

import Logo from "../images/dsulogo.jpg";
import HeroImage from "../images/hero-cover1.jpg";
import cmapScreenshot1 from "../images/screenshot-breachlink-templates.png";
import cmapScreenshot2 from "../images/Screenshot-Compare.png";
import cmapScreenshot3 from "../images/screenshot-breachlink-comments.png";
import cmapScreenshot4 from "../images/screenshot-breachlink-marketplace.png";

//vendor logos
import epiq from "../images/logos/vendors/epiq.png";
import kroll from "../images/logos/vendors/kroll.png";
import integreon from "../images/logos/vendors/newIntegreon.png";
import consilio from "../images/logos/vendors/consilio.png";

import { ChevronDownIcon } from "@heroicons/react/solid";
import { Hero } from "../parts/Hero";
import { FormBanner } from "../parts/FormBanner";
import HubsForm from "../components/Hubsform";
import { SmallBanner } from "../parts/SmallBanner";
import { Header } from "../parts/Header";
import { ThreePartFeature } from "../parts/ThreePartFeature";
import FeatureGrid from "../parts/FeatureGrid";
import CenterBrandedCTA from "../parts/CenterBrandedCTA";
import TeamSmallPictures from "../parts/TeamSmallPictures";
import StatsLarge from "../parts/StatsLargeDark";
import FeatureList from "../parts/FeatureList";
import CTASplitWithImage from "../parts/CTASplitWithImage";
import SinglePriceWithDetails from "../parts/SinglePriceWithDetails";
import PartnerForm from "../parts/FormBannerPartner";
import ModalBasic from "../components/ModalBasic";
import NotificationBanner from "../components/NotificationBanner";
import PricingModule from "../parts/PricingModule";
import { _pricing } from "../modules/pricing";

import ThreeColumnOnDark from "../parts/ThreeColumnOnDark";
import LogoCloudWithCTA from "../parts/LogoCloudWithCTA";
import BenefitsWithBackground from "../parts/BenefitsWithBackground";
import SecondaryFeatures from "../parts/SecondaryFeatures";
import TestimonialsGrid2 from "../parts/TestimonialsGrid2";
import Team from "../parts/Team";
import SectionDivider from "../parts/SectionDivider";
import DevNote from "../components/DevNote";
import { _auth } from "../modules/_auth";
import TestimonialBanner from "../parts/TestimonialBanner";

// import { exec } from "child_process";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Home() {
  //set profile role to "user"
  _auth.setUserProfile({ role: "user" });
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const pricing = _pricing.getPricing();
  const setModalOpen = () => {
    setShowModal(false);
  };

  const handleBannerClose = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    setOpen(false);
  };
  // const hubsForm = useRef();
  const executeScroll = (e) => {
    // alert("scrolling to " + e);
    console.log("scrolling to ", e);
    const element = document.getElementById(e);
    element.scrollIntoView({
      alignToTop: true,
      behavior: "smooth",
      // block: "nearest",
      inline: "start",
    });
  };

  return (
    <div className="bg-white">
      {open && (
        <NotificationBanner
          className={"sticky top-10 z-100"}
          setOpen={(e) => handleBannerClose(e)}
          showClose={true}
          text={"Ask us about our services!"}
        />
      )}
      <main className="flex flex-col">
        <div id="top">
          <Hero
            // title="Empowering Legal with Simplicity "
            title="Connecting the Legal Industry for Better Outcomes"
            subtitle="Breachlink optimizes data breach response and eDiscovery by connecting law firms, insurers, and corporations with top service providers."
            // subtitle="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly."
            image={HeroImage}
            // description="We're enabling Data Breach and eDiscovery by connecting law firms, insurers, and corporations with world-class service providers."
            buttons=""
            executeScroll={executeScroll}
            setShowModal={setShowModal}
          />
        </div>
        <div id="overview">
          {" "}
          <Fade id="breachlinkForEveryone">
            <ThreeColumnOnDark
              title={"Breachlink for Everyone"}
              description={
                "At Breachlink, we're transforming the data breach response and eDiscovery processes for law firms, insurance companies, corporations, and service providers. Our platform is designed to optimize costs, enhance quality, and save you precious time by streamlining the procurement process. Whether you are a law firm seeking to optimize your vendor management, an insurer looking to enhance efficiency and transparency, or a corporation managing complex legal and vendor relationships, Breachlink provides tailored solutions to meet your needs."
              }
              variant={"light"}
              features={[
                {
                  name: "Law Firms",
                  description:
                    "Breachlink saves you time and money. Compare bids, track statuses, and perform due diligence with ease.",
                  href: "#",
                  icon: "hugeicons:justice-scale-02",
                },
                {
                  name: "Insurance Carriers",
                  description:
                    "Breachlink helps insurers oversee the bid process with greater efficiency and transparency. ",
                  href: "#",
                  icon: "ri:shield-user-fill",
                },
                {
                  name: "Corporations",
                  description:
                    "Use Breachlink like a law firm to streamline your processes or like an insurer to gain real-time insights.",
                  href: "#",
                  icon: "ri:building-2-fill",
                },
              ]}
            />
          </Fade>{" "}
        </div>

        <Fade id="small-banner-create-in-seconds">
          <div className={`  bg-slate-800 py-12`}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-7xl text-center">
                <p
                  className={`mt-2 text-4xl  font-semibold tracking-tight text-white`}
                >
                  <div className=" text-opacity-80 ">
                    Stop digging through emails and making spreadsheets.
                  </div>

                  <div className="text-brandGreen-500">
                    Let Breachlink do the work for you.
                  </div>
                </p>
              </div>
            </div>
          </div>
          {/* <SmallBanner description="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly." /> */}
        </Fade>

        <Fade id="testimonial-banner">
          <TestimonialBanner />
        </Fade>
        {/* <Fade id="logoCloud">
          <div>
            <DevNote>
              We need to find some law firms who have used us to let us put
              their logos up. Ideally without us making a concession.
            </DevNote>
            <LogoCloudWithCTA
              title="Trusted by the world’s most innovative legal teams"
              showCTA
              ctaClick={() => {
                // alert("clicked");
                executeScroll("testimonials");
              }}
              ctaTitle={"We're used by over 50 law firms"}
              ctaText={"See our customer stories"}
              logos={[
                {
                  src: epiq,
                  alt: "Epiq",
                  width: 158,
                  height: 48,
                },
                {
                  src: kroll,
                  alt: "Kroll",
                  width: 158,
                  height: 48,
                },
                {
                  src: integreon,
                  alt: "Integreon",
                  // width: 158,
                  // height: 48,
                },
                {
                  src: consilio,
                  alt: "Consilio",
                  width: 158,
                  height: 48,
                },
                // {
                //   src: "https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg",
                //   alt: "Statamic",
                //   width: 158,
                //   height: 48,
                // },
              ]}
            />
          </div>
        </Fade> */}
        <div id="solutions"> </div>
        <Fade id="section-law-benefits">
          <div id="lawFirms">
            {" "}
            {/* <DevNote>Not the final layout. Looking at better options</DevNote> */}
            <BenefitsWithBackground
              id={"lawFirms"}
              title={"Law Firms"}
              header={
                "Maximize Efficiency and Quality in Your Vendor Management"
              }
              orientation="normal"
              description={
                "Breachlink saves you time and money. Compare bids, track statuses, and perform due diligence with ease."
              }
              data={[
                {
                  name: "Optimize Costs",
                  description:
                    "Encourage competitive bids to achieve the best value for your investments.",
                },
                {
                  name: "Improve Quality",
                  description:
                    "Access a marketplace of vetted, high-quality vendors.",
                },
                {
                  name: "Easy Comparisons",
                  description:
                    "Side-by-side bid comparisons to make informed decisions.",
                },
                {
                  name: "Collaborate",
                  description: "Collaborate with your team in real-time.",
                },
                {
                  name: "Tracking and Reporting",
                  description:
                    "Dashboards and reports for comprehensive bid tracking and analysis.",
                },
                {
                  name: "Vendor Flexibility",
                  description:
                    "Enjoy the freedom to choose the best vendor for each project, ensuring you always have the right partner.",
                },
              ]}
              layout="grid"
              // orientation="left"
              backgroundColor={"bg-white"}
              gradient={false}
            />
          </div>
        </Fade>
        <Fade id="section-corp-benefits">
          <div id="corporate">
            {" "}
            <BenefitsWithBackground
              id={"corp-BidManagement"}
              variant="light"
              title={"Corporate Legal"}
              header={
                "Optimize Your Legal Spend and Vendor Management Processes."
              }
              orientation="reverse"
              description={
                "Whether insured or not, corporations can benefit from Breachlink's robust platform for managing legal and vendor relationships. Use Breachlink like a law firm to streamline your processes or like an insurer to gain real-time insights."
              }
              data={[
                {
                  name: "Efficiency",
                  description:
                    "Manage bids, track statuses, and perform due diligence with an all-in-one platform.",
                },
                {
                  name: "Quality Assurance",
                  description:
                    "Access a network of vetted vendors to ensure high standards.",
                },
                {
                  name: "Cost Management",
                  description:
                    "Optimize your spending by encouraging competitive bidding.",
                },
                {
                  name: "Real-Time Access",
                  description:
                    "Utilize Breachlink Connect to view bids in real-time, saving time and ensuring better decision-making.",
                },
                {
                  name: "Transparency",
                  description:
                    "Maintain clear visibility into the bid process, enhancing compliance and quality control.",
                },
                {
                  name: "Collaboration",
                  description:
                    "Work seamlessly with your legal and procurement teams to select the best vendors.",
                },
              ]}
              backgroundColor={"bg-gray"}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
            />
          </div>
        </Fade>
        <Fade id="section-insurance-benefits">
          <div id="insurance">
            {" "}
            <BenefitsWithBackground
              id={"insurance-benefits"}
              title={"Insurance Carriers"}
              header={"Enhance Efficiency and Transparency in Bid Management."}
              orientation="normal"
              description={
                "Breachlink helps insurers oversee the bid process with greater efficiency and transparency. By ensuring attorneys use Breachlink, you gain real-time insights into bids and can make more informed decisions faster."
              }
              data={[
                {
                  name: "Real-Time Monitoring",
                  description:
                    "With Breachlink Connect, view bids across law firms as they come in, saving time and enhancing decision-making.",
                },
                {
                  name: "Cost Efficiency",
                  description:
                    "Streamline the process to reduce administrative overhead and focus on selecting the best vendors.",
                },
                {
                  name: "Improved Oversight",
                  description:
                    "Gain transparency into the bidding process, ensuring compliance and quality.",
                },
                {
                  name: "Collaborate Seamlessly",
                  description:
                    "Collaborate in real-time with your panel counsel to select the best vendors.",
                },
              ]}
              layout={"grid"}
              backgroundColor={"bg-gray"}
              gradient={false}
            />
          </div>
        </Fade>
        {/* FEATURE SECTION */}
        <div id="features"> </div>
        <div id="all-features-div" className="py-12">
          <Fade id="section-features">
            <div id="" className="">
              {" "}
              {/* <DevNote>
                Need better screenshots. Cannot keep all features. Need to
                choose which ones to keep.
              </DevNote>{" "} */}
              <SectionDivider
                title={"Powerful features that put you in control"}
                description={
                  "Explore the unique features of Breachlink tailored for law firms, insurers, and corporations. From bid request templates to bid comparison, Breachlink offers a comprehensive suite of tools to streamline your procurement process."
                }
                variant={"light"}
              />
              <BenefitsWithBackground
                id={"feature-1"}
                variant="light"
                title={"BID REQUEST TEMPLATES"}
                header={"Create bid requests in seconds."}
                orientation="reverse"
                description={
                  "Not sure which questions to ask?  We've done the work for you!  With templates for every use, we provide all the questions needed to get you the bids you need in the most detailed way possible."
                }
                data={[]}
                backgroundColor={"bg-gray"}
                layout="grid"
                gradient={false}
                backgroundImage={{
                  src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                  alt: "People working on laptops",
                }}
                featureImage={cmapScreenshot1}
              />
            </div>
          </Fade>
          <Fade id="section-features-2">
            <BenefitsWithBackground
              id={"feature-2"}
              variant="light"
              title={"BID COMPARE"}
              header={"Compare bids side-by-side to make informed decisions."}
              orientation="normal"
              description={
                "Gone are the days of digging through emails and deciphering quotes.  We give you apples to apples bid comparison made easier by our unique approach to vendor bids.  We put in the work to ensure that all bids meet minimum guidelines so you can focus on making the decisions."
              }
              data={[]}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot2}
              // bgColor={"gray-100"}
            />
          </Fade>
          {/* <Fade id="section-feature-3">
            <BenefitsWithBackground
              id={"corp-BidManagement"}
              variant="light"
              title={"VENDOR MANAGEMENT"}
              header={"Streamline your vendor management"}
              orientation="reverse"
              description={
                "From NDA's to supporting documentation, we make vendor management a breeze.  Need to update your requests. Check.  Need to message everyone?  Check.  Need to notify vendors they lost? Check. Breachlink makes it easy to manage your vendors."
              }
              data={[]}
              bg-color={"bg-gray-200"}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot3}
            />
          </Fade> */}
          {/* <Fade id="section-features-0">
            <BenefitsWithBackground
              id={"feature-0-realtime-bid-monitoring"}
              variant="light"
              title={"BID MONITORING"}
              header={"Real-Time Bid Monitoring."}
              orientation="normal"
              description={
                "Keep track of all your bids in real time, ensuring you never miss a crucial update."
              }
              data={[]}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot2}
              // bgColor={"gray-100"}
            />
          </Fade> */}
          <Fade id="section-features-00">
            <BenefitsWithBackground
              id={"feature-0-realtime-bid-monitoring"}
              variant="light"
              title={"COLLABORATION"}
              header={"Interactive Q&A"}
              orientation="reverse"
              description={
                "Engage with vendors through a built-in Q&A feature to clarify bids and make informed decisions."
              }
              data={[]}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot3}
              // bgColor={"gray-100"}
            />
          </Fade>
        </div>
        <div>
          <Fade id="small-banner-create-in-seconds">
            <div className={`  bg-slate-800 py-12`}>
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-7xl text-center">
                  <p
                    className={`mt-2 text-4xl  font-semibold tracking-tight text-white`}
                  >
                    <div className=" text-opacity-80 ">
                      {" "}
                      Create bid requests in seconds. Get bids in minutes.{" "}
                    </div>

                    <div className="text-brandGreen-500">
                      Compare them effortlessly.
                    </div>
                  </p>
                </div>
              </div>
            </div>
            {/* <SmallBanner description="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly." /> */}
          </Fade>
        </div>
        <div className="">
          <Fade id="section-marketplace-list">
            <div className="">
              {/* <DevNote>
                Need to update our T's & C's to say we can use their logo and
                give them the ability to opt out and not receive bid requests.
              </DevNote>{" "} */}
              <SectionDivider
                title={"A marketplace beyond Data Breach and eDiscovery."}
                description={
                  "The Breachlink marketplace is designed to connect you with the best service providers in the industry. From preparedness and training to litigation support, our marketplace offers a wide range of categories to meet your needs."
                }
                variant={"light"}
                // bgColor={"gray-100"}
              />
              <FeatureList
                id={"marketplace"}
                title={""}
                header={""} //A marketplace beyond Data Breach and eDiscovery.
                description={""} //Breachlink started with post-data breach service providers.  But we've expanded to include breach preparedness as well as litigation support. Check out just a few of the categories featured in our marketplace:
                features={[
                  {
                    name: "Preparedness & Training",
                    description: "",
                  },
                  {
                    name: "Managed Detection & Response",
                    description: "",
                  },
                  {
                    name: "Forensics & Ransomware",
                    description: "",
                  },
                  {
                    name: "Data Mining & Document Review",
                    description: "",
                  },
                  {
                    name: "Notification & Identity Services",
                    description: "",
                  },
                  {
                    name: "eDiscovery & Litigation Support",
                    description: "",
                  },
                ]}
                variant={"light"}
                // bgColor={"gray-100"}
              />
              {/* <div className="bg-gray-100 px-24 pb-24">
                <img
                  src={cmapScreenshot4}
                  alt="hero"
                  className="shadow-xl rounded-3xl"
                />
              </div> */}
            </div>
          </Fade>
        </div>
        <Fade id="logoCloud">
          <div className="relative">
            <LogoCloudWithCTA
              title="
                Here are a few of our featured vendors."
              showCTA={false}
              ctaTitle={
                "Our marketplace currently has over 50 world-class service providers and gorowing every day."
              }
              ctaText={"Check out the full marketplace"}
              logos={[
                {
                  src: epiq,
                  alt: "Epiq",
                  width: 158,
                  height: 48,
                },
                {
                  src: kroll,
                  alt: "Kroll",
                  width: 158,
                  height: 48,
                },
                {
                  src: integreon,
                  alt: "Integreon",
                  // width: 158,
                  // height: 48,
                },
                {
                  src: consilio,
                  alt: "Consilio",
                  width: 158,
                  height: 48,
                },
                // {
                //   src: "https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg",
                //   alt: "Statamic",
                //   width: 158,
                //   height: 48,
                // },
              ]}
              bgColor={"bg-gray-100"}
            />
          </div>
        </Fade>
        {/* CTA SECTION */}

        <Fade id="section-split-image-cta">
          <CTASplitWithImage
            title=""
            header="Curious about how Breachlink can help you?"
            description="Signing up is free and easy. Get started today."
            ctaText="Sign up for free"
            executeScroll={executeScroll}
          />
        </Fade>
        <div id="services" className="">
          <Fade id="fade-services">
            <StatsLarge
              id={"services"}
              title={"Quotes at Breachlink"}
              header={"Free Bid Management Service for Law Firms"}
              orientation="reverse"
              variant={"light"}
              description={
                "We offer a complimentary service to law firms where we seek bids on their behalf. While leveraging our platform can save time and effort in finding the best service providers for your needs, our free service can get you the bids you need without requiring you to learn a new platform. Just email us at quotes@breachlink.com with your bid request, and we'll take care of the rest."
              }
              data={[
                {
                  stat: "Save Time",
                  rest: "We handle the entire bid solicitation process, allowing you to focus on your core legal work.",
                },
                {
                  stat: "Competitive Bids",
                  rest: "Receive multiple bids from top vendors, ensuring you get the best value for your requirements.",
                },
                {
                  stat: "High-Quality Vendors",
                  rest: "Our network consists of vetted, high-quality service providers ready to meet your needs.",
                },
                {
                  stat: "No Cost to Law Firms",
                  rest: "This service is completely free for law firms, as our vendors cover the costs.",
                },
              ]}
              showCTA={true}
              ctaText={"Get started"}
              ctaClick={"#contact"}
              layout="grid"
              gradient={false}
              showBackground={false}
            />
          </Fade>
        </div>

        {/* <Fade id="section-testimonials">
          <div id="testimonials">
            <DevNote>
              We need to get some testimonials. Just need a few.{" "}
              <p>This is not the final design for testimonials.</p>
            </DevNote>
            <SectionDivider
              title={"What our customers are saying"}
              description={
                "We work with some amazing people. Here's what they have to say."
              }
              variant="light"
            />
            <TestimonialsGrid2 id="testimonials-component" />
          </div>
        </Fade> */}
        <div id="vendors">
          <Fade id="section-service-providers">
            {/* <DevNote>Content to be updated with latest </DevNote>{" "} */}
            {/* <SectionDivider
              title={"Become a vendor on Breachlink"}
              description={
                "Want to be among the very best in the world?  Get listed in our marketplace and start bidding on jobs"
              }
              variant={"dark"}
            /> */}
            <StatsLarge
              id={"serviceProviders"}
              title={"Service Providers"}
              header={"Join Our Network of Trusted Service Providers"}
              description={
                "Are you a service provider looking to expand your reach and connect with more potential clients? Join Breachlink to showcase your value and grow your business."
              }
              variant={"dark"}
              showCTA={true}
              ctaText={"Join our network"}
              ctaClick={"https://app.breachlink.com/signup"}
              data={[
                {
                  id: 1,
                  stat: "Detailed bidding",
                  emphasis: "",
                  rest: "Break down your bids to show your value in user-friendly detail.",
                },
                {
                  id: 2,
                  stat: "Q&A",
                  emphasis: "",
                  rest: "Ask and answer questions to clarify your bid and win the job.",
                },
                {
                  id: 3,
                  stat: "Be discovered",
                  emphasis: "",
                  rest: "Get in front of the right people, at the right time, every time.",
                },
                {
                  id: 4,
                  stat: "Track your success",
                  emphasis: "",
                  rest: "See how you compare to the competition and improve.",
                },
              ]}
            />
          </Fade>
        </div>
        <Fade id="section-team">
          <div id="team" className="h-full min-h-[800px]">
            {/* <DevNote>
              Will create team profiles. Need to decide who is listed. Link will
              go to new company page.
            </DevNote> */}

            <SectionDivider
              title="Meet the Team Behind Breachlink"
              description="At Breachlink, our mission is to revolutionize the legal
              industry's approach to data breach response and eDiscovery. Meet
              the dedicated team driving innovation and excellence."
              variant="light"
            />
            <Team />
          </div>
        </Fade>

        <Fade id="cta-ready-next-step">
          {" "}
          <CenterBrandedCTA
            text={"Ready to take the next step?"}
            buttonText={"Sign up for free"}
            link={"https://app.breachlink.com/signup"}
            executeScroll={executeScroll}
            bottomText={""}
            variant="dark" //light, brand, dark
          />
        </Fade>

        <Fade id="section-hubspot-section-1">
          <div id="contact" className=" lg:pt-0">
            <HubsForm id="hubsForm" variant={"light"} />
          </div>
        </Fade>
      </main>
      <ModalBasic
        id="partner_modal"
        modalOpen={showModal}
        setModalOpen={setModalOpen}
        title="Get in touch!"
      >
        <PartnerForm id="partnerForm" />
      </ModalBasic>
      {/* ------------------------------------ --------- ----------------------------------*/}
      {/* ------------------------------------ GRAVEYARD ----------------------------------*/}
      {/* ------------------------------------ --------- ----------------------------------*/}
      {/* <SmallBanner
          description={
            "At Breachlink, we're transforming the data breach response and eDiscovery processes for law firms, insurance companies, corporations, and service providers. Our platform is designed to optimize costs, enhance quality, and save you precious time by streamlining the procurement process."
          }
        /> */}
      {/* <CenterBrandedCTA
          variant="light"
          text={"Convinced? We thought you would be."}
          buttonText={"Sign up for free"}
          link={"https://app.breachlink.com/signup"}
          executeScroll={executeScroll}
          bottomText={"If you're not, contact us and let us convince you!"}
        /> */}
      {/* <SmallBanner
          description={
            "Ready to make security assessments part of your offering? We've got you covered with reseller, whitelabel, and integration partner programs!"
          }
          partner={true}
          executeScroll={executeScroll}
          setShowModal={setShowModal}
        /> */}
      {/* <SinglePriceWithDetails /> */}
      {/* <HubsForm id="hubsForm" /> */}
      {/* <div id="solutions-lawFirms">
          <ThreePartFeature
            title={"Law Firms"}
            header={"Maximize Efficiency and Quality in Your Vendor Management"}
            description={
              "Breachlink streamlines your data breach response, saving you time and money. Compare bids, track statuses, and perform due diligence with ease."
            }
            features={[
              {
                name: "Optimize Costs",
                description:
                  "Encourage competitive bids to achieve the best value for your investments.",
              },
              {
                name: "Improve Quality",
                description:
                  "Access a marketplace of vetted, high-quality vendors.",
              },
              {
                name: "Easy Comparisons",
                description:
                  "Side-by-side bid comparisons to make informed decisions.",
              },
              {
                name: "Tracking and Reporting",
                description:
                  "Dashboards and reports for comprehensive bid tracking and analysis.",
              },
              {
                name: "Vendor Flexibility",
                description:
                  "Enjoy the freedom to choose the best vendor for each project, ensuring you always have the right partner.",
              },
            ]}
          />
        </div> */}
      {/* <Fade id="section-feature-carousel">
          {" "}
          <SecondaryFeatures
            showImages
            title="Notable Features"
            description="Explore some of our features by clicking the feature descriptions."
            images={[]}
          />
        </Fade> */}
      {/* <ModalBasic
        id="client_modal"
        modalOpen={showClientModal}
        setModalOpen={setClientModalOpen}
        title="Join today!"
      >
        <HubsForm id="hubsForm" />
      </ModalBasic> */}
    </div>
  );
}
