import { Paragraph } from "../components/Paragraph";
import Button from "../elements/Button";

/* This example requires Tailwind CSS v2.0+ */
export default function CenterBrandedCTA(
  { executeScroll, text, buttonText, bottomText, link },
  props
) {
  // const executeScroll = props.executeScroll;
  const variant = props.variant ? props.variant : "dark";
  return (
    <div
      className={`${
        variant === "light"
          ? "bg-slate-200"
          : variant === "dark"
          ? "bg-slate-800"
          : "bg-indigo-600"
      }`}
    >
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">{text}</span>
          <span className="block"></span>
        </h2>
        {/* <p className="mt-4 text-lg leading-6 text-indigo-200">
          See how easy it is to help your customers tackle one of their top
          risks as a part of your offering.
        </p> */}
        {link ? (
          <a
            href={link}
            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white text-opacity-80 bg-brandGreen-500 hover:bg-brandGreen-600 sm:w-auto"
          >
            {buttonText}
          </a>
        ) : (
          <Button
            onClick={() => {
              executeScroll("vetBanner");
            }}
            color={variant === "light" ? "indigo" : "green"}
            className=""
          >
            {buttonText}
          </Button>
        )}

        <div>
          <Paragraph variant={"dark"}>{bottomText && bottomText}</Paragraph>
        </div>
      </div>
    </div>
  );
}
