import { getRequest, postRequest } from "./ApiRequest";

const o = [
  {
    id: 1,
    question:
      "Was your POS system installed by a qualified 3rd party with POS software that is certified to be compliant?",
    best_answer: "Yes",
    worst_answer: "You or your employees",
    action_plan:
      "Your answer indicated uncertainty regarding whether your POS system is PCI certified and has been installed by a PCI trained professional.  Uncertified systems lack the safeguards to protect your system from damaging data security breaches.  Outdated, improperly installed and maintained POS systems also increase the risk of data security breaches and bring with them PCI fines and penalties.  For this reason, Visa mandates that PCI Level 4 small merchants must use payment solution providers that have achieved Qualified Integrator and Reseller (QIR) certification.  \n\nYour DSU Consultant can help you determine if your POS system has been properly certified and that you are working with trained and approved PCI DSS vendors to keep your system compliant.   ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      'Poorly trained installers have been responsible for creating security vulnerabilities and opening the door to hackers. As a result, Visa now mandates that small merchants (Level 4) must use payment solution providers that are PCI certified and registered as Qualified Integrators & Resellers (QIR)."',

    action_title: "Check the Plumbing",
    action_icon: "09-Check-the-plumbing",
  },
  {
    id: 4,
    question:
      "Do you have a current support and maintenance agreement for your POS?",
    best_answer: "Yes",
    worst_answer: "No , IDK",
    action_plan:
      "Get Regular Checkups.\nYou indicated that you either do not, or do not know if you maintain a current support and maintenance plan with your POS provider. Updates or patches to a POS system's software help maintain its security. POS vendors generally provide such updates and patches only to customers who maintain a current support and maintenance plan. \n\nAsk your DSU Consultant the best means to determine whether you maintain a current support and maintenance plan and whether the offered plan includes security features that will protect your POS system from data security breaches.\n\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?[",
    hover_answer:
      "Responsible maintenance services are more apt to focus on PCI compliance.",

    action_title: "Get Regular Checkups",
    action_icon: "10-Get-Regular-Checkups",
  },
  {
    id: 5,
    question:
      "Do you or your POS vendor use a remote desktop application to connect remotely to your POS system?",
    best_answer: "Yes",
    worst_answer: "No , IDK",
    action_plan:
      "Make Remote Access Secure Access.\nYou indicated that you use a remote desktop application in your business. Many POS systems come equipped with remote desktop applications that permit business owners to manage their POS systems remotely or permit POS providers to service their systems remotely. A major concern with such remote desktop applications is password security resulting from default or vendor-supplied passwords.  Additionally, companies are to use \"multi-factor authentication\" (MFA) which requires two or more pieces of evidence to verify a user's identity  to gain access to a company's network.  In addition to passwords and security questions, MFA could entail that a user providel a SMS code and or fingerprint or face ID.  \nConsult with your DSU Consultant to ensure that you are not using unsecure remote desktop applications and default passwords for those applications.   \n\nLet your DSU Consultant provide guidance on the PCI-DSS requirements for proper use of multi-factor authentication (MFA) for preventing unauthorized access to computers and systems that process payment transactions.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?[",
    hover_answer:
      "It’s OK to connect remotely if you are cautious and follow compliance requirements.",

    action_title: "Make Remote Access Secure Access.",
    action_icon: "31-Make-Remote-Access-Secure-Access",
  },
];

const pre = [
  {
    id: 1,
    question:
      'Do you know what "Personally Identifiable Information" is and if so, do you know the nature of what personal, sensitive or confidential information is stored in your company, where it might be stored and how it is securely transmitted. ',
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "This preliminary step can be equated to needing to build a foundation for a house prior to the construction process.  It would be impossible to build an effective Information Security  Plan and comply with data security and privacy laws without first performing an inventory to determine where your organization's sensitive personal information is stored and how it is transmitted.  Let a DSU Consultant provide you with the most efficient and economic means to quickly walk you through the most economical way to manage this process. ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
  },
  {
    id: 2,
    question:
      "Has your company put together a written Information Security Plan which you understand, which you and all personnel are following, and which is actively communicated and monitored? ",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      'In order to be in compliance with data security and privacy laws and standards - it is necessary that your organization have a written Information Security Plan which clearly sets out what security measures your organization is implementing under the categories of "People" (Employee Training & Education) , "Process" (Data Security Policies and Procedures) & "Technology "(Data Security tools).  Have a DSU Consultant provide you with simple templates and directions to quickly build a Information Security Plan that is tailored to your unique organization.',
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
  },
  {
    id: 3,
    question:
      "Do you have a working knowledge of Firewalls, VPNs, Vulnerability Tests, Multi-factor Authentication, Encryption, Tokenization and Audit Trails?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      'It is not necessary to be an expert on "technology" security measures just as one doesn\'t need to be a mechanic in order to select a car and know how to drive it.   However, it is important to know a few fundamentals about basic technology tools in order to ensure that they are doing their job intended.  It also helps to be able to ask the right questions to your IT consultant or Managed Service Provider.  Let a DSU Consultant quickly provide you with a primer and break down the techno speak in order to ensure that your organization is using the right tools and that you are not overpaying for technology solutions. ',
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
  },
  {
    id: 4,
    question:
      "Does your company have written Incident Response and Storage Recovery Plans that you know how to follow if there is a security breach. If so, have these plans been rolled out to all personnel and are personnel actively trained in the plans.  ",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "It is impossible to have an effective Information Security Plan in place if it does not provide for a plan for how to recover vital data stolen by cyber criminals as well as how your organization is to fulfill its legal obligations to notify your organization's customers to inform them that their personal data had been stolen.   Have a DSU Consultant provide guidance on how to prepare effective Incident Response and Storage Recovery Plans for your unique organization.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
  },
  {
    id: 5,
    question:
      "Do you know what Ransomware is and what can be done to prevent it? ",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "Ransomware is one of the fastest growing cyber security threats targeting small and medium sized businesses.  Ransomware is malware that hackers are able to remotely install on your organization's system network that prevents your ability to access your computer system.  As the ransomware encrypts your company's data; the cyber criminal demands that you pay a ransom fee for them to unlock your computer network so that you may retrieve your stolen data.  Payment of a high ransom fee does not guarantee return of your organization's data and many victims who pay the ransom are likely to suffer repeat ransomware attacks.  DSU Consultants can show you how to avoid ransomware attacks as well as to minimize damages in the event that they occur. ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
  },
  {
    id: 6,
    question:
      "Does your company provide security awareness training to employees which includes how to avoid being victims to “phishing emails” as well as ransomware?   ",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "According to an IBM study -human error is the main cause of approximately 95% of security breaches.  Data Security laws recognize this fact and require that organization's have  Information Security Programs  that train both your employees and third party vendors/suppliers on how to prevent these problems.  Have a DSU consultant help you tailor the right learning management system for your organization while providing the best value.",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
  },
  {
    id: 7,
    question:
      "If your company has any vendors or contractors that have access to your company’s system network and customer or employee information, do you know what security measures they are using to secure your company’s data? ",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "Any vendor that you work with is vulnerable to cyber attacks.  In fact, industry experts estimate that roughly 60% of all data breaches happen as a result of third party vendors inadvertently allowing access to your organization's system networks.  Have a DSU consultant show you what best practices to  take to avoid having a vendor or supplier open the door to a damaging security breach of your organization.  ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
  },
  {
    id: 8,
    question:
      "Do you know what Privacy and Data Protection laws apply to your business and if your company is complying with all the applicable laws and regulations pertaining to privacy and data protection?  ",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "Taking proper security measures to protect your business and safeguard your customers' personal information is no longer just a smart thing to do - it's the law.  More and more states have enacted privacy laws requiring businesses to allow consumers to control how a business collects, stores, and uses their personal information.  Laws mandate that organizations implement written Information Security Plans  which require Employee Training and Incident Response Plans.  Violations of these laws may result in severe penalties and legal exposure.   Have a DSU Consultant help make sure that your business is compliant with the law.  ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
  },
  {
    id: 9,
    question:
      "Do you know if your company has the right kind of cybersecurity insurance coverage to protect against damages from ransomware and security incidents?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      'Companies are increasingly aware that having cyber security insurance is vital to managing the substantial risk of loss resulting from ransomware and security breaches.  However, too many businesses have a "check the box" mentality when purchasing insurance and do not realize  until too late that they have the wrong type of policy that will not provide the coverage protection that fits their company\'s needs.  Additionally, many cyber security policies have exemptions in the fine print that may nullify coverage and kick out a claim for damages if the insured business has not applied certain security measures or have not adequately trained their personnel.  Have a DSU Consultant provide you with guidance to ensure that you have the right insurance and your business will not be subject to any unwanted surprises should it need to submit a claim in the future. ',
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
  },
  {
    id: 10,
    question:
      "Is there any particular person in your organization that has responsibility for managing data security and consumer privacy regulations?  ",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "So many of the new data protection and privacy laws have taken note of the fact that  it is too easy for small and medium sized businesses to lose sight of the importance of cyber security while trying to conduct their day to day business.  Too often cyber security protection falls between the cracks when it is not given a priority from the top of the organization and assigned to a qualified person to manage the company's information security program and employee training.   Have a DSU Consultant help you determine what basic skills and knowledge is required of a manager to direct and supervise the work of personnel and data security vendors.  ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
  },
];
const csf = [
  {
    id: 1,
    question:
      "Have you completed a comprehensive audit and conducted an inventory of all of your company’s sensitive data so that you understand where and how the data is received, stored, managed and transmitted? ",
    best_answer: null,
    worst_answer: null,
    action_plan:
      "You indicated that you have not taken the critical first step of conducting an internal Data and Systems Audit which would enable your organization to assess the best means to protect your company’s sensitive data and minimize the risk of a security breach.  This is not a step that an organization can afford to skip as it lays the foundation to be able to apply best practices for data security. \n\nDSU Consultant on call prepared to give you some guidance and get you started on performing your company's Data and Systems Audit. ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
    your_answer: null,
    action_title: "Taking a Good Look",
    action_icon: "taking-a-good-look",
  },
  {
    id: 2,
    question:
      "Does your organization i) inventory and track all approved Software and Hardware devices on your Network so that only authorized Software and Hardware are loaded, and ii) detect and remove unauthorized Software and Hardware.  ",
    best_answer: null,
    worst_answer: null,
    action_plan:
      "You indicated that in conducting your self-audit and inventory process that you discovered certain fundamental information governance procedures that are not yet in place. \n\nNow that you have a better handle on the type of basic deficiencies in how your company is receiving, storing, managing and transmitting sensitive business data, it would be advisable to call upon a DSU Consultant to help you seek a qualified IT/Data Protection specialist to fortify and better secure your data.",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
    your_answer: null,
    action_title: "Finding the Missing Links",
    action_icon: "finding-the-missing-links",
  },
  {
    id: 3,
    question:
      "Do you restrict and control access to your company’s critical data to only those specially authorized employees who have demonstrated a need for access and who have been vetted by background checks?",
    best_answer: null,
    worst_answer: null,
    action_plan:
      "You indicated that you may not be restricting access to your company’s critical data to only those personnel who have demonstrated a need for access to your company’s critical data and who have been vetted by background checks. Data security standards require organizations to have strong access control measures in place. These include the following: i) Company policies and facility controls need to ensure that only authorized persons can access areas where hard copy or digital data are available. ii) All employees (as well as contractors and vendors) need to be thoroughly vetted prior to being granted specific access to sensitive data. Background checks are recommended when they may be legally used per your attorney’s direction. Restricted Access Control policies and background screening are easy enough to implement with the guidance of a DSU Consultant.  \n\nDSU also can put you in touch with an attorney who can quickly advise if there may be any local privacy laws that can limit your ability to use a background check.",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is PCI-DSS?",
    hover_answer:
      "The PCI Data Security Standard is a set of security controls mandated by the Payment Card Industry Security Standards Council. The standard was created primarily by the major credit card brands to increase controls around cardholder data to reduce credit card fraud. Validation of compliance for SMBs is performed annually with a Self-Assessment Questionnaire (QSA). The prior Assessment questions pertaining to Point-to-Point Encryption and Tokenization are very important as these are critical security controls and components of PCI-DSS.",
    your_answer: null,
    action_title: "Lock Up Tighter",
    action_icon: "Copy of 28-lock-up-tight",
  },
  {
    id: 4,
    question:
      "Do you sufficiently cover data security policies and procedures and reference in your employee handbook the applicable data protection laws or requirements such as PCI-DSS, GDPR or HIPAA? ",
    best_answer: "Yes",
    worst_answer: "No or We don't have a handbook.",
    action_plan:
      "You indicated that you may not be covering either Cyber Security best practices or PCI-DSS compliance in your employee handbook. Both the NIST Cyber Security Framework and PCI-DSS rules require organizations to implement strong “access control measures” to ensure that only authorized persons can access sensitive company data. Such policies should be adopted and incorporated into an employee handbook that is provided to all employees. \n\nCall on your DSU Consultant to ensure that you receive a set of Cyber Security policies for your Employee Handbook in addition to templates for Incident Response Plans and Data Recovery Programs. \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
    your_answer: null,
    action_title: "Update Your Handbook",
    action_icon: "Copy of 20-Update-Your-Handbook",
  },
  {
    id: 5,
    question:
      "Does your company provide security awareness training to employees which includes how to avoid being victims to “phishing” as well as ransomware?",
    best_answer: "Yes",
    worst_answer: "No or What's a phishing email look like?",
    action_plan:
      "You indicated that your company has not performed adequate training to help employees from responding to phishing emails that trick employees into opening links and attachments which contain malware.  According to IBM, human error is the main cause of approximately 95% of cyber security breaches.  Basic employee training is critical to prevent employees from inadvertently exposing the company to hackers by improperly using company computers or being deceived by hackers employing social engineering tricks such as “Phishing”.  \n\nYour DSU Consultant can make sure that your personnel and vendors have the required education, training and phishing simulation programs to successfully combat this high exposure risk. ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is phishing?",
    hover_answer:
      "Phishing is when a fraudster sends out a legitimate-looking email that could be part of a phishing, smishing, vishing, spyware, ransomware, malware or social engineering effort in an attempt to trick a recipient/employee to provide confidential info or open a link containing a virus.",
    your_answer: null,
    action_title: "Gone Phishing",
    action_icon: "Copy of Phishing",
  },
  {
    id: 6,
    question:
      "Are employees permitted to ‘BYOD’ (Bring Your Own Devices - laptops, tablets, and smartphones) to the workplace and use these devices to access privileged company information and applications?",
    best_answer: "No",
    worst_answer: "Yes or IDK",
    action_plan:
      "As you indicated that your organization may not have BYOD security policies, your organization needs to implement critical data security vulnerabilities associated with employees tapping into sensitive business data while balancing legal regulations and the employee’s privacy.   Be sure to seek the help of legal counsel to address privacy issues and liabilities that may emerge when businesses wipe devices after employees leave the organization. It can get tricky when an employee feels that they have ownership of the phone number and thereafter leaves your company to join a competitor.  \n\nAllow our DSU Consultant to provide for you the right BYOD employee and consultant policies along with the correct notification protocols to report missing or stolen portable computers. DSU also has a Learning Management System to properly train your employees regarding security and the proper usage of BYOD equipment. DSU consultants can also fill you in an give you ready access to helpful tech tools that happen to have complicated sounding names such as mobile device management (MDM), containerization and app virtualization.  ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
    your_answer: null,
    action_title: "BYOD Needs to Spell Security",
    action_icon: "byod-needs-to-spell-security",
  },
  {
    id: 7,
    question:
      "Do you give third party Call Centers or Suppliers access to your critical systems and do you monitor that they are complying with PCI-DSS and fulfilling contractual responsibilities to your company to adhere to data security standards?",
    best_answer: "No",
    worst_answer: "Yes or IDK",
    action_plan:
      "You indicated that your company is utilizing either third party call centers or suppliers that have access to your IT systems or customer data, but questions remain as to whether these third parties are fulfilling their PCI responsibilities. Many security breaches have been the result of call centers or supplier vendors that have inadvertently provided hackers an entry point into their customer’s sensitive data systems. It is essential that you only do business with third parties that can demonstrate that they have compliant systems and security practices which can easily be monitored. It also is critical to have good contracts with third parties that identify PCI-DSS responsibilities upfront and hold third parties accountable for their actions.  \n\nHave your DSU Consultant show you how to better ensure that the proper safeguards are in place for dramatically reducing cyber security risks associated with vendors and contracts. \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "There have been too many incidences where third party call centers or suppliers have inadvertently exposed their client companies to hackers.",
    your_answer: null,
    action_title: "Call Centers",
    action_icon: "Copy of 05-Call Centers",
  },
  {
    id: 8,
    question:
      "Are “stationary” computer workstations within your organization, such as at a receptionist’s desk or on a showroom floor, particularly accessible to outsiders? This question also encompasses workstations at unoccupied desks or in offices left unattended during short intervals such as lunch breaks or at longer spans by vacationing or former employees.",
    best_answer: "No",
    worst_answer: "Yes or IDK",
    action_plan:
      "You indicated that you may not be properly securing computer sites within your organization. It is a good practice to use computers that have locks to prevent opening its case without a key. It should also be easy enough to simply disconnect or remove computers and disable USB drives that are not being used in empty offices. Even offices that are left empty at lunch or due to an employee’s absence should at least be locked to keep intruders out. \n\nAs it may be unrealistic to remove computers from open areas; your DSU Consultant can provide you with employee security policies and training in addition to  smart card or biometric readers to prevent unauthorized persons from logging onto the computer.",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why is this important?",
    hover_answer:
      "Unsecured computers that are connected to the network make for an easy target for data thieves.",
    your_answer: null,
    action_title: "Keep Tabs on Laptops",
    action_icon: "Copy of 28-lock-up-tight",
  },
  {
    id: 9,
    question:
      'Does each employee have unique and complex passwords which have been changed from the "default" password, and are passwords for critical applications changed at least every 90 days?',
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that there may be a problem with either changing your POS system's default passwords or not giving employees unique passwords to log into your network wherein these passwords need to be routinely changed at least every 90 days. PCI-DSS rules require merchants to implement strong access control measures so that only authorized persons can access cardholder data. This includes assigning a unique and complex ID to each employee with computer access which needs to be routinely changed while being incorporated into multi-factor authentication \n\nConsult with your DSU Consultant  about multi-factor authentications as well as to confirm that you are compliant with PCI password protocol regarding your network management devices or remote access applications.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?   ",
    hover_answer:
      "Default passwords come with hardware or software when it comes out of the box from the manufacturer or retailer. Multi-Factor Authentication: Don't rely on just passwords! Under PCI-DSS v3.2 merchants are to see that at least two security factors are utilized wherever there is access to cardholder data or sensitive authentication data originating within an organization’s own trusted networks (as has been the requirement when it came to untrusted, remote access into an organization’s cardholder data). This requirement is intended for authentication of personnel and applies to any administrator, third party, or internal individuals who have the ability to change systems and other credentials. Examples of different factor authentications: - known to the user such as a traditional password - user possession such as a hardware token or smart card, or - generates from the user’s own person such as a biometric (e.g. fingerprints, iris/retina scanning, facial imaging, voice recognition).",
    your_answer: null,
    action_title: "Get Personal",
    action_icon: "27-get-personal",
  },
  {
    id: 10,
    question:
      "Does your organization have an Access Control (AC) policy requiring that its servers and the server room are properly secured and locked down?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that your organization may not be doing enough to secure and lock down its servers and server rooms. Organizations need to keep a log book in order to track personnel within the server room in addition to anywhere else where there may be sensitive data. It’s a good investment to have locking devices, and authentication systems so that entry doors to server rooms can only be unlocked via a smart card, token, or biometric scan while a record is made of the ID for each person who enters. Surveillance systems are effective and state of the art systems can even be set up to send email or cell phone notification if motion is detected after business hours.  \n\nHave your DSU Consultant work with you to put together a turnkey Access Control plan and policies at affordable prices. ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question:
      "What is an Access Control (AC) Policy? Why is this important?",
    hover_answer:
      "An Access Control Policy helps manage the security of a company’s system network by restricting the access to the network to only authorized users via such access control mechanisms as locks and login credentials. Access Control (AC) Policy?\n\nThink of your server room as ground zero for your physical network and consider what damage can be done to your operation by an intruder having access to your servers, routers, switches and cables. Keep in mind how easy it would be for an intruder to physically open a computer’s case and grab the hard drive or for a hacker to plug into a hub and use sniffer software to capture data traveling across the network. \n",
    your_answer: null,
    action_title: "Restrict with Access Control Policy",
    action_icon: "restrick-withaccess-control-policy",
  },
  {
    id: 11,
    question:
      "Has your organization prepared a comprehensive Incident Response Plan which addresses what to do with your information and systems in case of a breach as well as the roles and responsibilities for those within and outside your organization who will carry out the plan? The plan should include what activities constitute an information security incident and who will be contacted.",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you may not have prepared an Incident Response Plan in the event of a breach. Although it is hoped that your organization will never experience a security breach, the fact is that companies that do not prepare for such an event most often find themselves ill equipped to adequately manage the devastating aftermath of a security breach. Without a blueprint that provides a coordinated process for how designated company representatives manage customer notifications, law enforcement, media, litigation, remediation, etc,; the cost and damages of a breach are made worse.  \n\nContact your DSU Consultant who can help you quickly put together the right Incident Response Plan for your organization.   ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is an Incident Response Plan?",
    hover_answer:
      "An Incident Response Plan is much like a Crisis Management Plan in that it provides for a coordinated approach of how specific personnel within and outside the company shall address and manage the aftermath of a security breach. The objective of an Incident Response Plan is to manage the breach so as to limit damage to the company and reduce the recovery time and costs.",
    your_answer: null,
    action_title: "Plan on a Incident Response Plan",
    action_icon: "plan-on-an-incident-responce-plan",
  },
  {
    id: 12,
    question:
      "Do you have a Data Recovery policy in place which requires that full and functioning data backups are routinely and frequently made and that these backups are maintained in a secure offsite location?  ",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you may not have an appropriate Data Recovery process which covers the management of full data backups. More organizations are recognizing the vital need for backing up important data as a part of a proactive Data Recovery Process. However, organizations should not lose sight of the fact that information on backup tapes or disks are also a common target for data thieves.  Most importantly, strong Data Recovery plans require backups to be made to a very secure offsite location.  \n\nLet a DSU Consultant help you quickly prepare a customized Data Recovery Plan that fits your organization's specific needs.  ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
    your_answer: null,
    action_title: "Backup with Data Recovery Policy",
    action_icon: "backup-with-data-recovery",
  },
  {
    id: 13,
    question:
      "Do you conduct Vulnerability Scans at least as often as on a quarterly basis?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you do not implement, or do not know if you implement quarterly network scans. PCI-DSS rules require all merchants to complete four quarterly network scans, performed by an Approved Scanning Vendor (ASV).\n\nCall on your DSU Consultant to have an approved ASV conduct your quarterly network scans to keep your company secure and compliant with PCI-DSS requirements.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "The automated process of proactively identifying vulnerabilities of computing systems in a network in order to determine if and where a system can be exploited and/or threatened. To maintain PCI compliance, scans must be performed on at least a quarterly basis.",
    your_answer: null,
    action_title: "Get Quarterly Scans",
    action_icon: "Copy of Get-Quarterly-Scans",
  },
  {
    id: 14,
    question: "Are Penetration Tests conducted periodically?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that your organization may not be conducting regular Penetration tests.  Penetration tests enable an organization to probe for weaknesses in their own system infrastructure, including hardware and software applications, in order to shore up these network vulnerabilities and develop tighter controls. \n\nAs Penetration tests should play a vital role in any organization's cybersecurity defenses, have a DSU Consultant help you conduct Penetration tests at DSU discounted pricing.",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question:
      "What are Penetration Tests? \n\nVulnerability Scan vs. Penetration Test?",
    hover_answer:
      "Penetration tests are designed to not only identify flaws in the system network that pose a threat, but also attempt to exploit the weaknesses in the system to determine whether unauthorized access or other malicious activity is possible.\n\nWhat’s the difference between a Vulnerability Scan and Penetration Test? A vulnerability scan attempts to detect and report on vulnerabilities or weaknesses in the architecture of your systems whereas a penetration test is designed to exploit such weaknesses. A Penetration test provides for an authorized individual to actually attempt to hack into your systems in order to identify the systems’ weaknesses and fix them.\n",
    your_answer: null,
    action_title: "Exploiting System Weaknesses",
    action_icon: "Copy of 27-get-personal",
  },
  {
    id: 15,
    question:
      "Do you maintain a wireless Computer Network and have you installed and activated properly configured software and hardware firewalls on all your business networks?",
    best_answer: "No",
    worst_answer: "No, IDK, What's a Firewall?",
    action_plan:
      "You indicated that you either do not maintain, or do not know if you maintain, a properly configured firewall.  Firewall capability is often built into other network devices, such as routers, which control the flow of data in and out of your network.  Even when firewalls have been properly installed initially;  too many serious data breaches have occurred as a result of the failure of merchants to have their firewalls properly reconfigured after they installed a DSL line or Wi-Fi network.\n\nHave your DSU Consultant provide you with guidance on whether you have a quality firewall that has been properly installed or reconfigured.",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is a Firewall?",
    hover_answer:
      "A Firewall is a system designed to prevent unauthorized access to a private network. All messages entering or leaving the network pass through the Firewall, which examines each message and blocks those that do not meet specific security criteria.",
    your_answer: null,
    action_title: "Reconfigure Your Firewall",
    action_icon: "Copy of 22-reconfigure-your-firewall",
  },
  {
    id: 16,
    question: "Do you use a VPN Router to transmit data between devices?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you do not, or do not know whether you use a Virtual Private Network (VPN) router. A VPN router is a particular type of router that encrypts data, such as customer credit card data, as it passes through a network. PCI-DSS rules require merchants to encrypt cardholder data as it is transmitted across open, public networks. Use of a VPN router is one way to comply with this requirement.\n\nAs not all VPN routers are of high quality and function, have your DSU Consultant see that vendors have installed the proper VPN devices to properly encrypt cardholder data in compliance with PCI-DSS requirements.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is a Router?",
    hover_answer:
      "A Router is a device that forwards data packets along networks. A VPN (Virtual Private Network) Router encrypts data between points on a network. ",
    your_answer: null,
    action_title: "Get Virtual",
    action_icon: "Copy of 14-Get-Virtual",
  },
  {
    id: 17,
    question:
      "Is all traffic through firewalls, routers, etc. logged by an audit log tool so that your system administrator is able to detect unusual or irregular activity within your network?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that there may not be an audit log process and tool in place to detect unusual activity on or through your network’s firewalls or routers. \n\nAs spotting potentially troublesome network traffic activity may be critical to detecting a hacker and time is of the essence - get help from from your DSU Consultant to install the right audit log tool and alert controls at discounted prices. ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
    your_answer: null,
    action_title: "Keeping Track with an Audit Log",
    action_icon: "keep-tracking-with-an-audit-log",
  },
  {
    id: 18,
    question:
      "Has your organization implemented Point-to-Point encryption for data in transit?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you may not be implementing all of the primary technology tools that are required to safeguard your company from a security breach. Point-to-Point encryption secures against hackers during credit card data transmission.  \n\nPoint-to-Point Encryption only sounds like it is something out of a spy movie but a DSU Consultant can show you how easily P2PE Encryption operates and how it can be affordably integrated into your business.  \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is Point to Point encryption?\nIMPORTANT - TIP ",
    hover_answer:
      "Point-to-Point encryption (P2PE) is a 3rd party solution that encrypts data from the credit card swipe until data reaches a secure decryption environment. P2PE is the only PCI-DSS approved encryption solution as opposed to End-to-End encryption.\n\nTechnology Innovation Program (TIP): Under Visa 1/31/17 mandate, acquirers must ensure that Level 4 merchants are to annually validate PCI-DSS compliance or participate in the Technology Innovation Program (TIP). In order to discontinue its annual PCI-DSS validation assessment under TIP, a merchant must meet the following criteria: Confirm that sensitive data is not stored subsequent to transaction authorization Ensure that at least 75% of all transactions originate through either i) EMV chip reading terminals or ii) PCI SSC-validated P2PE solution. ",
    your_answer: null,
    action_title: "Encryption is the Key",
    action_icon: "Copy of the-key-to-encryption",
  },
  {
    id: 19,
    question:
      "Has your organization implemented Tokenization for data at rest?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "Point-to-Point encryption (P2PE) is a 3rd party solution that encrypts data from the credit card swipe until data reaches a secure decryption environment. P2PE is the only PCI-DSS approved encryption solution as opposed to End-to-End encryption.\n\nTechnology Innovation Program (TIP): Under Visa 1/31/17 mandate, acquirers must ensure that Level 4 merchants are to annually validate PCI-DSS compliance or participate in the Technology Innovation Program (TIP). In order to discontinue its annual PCI-DSS validation assessment under TIP, a merchant must meet the following criteria: Confirm that sensitive data is not stored subsequent to transaction authorization Ensure that at least 75% of all transactions originate through either i) EMV chip reading terminals or ii) PCI SSC-validated P2PE solution. ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is Tokenization?",
    hover_answer:
      "Tokenization protects data by replacing it with alias values/tokens that are meaningless to someone obtaining unauthorized access.",
    your_answer: null,
    action_title: "More Than a Token of Your Security",
    action_icon: "Copy of a-token-of-security",
  },
  {
    id: 20,
    question:
      "When your organization accepts credit cards which would subject it to compliance with Payment Card Industry Data Security Standard (PCI-DSS) have you implemented EMV e.g. Chip & Pin/Chip & Signature?",
    best_answer: "No",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you may not be implementing all of the primary technology tools that are required to best safeguard your company from a security breach. Merchants need to make the necessary investment in EMV credit card readers for their POS systems to better guard against counterfeit and stolen cards. \n\nHave your DSU Consultant help you take advantage of the DSU vendor discount for EMV credit card readers. \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is EMV?\nIMPORTANT - Liability Shift",
    hover_answer:
      "EMV makes credit cards less vulnerable to fraud by putting a microprocessor chip into debit and credit cards.\n\nLiability Shift: The Liability Shift means that counterfeit card-present transactions between merchants and card issuers (banks, credit unions, etc) create liability shifts away from the issuer (where it traditionally has resided) to the merchant if the merchant fails to become EMV compliant (assuming that the issuer provides its customers with EMV-compliant cards). To become EMV compliant, merchants must take on the expense of implementing payment terminals and upgrading software necessary for their terminals to “read” the EMV- enabled cards. Between the Merchant and the Issuer, it is the party that fails to offer EMV-compliant devices (the merchant), or cards (the issuer), that will face liability for counterfeit card transactions. ",
    your_answer: null,
    action_title: "Be Smart with your Smart Card",
    action_icon: "29-Watch-Out-For-Skimmers",
  },
  {
    id: 21,
    question:
      "Do you use current Anti-Virus Software, and deploy Patch Management procedures for your computer systems?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you do not, or do not know if you use updated Anti-Virus software or deploy Patch Management procedures. Both the NIST Cyber Security Framework and PCI-DSS rules require organizations to use and regularly update anti-virus software or programs. The requirements that organizations regularly update anti-virus software and ensure that all software and hardware have the latest security updates (patches) are vital as hackers constantly develop new viruses and malware to exploit vulnerabilities in target organization's networks.   \n\nCall on a DSU Consultant  If to should you have any questions on how to ensure that your organization has updated anti-virus software and security patches.   ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "Patch Management refers to a company policy that regularly installs security software updates that “patch” vulnerabilities that may be exploited by malware.",
    your_answer: null,
    action_title: "An Apple a Day",
    action_icon: "Copy of 15-An-Apple-A-Day",
  },
  {
    id: 22,
    question: "Do you still use Microsoft Windows XP or Windows 7?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated you may still be using Windows XP which Microsoft ceased providing security updates for on April 8, 2014. As a result, your data has become unprotected on this old operating system. We understand it is natural to want to save money by hanging onto old equipment. However, the financial risk of keeping Windows XP is much too great. It is necessary to upgrade ASAP.  \n\nYour DSU Consultant is available to help you make this easy and economical transition. ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "Microsoft ceased providing security updates and support for Windows XP and Windows 7, leaving your data unprotected on this old operating system.",
    your_answer: null,
    action_title: "Upgrade Your System",
    action_icon: "Copy of 06-upgrade-your-system",
  },
  {
    id: 23,
    question:
      "Does your company still have security applications that use either Secure Sockets Layer (SSL) or Transport Layer Security (TLS) v1.0?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "PCI-DSS requires the removal of Secure Sockets Layer SSLv3 security protocols technology and Transport Layer Security TLS v 1.0 past June 30, 2018. Prior to this date merchants have been required to establish a formal PCI-DSS Risk Mitigation Plan to address existing implementation that use SSL and/or early TLS.\n\nIf you use POS terminals, check in with your DSU Consultant to see if their terminals are affected. Your DSU Consultant can also guide you on whether you need upgrades to your non-POS security protocol environments e.g. virtual payment terminals, back office servers, and user computers.\nNote: Although there had been a prior 2017 European Community Meeting that indicated that the June 30, 2018 deadline was under review by the Council, it is prudent to plan to no longer support these two protocols past that date or have appropriate compensating controls to address the risk.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "PCI DSS 3.1 warns of the dangers of SSL encryption and explains why you can no longer rely upon it.",
    your_answer: null,
    action_title: "Drive Away from Secure Socket Layers",
    action_icon: "Copy of 04-rules-of-the-road",
  },
  {
    id: 24,
    question:
      "Has your organization installed web filters and email filters as a means to help secure your network from Web-based threats?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that your organization may not be fully utilizing either email filters or web filters.   Filters are required to better ensure that employees will not have unfettered access to the internet which would pose a grave risk to your organization's data security.  Whereas email filters work with firewalls to act as a first defense to hackers' phishing attacks, web filters guard against fraudulent or websites whose content is aggregated from other sites.  If just one feed of data on a website has been compromised, all the websites that pull in from that feed will provide malware to the user unless web filters have been applied.   \n\nYour DSU Consultant is available to help you with the purchase and implementation of both email and web filters.  ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is a Web Filter?\n\nWhat is an Email Filter?",
    hover_answer:
      "A web filter is a program that can screen an incoming Web page as to its origin and content to determine if it should be displayed to the user. A web filter allows an organization to block out pages from Web sites that are likely to include spyware, viruses or other objectionable content. A web filter is often installed within a firewall.\n\nAn email filtering solution uses a set of protocols to organize incoming email and remove spam and computer items. A less common use of the filter is to inspect outgoing email to better ensure that potentially harmful messages are not being sent out from your organization.\n",
    your_answer: null,
    action_title: "Filtering it Out",
    action_icon: "filtering-out",
  },
  {
    id: 25,
    question:
      "When your organization uses a third-party data storage or cloud storage solution, do you ensure with your Cloud Service Provider (CSP) that the risk factors involved with 3rd party data storage are being mitigated by encryption, secure access, data recovery/backup or mirroring? ",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that your organization may not have required from their Cloud vendor the requisite comfort that their Cloud vendor is applying the highest standards of care in protecting your business data. Many people do not sufficiently understand what Cloud computing does and incorrectly assume that data placed in “the Cloud” is adequately secured. The fact is that the types of security risks (e.g. security, integrity, availability, and performance) that exist in a non-cloud environment are also evident with cloud based technology solutions. Additionally, Cloud Service Providers (CSPs) are considered to be high value targets by hackers as they contain a consolidation of data from multiple organizations.  Check with your DSU Consultant on how best to ensure that your Cloud vendor is securely protecting your company's data. ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is the cloud?",
    hover_answer:
      "Cloud computing enables an organization to obtain its computing resources and applications from another location(s) via an Internet connection. In order to depend on a cloud solution model, an organization must accept that all or parts of its hardware, software, and data may no longer reside on its own technology infrastructure. Instead, all of these resources may reside in a technology center shared with other organizations and managed by a third-party vendor. ",
    your_answer: null,
    action_title: "Dont get Lost in the Cloud",
    action_icon: "dont-get-lost-in-the-cloud",
  },
  {
    id: 26,
    question:
      "Does your insurance provide coverage for Data Security Breaches?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you do not currently have Cyber Insurance coverage. Small and Medium sized businesses are the primary targets of hackers. Most businesses have difficulty absorbing the damage (both financial and to the company’s reputation) associated with experiencing a data security breach. This is why a significant number of small and midsize businesses hit by a cyber-attack will be out of business within six to twelve months. Any effective Incident Response Plan needs to include a comprehensive cyber security policy to safeguard your business. \n\nAlthough it is not a PCI-DSS requirement, it is critical to protect your company with the proper cyber insurance coverage. Let your DSU Consultant help ensure that your organization has comprehensive cyber security coverage that is tailored to your organization's exact needs, risks and budget.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,
    your_answer: null,
    action_title: "Cyber Insurance Coverage",
    action_icon: "Copy of 06-do-your-homework",
  },
];
const pci = [
  {
    id: 1,
    question:
      "Was your POS system installed by a qualified 3rd party with POS software that is certified to be compliant?",
    best_answer:
      "POS Manufacturer\nPOS Distributor\nPCI Certified Qualified Integrator & Reseller (QIR)\n",
    worst_answer: "You or your employees",
    action_plan:
      "Your answer indicated uncertainty regarding whether your POS system is PCI certified and has been installed by a PCI trained professional.  Uncertified systems lack the safeguards to protect your system from damaging data security breaches.  Outdated, improperly installed and maintained POS systems also increase the risk of data security breaches and bring with them PCI fines and penalties.  For this reason, Visa mandates that PCI Level 4 small merchants must use payment solution providers that have achieved Qualified Integrator and Reseller (QIR) certification.  \n\nYour DSU Consultant can help you determine if your POS system has been properly certified and that you are working with trained and approved PCI DSS vendors to keep your system compliant.   ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "Poorly trained installers have been responsible for creating security vulnerabilities and opening the door to hackers. As a result, Visa now mandates that small merchants (Level 4) must use payment solution providers that are PCI certified and registered as Qualified Integrators & Resellers (QIR).",

    action_title: "Check the Plumbing",
    action_icon: "09-Check-the-plumbing",
  },
  {
    id: 2,
    question:
      "Do you have a current support and maintenance agreement for your POS?",
    best_answer: "Yes",
    worst_answer: "No , IDK",
    action_plan:
      "You indicated that you either do not, or do not know if you maintain a current support and maintenance plan with your POS provider. Updates or patches to a POS system's software help maintain its security. POS vendors generally provide such updates and patches only to customers who maintain a current support and maintenance plan. \n\nAsk your DSU Consultant the best means to determine whether you maintain a current support and maintenance plan and whether the offered plan includes security features that will protect your POS system from data security breaches.\n\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "Responsible maintenance services are more apt to focus on PCI compliance.",

    action_title: "Get Regular Checkups",
    action_icon: "10-Get-Regular-Checkups",
  },
  {
    id: 3,
    question:
      "Do you or your POS vendor use a remote desktop application to connect remotely to your POS system?",
    best_answer: "Yes",
    worst_answer: "No , IDK",
    action_plan:
      "You indicated that you use a remote desktop application in your business. Many POS systems come equipped with remote desktop applications that permit business owners to manage their POS systems remotely or permit POS providers to service their systems remotely. A major concern with such remote desktop applications is password security resulting from default or vendor-supplied passwords.  Additionally, companies are to use \"multi-factor authentication\" (MFA) which requires two or more pieces of evidence to verify a user's identity  to gain access to a company's network.  In addition to passwords and security questions, MFA could entail that a user providel a SMS code and or fingerprint or face ID.  \nConsult with your DSU Consultant to ensure that you are not using unsecure remote desktop applications and default passwords for those applications.   \n\nLet your DSU Consultant provide guidance on the PCI-DSS requirements for proper use of multi-factor authentication (MFA) for preventing unauthorized access to computers and systems that process payment transactions.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "It’s OK to connect remotely if you are cautious and follow compliance requirements.",

    action_title: "Make Remote Access Secure Access.",
    action_icon: "31-Make-Remote-Access-Secure-Access",
  },
  {
    id: 4,
    question:
      "Do you personally consult with any advisors, specialists, or vendors regarding PCI compliance?",
    best_answer: "Yes",
    worst_answer: "No , IDK",
    action_plan:
      "You indicated that you do not regularly consult with an Approved Scanning Vendor (ASV), Qualified Security Assessor (QSA), a trained PCI IT consultant, or PCI attorney regarding your PCI-DSS obligations. This reflects low engagement with, and perhaps violations of, the PCI-DSS rules.\nAs the PCI Security Standards Council understands how easy it is for merchants to be exploited by vendors that are not qualified to safeguard your company's data, PCI-DSS requires that merchants work only with security vendors with PCI certified seals of approval.  For instance, only Approved Scanning Vendors (ASVs) are qualified to manage mandated quarterly network scans, and  Visa mandates that small merchants must only use payment solution providers that have  Qualified Integrator and Reseller (QIR) certification. \n\nLet a DSU Consultant see that you are placed with the right certified vendor that will keep your business safe and compliant. \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Hire the Pros",
    action_icon: "12-Hire-the-Pros",
  },
  {
    id: 5,
    question:
      "Have you ever completed the PCI-DSS Self-Assessment Questionnaire?",
    best_answer: "Yes",
    worst_answer: "No, I did not finish it, Never heard of it.",
    action_plan:
      "You indicated that you have not completed the PCI-DSS Self Assessment Questionnaire. All merchants who accept credit cards are required to complete the SAQ on an annual basis. If you have not completed the SAQ, you are currently non-compliant.\n\nMany merchants find completing the SAQ to be challenging and hard to understand . Have your DSU Consultant make the SAQ experience both an easy and helpful experience. \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "Failure to properly and accurately fill out SAQ’s can lead to serious problems.",

    action_title: "Do Your Homework.",
    action_icon: "06-do-your-homework",
  },
  {
    id: 6,
    question:
      "Beginning in February 2018 have you completed a significant IT change to your systems or networks such as installing new system components; modifying firewall rules, implementing applications or operating system upgrades, installing new web servers, or changing your network or sub-network? If Yes, Did your PCI-certified specialist confirm as well as properly document that relevant PCI requirements were in place prior to going live with the new or changed system?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that since February 2018 you made a significant IT change and may not have ensured that all the relevant PCI requirements were in place prior to activation. It is essential that as part of your organization's change control process that there be sufficient testing such as vulnerability scanning and penetration testing. It is also important that there be clear documentation of the application of PCI requirements.\n\nHave your DSU Consultant help you determine that your organization stays compliant as your IT system evolves and that you have the adequate amount of vulnerability scanning and penetration testing by qualified and vetted PCI professionals.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "With Change Comes Compliance",
    action_icon: "with-change-comes-compliance",
  },
  {
    id: 7,
    question:
      "As of 2/1/18, have you directed your authorized personnel with administrative access to incorporate multi-factor authentication for all non-console access into your Cardholder Data Environment (CDE) system or device?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that as of the beginning of 2/1/18 you may not have provided the additional security measure of having multi-factor authentication to your personnel with administrative access to your non-console Cardholder Data Environment (CDE) systems and network. The PCI Data Security Council required the multi-factor security authentication in 2018 so as to minimize the data security breaches that have occurred due to hackers targeting administrators (spear-fishing). \n\nHave your DSU Consultant ensure that you are properly implementing multi-factor authentication.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is a console?",
    hover_answer:
      "A console is typically considered the combination of display monitor and keyboard (or other device that allows input). Another term for console is terminal. The term console usually refers to a terminal attached to a minicomputer or mainframe and used to monitor the status of the system.",

    action_title: "Variety is the Spice of Authentication",
    action_icon: "variety-is-the-spice-of-authentication",
  },
  {
    id: 8,
    question: "Do you conduct Quarterly Vulnerability Scans?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you do not implement, or do not know if you implement quarterly network scans. PCI-DSS rules require all merchants to complete four quarterly network scans, performed by an Approved Scanning Vendor (ASV).\n\nCall on your DSU Consultant to have an approved ASV conduct your quarterly network scans to keep your company secure and compliant with PCI-DSS requirements.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "The automated process of proactively identifying vulnerabilities of computing systems in a network in order to determine if and where a system can be exploited and/or threatened. To maintain PCI compliance, scans must be performed on at least a quarterly basis.",

    action_title: "Get Quarterly Scans.",
    action_icon: "Get-Quarterly-Scans",
  },
  {
    id: 9,
    question:
      "Do you maintain a properly configured Firewall that protects consumer card data?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you either do not maintain, or do not know if you maintain, a properly configured firewall.  Firewall capability is often built into other network devices, such as routers, which control the flow of data in and out of your network.  Even when firewalls have been properly installed initially;  too many serious data breaches have occurred as a result of the failure of merchants to have their firewalls properly reconfigured after they installed a DSL line or Wi-Fi network.\n\nHave your DSU Consultant provide you with guidance on whether you have a quality firewall that has been properly installed or reconfigured.",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is a Firewall?",
    hover_answer:
      "A Firewall is a system designed to prevent unauthorized access to a private network. All messages entering or leaving the network pass through the Firewall, which examines each message and blocks those that do not meet specific security criteria.",

    action_title: "Shore Up Your Defenses",
    action_icon: "13-Shore-Up-Your-Defenses",
  },
  {
    id: 10,
    question:
      "Do you maintain a wireless Computer Network? Do you block access to computers that transmit cardholder data?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      'You indicated that you maintain a wireless network but that you do not, or do not know whether it is on a separate feed. With the emergence and prevalence of Wi-Fi technology, the PCI Security Standards Council has adopted segmentation for safely maintaining wireless networks. A key concern is "segmenting", or segregating, cardholder data from other wireless network traffic. Cardholder data must be completely segregated from other network traffic using a firewall or some other means.\n\nConsult your DSU Consultant to make certain that your company is correctly applying segmentation of your wireless networks.  \n',
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Segment Your Wi-Fi.",
    action_icon: "18-Segment-Your-Wi-fi",
  },
  {
    id: 11,
    question: "Do you use a VPN Router to transmit data between devices?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you do not, or do not know whether you use a Virtual Private Network (VPN) router. A VPN router is a particular type of router that encrypts data, such as customer credit card data, as it passes through a network. PCI-DSS rules require merchants to encrypt cardholder data as it is transmitted across open, public networks. Use of a VPN router is one way to comply with this requirement.\n\nAs not all VPN routers are of high quality and function, have your DSU Consultant see that vendors have installed the proper VPN devices to properly encrypt cardholder data in compliance with PCI-DSS requirements.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is a Router?",
    hover_answer:
      "A Router is a device that forwards data packets along networks. A VPN (Virtual Private Network) Router encrypts data between points on a network. ",

    action_title: "Get Virtual.",
    action_icon: "14-Get-Virtual",
  },
  {
    id: 12,
    question:
      "Do you store customer credit card data either electronically or in paper format?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you do, or you might, store customer credit card data either electronically or in paper format. The storage of customer card data is a problem that plagues older, or “legacy,” POS systems. Such legacy POS systems may save customer credit card information for longer than commercially necessary (or perhaps even permanently), creating a treasure trove of sensitive information for hackers. Thus, the PCI-DSS Rules prohibit merchants from storing customer card information after a card transaction is authorized. \n\nIf you think your POS system may be improperly storing customer card data, let your DSU Consultant help to see that your organization is both PCI-DSS secure and compliant. \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Don't Store Customer Data.",
    action_icon: "25-dont-store-customer-data",
  },
  {
    id: 13,
    question: "Have you implemented EMV e.g. Chip & Pin/Chip & Signature?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you may not be implementing all of the primary technology tools that are required to best safeguard your company from a security breach. Merchants need to make the necessary investment in EMV credit card readers for their POS systems to better guard against counterfeit and stolen cards. \n\nHave your DSU Consultant help you take advantage of the DSU vendor discount for EMV credit card readers. \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is EMV?\nIMPORTANT - Liability Shift",
    hover_answer:
      "EMV makes credit cards less vulnerable to fraud by putting a microprocessor chip into debit and credit cards.\n\nLiability Shift: The Liability Shift means that counterfeit card-present transactions between merchants and card issuers (banks, credit unions, etc) create liability shifts away from the issuer (where it traditionally has resided) to the merchant if the merchant fails to become EMV compliant (assuming that the issuer provides its customers with EMV-compliant cards). To become EMV compliant, merchants must take on the expense of implementing payment terminals and upgrading software necessary for their terminals to “read” the EMV- enabled cards. Between the Merchant and the Issuer, it is the party that fails to offer EMV-compliant devices (the merchant), or cards (the issuer), that will face liability for counterfeit card transactions. ",

    action_title: "Be Smart with your Smart Card",
    action_icon: "29-Watch-Out-For-Skimmers",
  },
  {
    id: 14,
    question: "Have you implemented Point-to-Point encryption (P2PE)?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you may not be implementing all of the primary technology tools that are required to safeguard your company from a security breach. Point-to-Point encryption secures against hackers during credit card data transmission.  \n\nPoint-to-Point Encryption only sounds like it is something out of a spy movie but a DSU Consultant can show you how easily P2PE Encryption operates and how it can be affordably integrated into your business.  \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is Point to Point encryption?\nIMPORTANT - TIP ",
    hover_answer:
      "Point-to-Point encryption (P2PE) is a 3rd party solution that encrypts data from the credit card swipe until data reaches a secure decryption environment. P2PE is the only PCI-DSS approved encryption solution as opposed to End-to-End encryption.\n\nTechnology Innovation Program (TIP): Under Visa 1/31/17 mandate, acquirers must ensure that Level 4 merchants are to annually validate PCI-DSS compliance or participate in the Technology Innovation Program (TIP). In order to discontinue its annual PCI-DSS validation assessment under TIP, a merchant must meet the following criteria: Confirm that sensitive data is not stored subsequent to transaction authorization Ensure that at least 75% of all transactions originate through either i) EMV chip reading terminals or ii) PCI SSC-validated P2PE solution. ",

    action_title: "Encryption is the Key",
    action_icon: "the-key-to-encryption",
  },
  {
    id: 15,
    question: "Have you implemented Tokenization?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you may not be implementing all of the primary technology tools that are required to safeguard your company from a security breach. Tokenization secures credit card data at rest.  \n\nAs with P2PE Encryption, DSU Consultants are available to see that Tokenization is seamlessly applied to your operations. \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is Tokenization?",
    hover_answer:
      "Tokenization protects data by replacing it with alias values/tokens that are meaningless to someone obtaining unauthorized access.",

    action_title: "More Than a Token of Your Security",
    action_icon: "a-token-of-security",
  },
  {
    id: 16,
    question:
      "Do you physically restrict access to areas where credit card data is present?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you may not be restricting access to your company’s critical data to only those employees who have demonstrated a need for access to your company’s critical data and who have been vetted by background checks. PCI-DSS rules require organizations to implement strong access control measures to ensure that only authorized persons can access such data.\nStrong Access Controls require that you physically restrict access to places where hard copy data is stored and where computers or devices that contain such data are available. Implement policies and facility controls to ensure that only authorized and vetted persons can access areas where hard copy or digital data may be available. Also be sure to track and monitor all access to your system network and cardholder data.  \n\nConsult your DSU Consultant to ensure that your business has strong Access Controls and are properly conducting background checks.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Lock Up Tight.",
    action_icon: "28-lock-up-tight",
  },
  {
    id: 17,
    question:
      "Do you keep a record of personnel that log onto your Computer Network?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you may not be keeping a record of personnel that log into your computer network.  The PCI Security Standards Counsel wants to make certain that organizations are extremely careful about keeping close tabs and properly documenting that only a select number of properly screened personnel gain access into your computer network.  \n\nConsult your DSU Consultant regarding some easy ways to monitor, track and maintain audit trails to ensure strong access controls and security documentation.  ",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Update Your Handbook.",
    action_icon: "20-Update-Your-Handbook",
  },
  {
    id: 18,
    question:
      "Do you cover Data Security and PCI compliance in your employee handbook?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      'You indicated that you do not cover PCI-DSS compliance in your employee handbook. PCI-DSS rules require merchants to implement strong "access control measures" to assure that only authorized persons can access cardholder data. These "access control measures" include limiting access to cardholder data to employees whose jobs require such access; assigning unique identification numbers to employees who can access cardholder data; and restricting physical access to systems that contain cardholder data. Such policies should be adopted and incorporated into an employee handbook as well as a comprehensive written Information Security Plan that is provided to all employees along with PCI Cyber Security education and training.  \n\nHave your DSU Consultant ensure that your organization has the right PCI-DSS policies for your employee handbook and Information Security Plan.  Most importantly, your DSU Consultant will help see that your employees and vendors are receiving the necessary education and training so that these company policies are understood and being applied.  \n',
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Password Protection.",
    action_icon: "password protection",
  },
  {
    id: 19,
    question:
      'Does each employee have unique and complex passwords which have been changed from the "default" password, and are passwords for critical applications changed at least every 90 days?',
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that there may be a problem with either changing your POS system's default passwords or not giving employees unique passwords to log into your network wherein these passwords need to be routinely changed at least every 90 days. PCI-DSS rules require merchants to implement strong access control measures so that only authorized persons can access cardholder data. This includes assigning a unique and complex ID to each employee with computer access which needs to be routinely changed while being incorporated into multi-factor authentication \n\nConsult with your DSU Consultant  about multi-factor authentications as well as to confirm that you are compliant with PCI password protocol regarding your network management devices or remote access applications.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?\n\nIMPORTANT - MFA",
    hover_answer:
      "Default passwords come with hardware or software when it comes out of the box from the manufacturer or retailer.\"\n\nMulti-Factor Authentication: Don't rely on just passwords! Under PCI-DSS v3.2 merchants are to see that at least two security factors are utilized wherever there is access to cardholder data or sensitive authentication data originating within an organization’s own trusted networks (as has been the requirement when it came to untrusted, remote access into an organization’s cardholder data). This requirement is intended for authentication of personnel and applies to any administrator, third party, or internal individuals who have the ability to change systems and other credentials. Examples of different factor authentications: - known to the user such as a traditional password - user possession such as a hardware token or smart card, or - generates from the user’s own person such as a biometric (e.g. fingerprints, iris/retina scanning, facial imaging, voice recognition) ",

    action_title: "An Apple a Day...",
    action_icon: "15-An-Apple-A-Day",
  },
  {
    id: 20,
    question:
      "Do you use current Anti-Virus Software, and deploy Patch Management procedures for your computer systems?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you do not use, or do not know if you use updated Anti-Virus software or deploy Patch Management procedures. PCI-DSS rules require merchants to use and regularly update anti-virus software or programs. The requirements that merchants regularly update anti-virus software and ensure that all software and hardware have the latest security updates (patches) are important as hackers constantly develop new viruses and malware to exploit vulnerabilities in merchants' networks. Updated anti-virus software is necessary to recognize and combat these new viruses and malware.\n\nIf you are uncertain if you use updated anti-virus software and security patches, have your DSU Consultant see that the necessary software and patch installations are being made to protect your systems.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "Patch Management refers to a company policy that regularly installs security software updates that “patch” vulnerabilities that may be exploited by malware.",

    action_title: "Upgrade Your Systems.",
    action_icon: "07-get-updated",
  },
  {
    id: 21,
    question: "Do you still use Microsoft Windows XP or Windows 7?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You are still using Windows XP which Microsoft ceased providing security updates for on April 8, 2014. As a result your data has become unprotected on this old operating system. We understand it is natural to want to save money by hanging onto old equipment. However, the financial risk of keeping Windows XP is much too great. It is necessary to upgrade ASAP.  \n\nYour DSU Consultant can help you quickly and economically update your system.  \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "Microsoft ceased providing security updates and support for Windows XP and Windows 7, leaving your data unprotected on this old operating system.",

    action_title: "Drive Away From Secure Sockets Layers.",
    action_icon: "04-rules-of-the-road",
  },
  {
    id: 22,
    question:
      "Does your company still have security applications that use either Secure Sockets Layer (SSL) or Transport Layer Security (TLS) v1.0?",
    best_answer: "Yes",
    worst_answer: "No, IDK",
    action_plan:
      "PCI-DSS requires the removal of Secure Sockets Layer SSLv3 security protocols technology and Transport Layer Security TLS v 1.0 past June 30, 2018. Prior to this date merchants have been required to establish a formal PCI-DSS Risk Mitigation Plan to address existing implementation that use SSL and/or early TLS.\n\nIf you use POS terminals, check in with your DSU Consultant to see if their terminals are affected. Your DSU Consultant can also guide you on whether you need upgrades to your non-POS security protocol environments e.g. virtual payment terminals, back office servers, and user computers.\nNote: Although there had been a prior 2017 European Community Meeting that indicated that the June 30, 2018 deadline was under review by the Council, it is prudent to plan to no longer support these two protocols past that date or have appropriate compensating controls to address the risk.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "PCI DSS 3.1 warns of the dangers of SSL encryption and explains why you can no longer rely upon it.",

    action_title: "Gone Phishing.",
    action_icon: "Phishing",
  },
  {
    id: 23,
    question:
      "Does your company train employees to avoid opening/responding to “phishing” emails?",
    best_answer: "Yes",
    worst_answer: "No, IDK, What's phishing?",
    action_plan:
      "You indicated that your company has not performed adequate training to help employees from responding to phishing emails that trick employees into opening links and attachments which contain malware.  According to IBM, human error is the main cause of approximately 95% of cyber security breaches.  Basic employee training is critical to prevent employees from inadvertently exposing the company to hackers by improperly using company computers or being deceived by hackers employing social engineering tricks such as “Phishing”.  \n\nYour DSU Consultant can make sure that your personnel and vendors have the required education, training and phishing simulation programs to successfully combat this high exposure risk. \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "What is phishing?",
    hover_answer:
      "Phishing is when a fraudster sends out a legitimate-looking email that could be part of a phishing, smishing, vishing, spyware, ransomware, malware or social engineering effort in an attempt to trick a recipient/employee to provide confidential info or open a link containing a virus.",

    action_title: "Limit Employee Access.",
    action_icon: "Limit Employee Access",
  },
  {
    id: 24,
    question:
      "Are employees allowed to use company computers for their personal use (ex. check email, Facebook, surf the Internet)?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you may be permitting employees to access the Internet from your main, or back of the house computer which exposes your network to viruses and malware. This is of particular concern when employees use the main computer which collects and transmits sensitive company data. PCI-DSS rules require organizations to implement strong “access control measures” to ensure that only authorized persons can access sensitive company data. These “access control measures” include limiting access to company and cardholder data to only employees and personnel whose jobs require such access and restricting physical access to systems that contain such data. Most importantly, PCI DSS requires that you track and monitor all access to Network resources and cardholder data. \n\nHave a DSU Consultant provide you with company policies and employee training restricting access to the main, or back of the house computer to only those vetted personnel who have a business need for using it, and only then if they are using multi-factor authentication.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Trust but Verify Your Personnel.",
    action_icon: "background-check",
  },
  {
    id: 25,
    question:
      "Do you conduct background checks on employees who handle or have access to credit card data?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you may not presently be conducting background checks on employees who handle or have access to credit card data. PCI -DSS requires that a background screen be performed on any prospective employee (whether full-time, part-time or temporary) as well as contractors and consultants, who will have access to cardholder data or the cardholder data environment.  \n\nHave your DSU Consultant provide you with a reputable background screening vendor as well as expert legal advice regarding any legal or privacy constraints or limitations on performing background screenings. \n\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Call Centers.",
    action_icon: "05-Call Centers",
  },
  {
    id: 26,
    question:
      "Do you give third party Call Centers or Suppliers access to your critical systems and do you monitor that they are complying with PCI-DSS and fulfilling contractual responsibilities to your company to adhere to data security standards?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that your company is utilizing either third party call centers or suppliers that have access to your IT systems or customer data, but questions remain as to whether these third parties are fulfilling their PCI responsibilities. Many security breaches have been the result of call centers or supplier vendors that have inadvertently provided hackers an entry point into their customer’s sensitive data systems. It is essential that you only do business with third parties that can demonstrate that they have compliant systems and security practices which can easily be monitored. It also is critical  to have good contracts with third parties that identify PCI-DSS responsibilities upfront and hold third parties accountable for their actions.  \n\nHave your DSU Consultant show you how to better ensure that the proper safeguards are in place for dramatically reducing cyber security risks associated with vendors and contracts. \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "There have been too many incidences where third party call centers or suppliers have inadvertently exposed their client companies to hackers.",

    action_title: "Develop A Playbook",
    action_icon: "30-Develop-a-Playbook",
  },
  {
    id: 27,
    question:
      "Do you have a written plan for responding to a Data Security Breach?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you do not have policies and procedures for dealing with suspected data security breaches. The PCI-DSS rules require merchants to maintain, and disseminate an Information  Security Policy that covers all personnel and addresses all PCI-DSS requirements. Failure to maintain such a policy constitutes a violation of the PCI-DSS rules. Additionally, merchants need to develop protocols for rapidly responding to suspected data security breaches once they occur which are to be contained within an Incident Response Plan.  \n\nHave your DSU Consultant provide you with the necessary PCI company policies as well as an Incident Response Plan tailored to your company.   \n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: "Why do we ask this?",
    hover_answer:
      "If you haven’t put together an Incident Response Plan in advance of a breach – you will struggle to adequately respond to a breach.",

    action_title: "A Good Cyber Policy = A Good Corp Policy",
    action_icon: "Cyber Policy",
  },
  {
    id: 28,
    question:
      "Does your insurance provide coverage for Data Security Breaches?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan:
      "You indicated that you do not currently have Cyber Insurance coverage. Small and Medium sized businesses are the primary targets of hackers. Most businesses have difficulty absorbing the damage (both financial and to the company’s reputation) associated with experiencing a data security breach. This is why a significant number of small and midsize businesses hit by a cyber-attack will be out of business within six to twelve months. Any effective Incident Response Plan needs to include a comprehensive cyber security policy to safeguard your business. \n\nAlthough it is not a PCI-DSS requirement; it is critical to protect your company with the proper cyber insurance coverage. Let your DSU Consultant help ensure that your organization has comprehensive cyber security coverage that is tailored to your organization's exact needs, risks and budget.\n",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: null,
    action_icon: null,
  },

  // {
  //   id: 1,
  //   question:
  //     "Was your POS system installed by a qualified 3rd party with POS software that is certified to be compliant?",
  //   best_answer: "Yes",
  //   worst_answer: "You or your employees",
  //   action_plan:
  //     "Your answer indicated uncertainty regarding whether your POS system is PCI certified and has been installed by a PCI trained professional.  Uncertified systems lack the safeguards to protect your system from damaging data security breaches.  Outdated, improperly installed and maintained POS systems also increase the risk of data security breaches and bring with them PCI fines and penalties.  For this reason, Visa mandates that PCI Level 4 small merchants must use payment solution providers that have achieved Qualified Integrator and Reseller (QIR) certification.  \n\nYour DSU Consultant can help you determine if your POS system has been properly certified and that you are working with trained and approved PCI DSS vendors to keep your system compliant.   ",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "Why do we ask this?",
  //   hover_answer:
  //     'Poorly trained installers have been responsible for creating security vulnerabilities and opening the door to hackers. As a result, Visa now mandates that small merchants (Level 4) must use payment solution providers that are PCI certified and registered as Qualified Integrators & Resellers (QIR)."',

  //   action_title: "Check the Plumbing",
  //   action_icon: "09-Check-the-plumbing",
  // },
  // {
  //   id: 4,
  //   question:
  //     "Do you have a current support and maintenance agreement for your POS?",
  //   best_answer: "Yes",
  //   worst_answer: "No , IDK",
  //   action_plan:
  //     "Get Regular Checkups.\nYou indicated that you either do not, or do not know if you maintain a current support and maintenance plan with your POS provider. Updates or patches to a POS system's software help maintain its security. POS vendors generally provide such updates and patches only to customers who maintain a current support and maintenance plan. \n\nAsk your DSU Consultant the best means to determine whether you maintain a current support and maintenance plan and whether the offered plan includes security features that will protect your POS system from data security breaches.\n\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "Why do we ask this?[",
  //   hover_answer:
  //     "Responsible maintenance services are more apt to focus on PCI compliance.",

  //   action_title: "Get Regular Checkups",
  //   action_icon: "10-Get-Regular-Checkups",
  // },
  // {
  //   id: 5,
  //   question:
  //     "Do you or your POS vendor use a remote desktop application to connect remotely to your POS system?",
  //   best_answer: "Yes",
  //   worst_answer: "No , IDK",
  //   action_plan:
  //     "Make Remote Access Secure Access.\nYou indicated that you use a remote desktop application in your business. Many POS systems come equipped with remote desktop applications that permit business owners to manage their POS systems remotely or permit POS providers to service their systems remotely. A major concern with such remote desktop applications is password security resulting from default or vendor-supplied passwords.  Additionally, companies are to use \"multi-factor authentication\" (MFA) which requires two or more pieces of evidence to verify a user's identity  to gain access to a company's network.  In addition to passwords and security questions, MFA could entail that a user providel a SMS code and or fingerprint or face ID.  \nConsult with your DSU Consultant to ensure that you are not using unsecure remote desktop applications and default passwords for those applications.   \n\nLet your DSU Consultant provide guidance on the PCI-DSS requirements for proper use of multi-factor authentication (MFA) for preventing unauthorized access to computers and systems that process payment transactions.\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "Why do we ask this?[",
  //   hover_answer:
  //     "It’s OK to connect remotely if you are cautious and follow compliance requirements.",

  //   action_title: "Make Remote Access Secure Access.",
  //   action_icon: "31-Make-Remote-Access-Secure-Access",
  // },
  // {
  //   id: 6,
  //   question:
  //     "Do you personally consult with any advisors, specialists, or vendors regarding PCI compliance?",
  //   best_answer: "Yes",
  //   worst_answer: "No , IDK",
  //   action_plan:
  //     "Hire the Pros\nYou indicated that you do not regularly consult with an Approved Scanning Vendor (ASV), Qualified Security Assessor (QSA), a trained PCI IT consultant, or PCI attorney regarding your PCI-DSS obligations. This reflects low engagement with, and perhaps violations of, the PCI-DSS rules.\nAs the PCI Security Standards Council understands how easy it is for merchants to be exploited by vendors that are not qualified to safeguard your company's data, PCI-DSS requires that merchants work only with security vendors with PCI certified seals of approval.  For instance, only Approved Scanning Vendors (ASVs) are qualified to manage mandated quarterly network scans, and  Visa mandates that small merchants must only use payment solution providers that have  Qualified Integrator and Reseller (QIR) certification. \n\nLet a DSU Consultant see that you are placed with the right certified vendor that will keep your business safe and compliant. \n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: null,
  //   hover_answer: null,

  //   action_title: "Hire the Pros",
  //   action_icon: "12-Hire-the-Pros",
  // },
  // {
  //   id: 7,
  //   question:
  //     "Have you ever completed the PCI-DSS Self-Assessment Questionnaire?",
  //   best_answer: "Yes",
  //   worst_answer: "No, I did not finish it, Never heard of it.",
  //   action_plan:
  //     "Do Your Homework.\nYou indicated that you have not completed the PCI-DSS Self Assessment Questionnaire. All merchants who accept credit cards are required to complete the SAQ on an annual basis. If you have not completed the SAQ, you are currently non-compliant.\n\nMany merchants find completing the SAQ to be challenging and hard to understand . Have your DSU Consultant make the SAQ experience both an easy and helpful experience. \n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "Why do we ask this?",
  //   hover_answer:
  //     "Failure to properly and accurately fill out SAQ’s can lead to serious problems.",

  //   action_title: "Do Your Homework.",
  //   action_icon: "06-do-your-homework",
  // },
  // {
  //   id: 8,
  //   question:
  //     "Beginning in February 2018 have you completed a significant IT change to your systems or networks such as installing new system components; modifying firewall rules, implementing applications or operating system upgrades, installing new web servers, or changing your network or sub-network? If Yes, Did your PCI-certified specialist confirm as well as properly document that relevant PCI requirements were in place prior to going live with the new or changed system?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "With Change Comes Compliance\nYou indicated that since February 2018 you made a significant IT change and may not have ensured that all the relevant PCI requirements were in place prior to activation. It is essential that as part of your organization's change control process that there be sufficient testing such as vulnerability scanning and penetration testing. It is also important that there be clear documentation of the application of PCI requirements.\n\nHave your DSU Consultant help you determine that your organization stays compliant as your IT system evolves and that you have the adequate amount of vulnerability scanning and penetration testing by qualified and vetted PCI professionals.\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: null,
  //   hover_answer: null,

  //   action_title: "With Change Comes Compliance",
  //   action_icon: "with-change-comes-compliance",
  // },
  // {
  //   id: 9,
  //   question:
  //     "As of 2/1/18, have you directed your authorized personnel with administrative access to incorporate multi-factor authentication for all non-console access into your Cardholder Data Environment (CDE) system or device?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Variety is the Spice of Authentication\nYou indicated that as of the beginning of 2/1/18 you may not have provided the additional security measure of having multi-factor authentication to your personnel with administrative access to your non-console Cardholder Data Environment (CDE) systems and network. The PCI Data Security Council required the multi-factor security authentication in 2018 so as to minimize the data security breaches that have occurred due to hackers targeting administrators (spear-fishing). \n\nHave your DSU Consultant ensure that you are properly implementing multi-factor authentication.\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "What is a console?",
  //   hover_answer:
  //     "A console is typically considered the combination of display monitor and keyboard (or other device that allows input). Another term for console is terminal. The term console usually refers to a terminal attached to a minicomputer or mainframe and used to monitor the status of the system.",

  //   action_title: "Variety is the Spice of Authentication",
  //   action_icon: "variety-is-the-spice-of-authentication",
  // },
  // {
  //   id: 10,
  //   question: "Do you conduct Quarterly Vulnerability Scans?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Get Quarterly Scans.\nYou indicated that you do not implement, or do not know if you implement quarterly network scans. PCI-DSS rules require all merchants to complete four quarterly network scans, performed by an Approved Scanning Vendor (ASV).\n\nCall on your DSU Consultant to have an approved ASV conduct your quarterly network scans to keep your company secure and compliant with PCI-DSS requirements.\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "Why do we ask this?",
  //   hover_answer:
  //     "The automated process of proactively identifying vulnerabilities of computing systems in a network in order to determine if and where a system can be exploited and/or threatened. To maintain PCI compliance, scans must be performed on at least a quarterly basis.",

  //   action_title: "Get Quarterly Scans.",
  //   action_icon: "Get-Quarterly-Scans",
  // },
  // {
  //   id: 11,
  //   question:
  //     "Do you maintain a properly configured Firewall that protects consumer card data?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "You indicated that you either do not maintain, or do not know if you maintain, a properly configured firewall.  Firewall capability is often built into other network devices, such as routers, which control the flow of data in and out of your network.  Even when firewalls have been properly installed initially;  too many serious data breaches have occurred as a result of the failure of merchants to have their firewalls properly reconfigured after they installed a DSL line or Wi-Fi network.\n\nHave your DSU Consultant provide you with guidance on whether you have a quality firewall that has been properly installed or reconfigured.",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "What is a Firewall?",
  //   hover_answer:
  //     "A Firewall is a system designed to prevent unauthorized access to a private network. All messages entering or leaving the network pass through the Firewall, which examines each message and blocks those that do not meet specific security criteria.",

  //   action_title: "Shore Up Your Defenses",
  //   action_icon: "13-Shore-Up-Your-Defenses",
  // },
  // {
  //   id: 12,
  //   question:
  //     "Do you maintain a wireless Computer Network? Do you block access to computers that transmit cardholder data?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     'Segment Your Wi-Fi.\nYou indicated that you maintain a wireless network but that you do not, or do not know whether it is on a separate feed. With the emergence and prevalence of Wi-Fi technology, the PCI Security Standards Council has adopted segmentation for safely maintaining wireless networks. A key concern is "segmenting", or segregating, cardholder data from other wireless network traffic. Cardholder data must be completely segregated from other network traffic using a firewall or some other means.\n\nConsult your DSU Consultant to make certain that your company is correctly applying segmentation of your wireless networks.  \n',
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: null,
  //   hover_answer: null,

  //   action_title: "Segment Your Wi-Fi.",
  //   action_icon: "18-Segment-Your-Wi-fi",
  // },
  // {
  //   id: 13,
  //   question: "Do you use a VPN Router to transmit data between devices?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Get Virtual.\nYou indicated that you do not, or do not know whether you use a Virtual Private Network (VPN) router. A VPN router is a particular type of router that encrypts data, such as customer credit card data, as it passes through a network. PCI-DSS rules require merchants to encrypt cardholder data as it is transmitted across open, public networks. Use of a VPN router is one way to comply with this requirement.\n\nAs not all VPN routers are of high quality and function, have your DSU Consultant see that vendors have installed the proper VPN devices to properly encrypt cardholder data in compliance with PCI-DSS requirements.\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "What is a Router?",
  //   hover_answer:
  //     "A Router is a device that forwards data packets along networks. A VPN (Virtual Private Network) Router encrypts data between points on a network. ",

  //   action_title: "Get Virtual.",
  //   action_icon: "14-Get-Virtual",
  // },
  // {
  //   id: 15,
  //   question:
  //     "Do you store customer credit card data either electronically or in paper format?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Don't Store Customer Data.\nYou indicated that you do, or you might, store customer credit card data either electronically or in paper format. The storage of customer card data is a problem that plagues older, or “legacy,” POS systems. Such legacy POS systems may save customer credit card information for longer than commercially necessary (or perhaps even permanently), creating a treasure trove of sensitive information for hackers. Thus, the PCI-DSS Rules prohibit merchants from storing customer card information after a card transaction is authorized. \n\nIf you think your POS system may be improperly storing customer card data, let your DSU Consultant help to see that your organization is both PCI-DSS secure and compliant. \n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: null,
  //   hover_answer: null,

  //   action_title: "Don't Store Customer Data.",
  //   action_icon: "25-dont-store-customer-data",
  // },
  // {
  //   id: 16,
  //   question: "Have you implemented EMV e.g. Chip & Pin/Chip & Signature?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Be Smart with your Smart Card\nYou indicated that you may not be implementing all of the primary technology tools that are required to best safeguard your company from a security breach. Merchants need to make the necessary investment in EMV credit card readers for their POS systems to better guard against counterfeit and stolen cards. \n\nHave your DSU Consultant help you take advantage of the DSU vendor discount for EMV credit card readers. \n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "What is EMV?\nIMPORTANT - Liability Shift",
  //   hover_answer:
  //     "EMV makes credit cards less vulnerable to fraud by putting a microprocessor chip into debit and credit cards.\n\nLiability Shift: The Liability Shift means that counterfeit card-present transactions between merchants and card issuers (banks, credit unions, etc) create liability shifts away from the issuer (where it traditionally has resided) to the merchant if the merchant fails to become EMV compliant (assuming that the issuer provides its customers with EMV-compliant cards). To become EMV compliant, merchants must take on the expense of implementing payment terminals and upgrading software necessary for their terminals to “read” the EMV- enabled cards. Between the Merchant and the Issuer, it is the party that fails to offer EMV-compliant devices (the merchant), or cards (the issuer), that will face liability for counterfeit card transactions. ",

  //   action_title: "Be Smart with your Smart Card",
  //   action_icon: "29-Watch-Out-For-Skimmers",
  // },
  // {
  //   id: 17,
  //   question: "Have you implemented Point-to-Point encryption (P2PE)?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Encryption is the Key\nYou indicated that you may not be implementing all of the primary technology tools that are required to safeguard your company from a security breach. Point-to-Point encryption secures against hackers during credit card data transmission.  \n\nPoint-to-Point Encryption only sounds like it is something out of a spy movie but a DSU Consultant can show you how easily P2PE Encryption operates and how it can be affordably integrated into your business.  \n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "What is Point to Point encryption?\nIMPORTANT - TIP ",
  //   hover_answer:
  //     "Point-to-Point encryption (P2PE) is a 3rd party solution that encrypts data from the credit card swipe until data reaches a secure decryption environment. P2PE is the only PCI-DSS approved encryption solution as opposed to End-to-End eEncryption.\n\nTechnology Innovation Program (TIP): Under Visa 1/31/17 mandate, acquirers must ensure that Level 4 merchants are to annually validate PCI-DSS compliance or participate in the Technology Innovation Program (TIP). In order to discontinue its annual PCI-DSS validation assessment under TIP, a merchant must meet the following criteria: Confirm that sensitive data is not stored subsequent to transaction authorization Ensure that at least 75% of all transactions originate through either i) EMV chip reading terminals or ii) PCI SSC-validated P2PE solution. ",

  //   action_title: "Encryption is the Key",
  //   action_icon: "the-key-to-encryption",
  // },
  // {
  //   id: 18,
  //   question: "Have you implemented Tokenization?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "More Than a Token of Your Security\nYou indicated that you may not be implementing all of the primary technology tools that are required to safeguard your company from a security breach. Tokenization secures credit card data at rest.  \n\nAs with P2PE Encryption, DSU Consultants are available to see that Tokenization is seamlessly applied to your operations. \n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "What is Tokenization?",
  //   hover_answer:
  //     "Tokenization protects data by replacing it with alias values/tokens that are meaningless to someone obtaining unauthorized access.",

  //   action_title: "More Than a Token of Your Security",
  //   action_icon: "a-token-of-security",
  // },
  // {
  //   id: 19,
  //   question:
  //     "Do you physically restrict access to areas where credit card data is present?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Lock Up Tight.\nYou indicated that you may not be restricting access to your company’s critical data to only those employees who have demonstrated a need for access to your company’s critical data and who have been vetted by background checks. PCI-DSS rules require organizations to implement strong access control measures to ensure that only authorized persons can access such data.\nStrong Access Controls require that you physically restrict access to places where hard copy data is stored and where computers or devices that contain such data are available. Implement policies and facility controls to ensure that only authorized and vetted persons can access areas where hardcopy or digital data may be available. Also be sure to track and monitor all access to your system network and cardholder data.  \n\nConsult your DSU Consultant to ensure that your business has strong Access Controls and are properly conducting background checks.\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: null,
  //   hover_answer: null,

  //   action_title: "Lock Up Tight.",
  //   action_icon: "28-lock-up-tight",
  // },
  // {
  //   id: 20,
  //   question:
  //     "Do you keep a record of personnel that log onto your Computer Network?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Do you keep a record of personnel that log into your Computer Network?  \nYou indicated that you may not be keeping a record of personnel that log into your computer network.  The PCI Security Standards Counsel wants to make certain that organizations are extremely careful about keeping close tabs and properly documenting that only a select number of properly screened personnel gain access into your computer network.  \n\nConsult your DSU Consultant regarding some easy ways to monitor, track and maintain audit trails to ensure strong access controls and security documentation.  ",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: null,
  //   hover_answer: null,

  //   action_title: "Update Your Handbook.",
  //   action_icon: "20-Update-Your-Handbook",
  // },
  // {
  //   id: 21,
  //   question:
  //     "Do you cover Data Security and PCI compliance in your employee handbook?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     'Update Your Handbook.\nYou indicated that you do not cover PCI-DSS compliance in your employee handbook. PCI-DSS rules require merchants to implement strong "access control measures" to assure that only authorized persons can access cardholder data. These "access control measures" include limiting access to cardholder data to employees whose jobs require such access; assigning unique identification numbers to employees who can access cardholder data; and restricting physical access to systems that contain cardholder data. Such policies should be adopted and incorporated into an employee handbook as well as a comprehensive written Information Security Plan that is provided to all employees along with PCI Cyber Security education and training.  \n\nHave your DSU Consultant ensure that your organization has the right PCI-DSS policies for your employee handbook and Information Security Plan.  Most importantly, your DSU Consultant will help see that your employees and vendors are receiving the necessary education and training so that these company policies are understood and being applied.  \n',
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: null,
  //   hover_answer: null,

  //   action_title: "Password Protection.",
  //   action_icon: "password protection",
  // },
  // {
  //   id: 22,
  //   question:
  //     'Does each employee have unique and complex passwords which have been changed from the "default" password, and are passwords for critical applications changed at least every 90 days?',
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Password Protection.\nYou indicated that there may be a problem with either changing your POS system's default passwords or not giving employees unique passwords to log into your network wherein these passwords need to be routinely changed at least every 90 days. PCI-DSS rules require merchants to implement strong access control measures so that only authorized persons can access cardholder data. This includes assigning a unique and complex ID to each employee with computer access which needs to be routinely changed while being incorporated into multi-factor authentication \n\nConsult with your DSU Consultant  about multi-factor authentications as well as to confirm that you are compliant with PCI password protocol regarding your network management devices or remote access applications.\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "Why do we ask this?\n\n]IMPORTANT - MFA",
  //   hover_answer:
  //     "Default passwords come with hardware or software when it comes out of the box from the manufacturer or retailer.\"\n\nMulti-Factor Authentication: Don't rely on just passwords! Under PCI-DSS v3.2 merchants are to see that at least two security factors are utilized wherever there is access to cardholder data or sensitive authentication data originating within an organization’s own trusted networks (as has been the requirement when it came to untrusted, remote access into an organization’s cardholder data). This requirement is intended for authentication of personnel and applies to any administrator, third party, or internal individuals who have the ability to change systems and other credentials. Examples of different factor authentications: - known to the user such as a traditional password - user possession such as a hardware token or smart card, or - generates from the user’s own person such as a biometric (e.g. fingerprints, iris/retina scanning, facial imaging, voice recognition) ",

  //   action_title: "An Apple a Day...",
  //   action_icon: "15-An-Apple-A-Day",
  // },
  // {
  //   id: 23,
  //   question:
  //     "Do you use current Anti-Virus Software, and deploy Patch Management procedures for your computer systems?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "An Apple a Day...\nYou indicated that you do not use, or do not know if you use updated Anti-Virus software or deploy Patch Management procedures. PCI-DSS rules require merchants to use and regularly update anti-virus software or programs. The requirements that merchants regularly update anti-virus software and ensure that all software and hardware have the latest security updates (patches) are important as hackers constantly develop new viruses and malware to exploit vulnerabilities in merchants' networks. Updated anti-virus software is necessary to recognize and combat these new viruses and malware.\n\nIf you are uncertain if you use updated anti-virus software and security patches, have your DSU Consultant see that the necessary software and patch installations are being made to protect your systems.\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "Why do we ask this?",
  //   hover_answer:
  //     "Patch Management refers to a company policy that regularly installs security software updates that “patch” vulnerabilities that may be exploited by malware.",

  //   action_title: "Upgrade Your Systems.",
  //   action_icon: "07-get-updated",
  // },
  // {
  //   id: 24,
  //   question: "Do you still use Microsoft Windows XP or Windows 7?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Upgrade Your Systems. (XP)\nYou are still using Windows XP which Microsoft ceased providing security updates for on April 8, 2014. As a result your data has become unprotected on this old operating system. We understand it is natural to want to save money by hanging onto old equipment. However, the financial risk of keeping Windows XP is much too great. It is necessary to upgrade ASAP.  \n\nYour DSU Consultant can help you quickly and economically update your system.  \n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "Why do we ask this?",
  //   hover_answer:
  //     "Microsoft ceased providing security updates and support for Windows XP and Windows 7, leaving your data unprotected on this old operating system.",

  //   action_title: "Drive Away From Secure Sockets Layers.",
  //   action_icon: "04-rules-of-the-road",
  // },
  // {
  //   id: 25,
  //   question:
  //     "Does your company still have security applications that use either Secure Sockets Layer (SSL) or Transport Layer Security (TLS) v1.0?",
  //   best_answer: "Yes",
  //   worst_answer: "No, IDK",
  //   action_plan:
  //     "Drive Away From Secure Sockets Layers.\nPCI-DSS requires the removal of Secure Sockets Layer SSLv3 security protocols technology and Transport Layer Security TLS v 1.0 past June 30, 2018. Prior to this date merchants have been required to establish a formal PCI-DSS Risk Mitigation Plan to address existing implementation that use SSL and/or early TLS.\nIf you use POS terminals, check in with your DSU Consultant to see if their terminals are affected. Your DSU Consultant can also guide you on whether you need upgrades to your non-POS security protocol environments e.g. virtual payment terminals, back office servers, and user computers.\nNote: Although there had been a prior 2017 European Community Meeting that indicated that the June 30, 2018 deadline was under review by the Council, it is prudent to plan to no longer support these two protocols past that date or have appropriate compensating controls to address the risk.\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "Why do we ask this?",
  //   hover_answer:
  //     "PCI DSS 3.1 warns of the dangers of SSL encryption and explains why you can no longer rely upon it.",

  //   action_title: "Gone Phishing.",
  //   action_icon: "Phishing",
  // },
  // {
  //   id: 26,
  //   question:
  //     "Does your company train employees to avoid opening/responding to “phishing” emails?",
  //   best_answer: "Yes",
  //   worst_answer: "No, IDK, What's phishing?",
  //   action_plan:
  //     "Gone Phishing.  \nYou indicated that your company has not performed adequate training to help employees from responding to phishing emails that trick employees into opening links and attachments which contain malware.  According to IBM, human error is the main cause of approximately 95% of cyber security breaches.  Basic employee training is critical to prevent employees from inadvertently exposing the company to hackers by improperly using company computers or being deceived by hackers employing social engineering tricks such as “Phishing”.  \n\nYour DSU Consultant can make sure that your personnel and vendors have the required education, training and phishing simulation programs to successfully combat this high exposure risk. \n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "What is phishing?",
  //   hover_answer:
  //     "Phishing is when a fraudster sends out a legitimate-looking email that could be part of a phishing, smishing, vishing, spyware, ransomware, malware or social engineering effort in an attempt to trick a recipient/employee to provide confidential info or open a link containing a virus.",

  //   action_title: "Limit Employee Access.",
  //   action_icon: "Limit Employee Access",
  // },
  // {
  //   id: 27,
  //   question:
  //     "Are employees allowed to use company computers for their personal use (ex. check email, Facebook, surf the Internet)?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Limit Employee Access.\nYou indicated that you may be permitting employees to access the Internet from your main, or back of the house computer which exposes your network to viruses and malware. This is of particular concern when employees use the main computer which collects and transmits sensitive company data. PCI-DSS rules require organizations to implement strong “access control measures” to ensure that only authorized persons can access sensitive company data. These “access control measures” include limiting access to company and cardholder data to only employees and personnel whose jobs require such access and restricting physical access to systems that contain such data. Most importantly, PCI DSS requires that you track and monitor all access to Network resources and cardholder data. \n\nHave a DSU Consultant provide you with company policies and employee training restricting access to the main, or back of the house computer to only those vetted personnel who have a business need for using it, and only then if they are using multi-factor authentication.\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: null,
  //   hover_answer: null,

  //   action_title: "Trust but Verify Your Personnel.",
  //   action_icon: "backgroudn-check",
  // },
  // {
  //   id: 28,
  //   question:
  //     "Do you conduct background checks on employees who handle or have access to credit card data?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Trust but Verify Your Personnel.\nYou indicated that you may not presently be conducting background checks on employees who handle or have access to credit card data. PCI -DSS requires that a background screen be performed on any prospective employee (whether full-time, part-time or temporary) as well as contractors and consultants, who will have access to cardholder data or the cardholder data environment.  \n\nHave your DSU Consultant provide you with a reputable background screening vendor as well as expert legal advice regarding any legal or privacy constraints or limitations on performing background screenings. \n\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: null,
  //   hover_answer: null,

  //   action_title: "Call Centers.",
  //   action_icon: "05-Call Centers",
  // },
  // {
  //   id: 29,
  //   question:
  //     "Do you give third party Call Centers or Suppliers access to your critical systems and do you monitor that they are complying with PCI-DSS and fulfilling contractual responsibilities to your company to adhere to data security standards?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Call Centers.\nYou indicated that your company is utilizing either third party call centers or suppliers that have access to your IT systems or customer data, but questions remain as to whether these third parties are fulfilling their PCI responsibilities. Many security breaches have been the result of call centers or supplier vendors that have inadvertently provided hackers an entry point into their customer’s sensitive data systems. It is essential that you only do business with third parties that can demonstrate that they have compliant systems and security practices which can easily be monitored. It also is critical  to have good contracts with third parties that identify PCI-DSS responsibilities upfront and hold third parties accountable for their actions.  \n\nHave your DSU Consultant show you how to better ensure that the proper safeguards are in place for dramatically reducing cyber security risks associated with vendors and contracts. \n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "Why do we ask this?",
  //   hover_answer:
  //     "There have been too many incidences where third party call centers or suppliers have inadvertently exposed their client companies to hackers.",

  //   action_title: "Develop A Playbook",
  //   action_icon: "30-Develop-a-Playbook",
  // },
  // {
  //   id: 31,
  //   question:
  //     "Do you have a written plan for responding to a Data Security Breach?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "Develop a Playbook.\nYou indicated that you do not have policies and procedures for dealing with suspected data security breaches. The PCI-DSS rules require merchants to maintain, and disseminate an Information  Security Policy that covers all personnel and addresses all PCI-DSS requirements. Failure to maintain such a policy constitutes a violation of the PCI-DSS rules. Additionally, merchants need to develop protocols for rapidly responding to suspected data security breaches once they occur which are to be contained within an Incident Response Plan.  \n\nHave your DSU Consultant provide you with the necessary PCI company policies as well as an Incident Response Plan tailored to your company.   \n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: "Why do we ask this?",
  //   hover_answer:
  //     "If you haven’t put together an Incident Response Plan in advance of a breach – you will struggle to adequately respond to a breach.",

  //   action_title: "A Good Cyber Policy = A Good Corp Policy",
  //   action_icon: "Cyber Policy",
  // },
  // {
  //   id: 34,
  //   question:
  //     "Does your insurance provide coverage for Data Security Breaches?",
  //   best_answer: "Yes",
  //   worst_answer: "No or IDK",
  //   action_plan:
  //     "A Good Cyber Policy = A Good Corp Policy\nYou indicated that you do not currently have Cyber Insurance coverage. Small and Medium sized businesses are the primary targets of hackers. Most businesses have difficulty absorbing the damage (both financial and to the company’s reputation) associated with experiencing a data security breach. This is why a significant number of small and midsize businesses hit by a cyber-attack will be out of business within six to twelve months. Any effective Incident Response Plan needs to include a comprehensive cyber security policy to safeguard your buisness. \n\nAlthough it is not a PCI-DSS requirement; it is critical to protect your company with the proper cyber insurance coverage. Let your DSU Consultant help ensure that your organization has comprehensive cyber security coverage that is tailored to your organization's exact needs, risks and budget.\n",
  //   criticality: null,
  //   solution_provider_bucket: null,
  //   Notes: null,
  //   hover_question: null,
  //   hover_answer: null,

  //   action_title: null,
  //   action_icon: null,
  // },
];
const gdpr = [];
const breach = [
  {
    id: 1,
    question: "Do you have a written Cyber Incident Response (CIR) Plan?",
    best_answer: "Yes\n",
    worst_answer: "You or your employees",
    action_plan:
      "Stop what you're doing right now and create an incident response plan. ",
    criticality: null,
    solution_provider_bucket: "Breach Coach",
    Notes:
      "We could direct them to a breach coach who can help or we can create a resource that helps put together a response plan.",
    hover_question: "Why do we ask this?",
    hover_answer:
      "A written Cyber Incident Reponse Plan is the backbone of any Breach Readiness Program.  Without a plan, responding to an incident tends to be chaotic and disorganized. ",

    action_title: "Get busy",
    action_icon: "09-Check-the-plumbing",
  },
  {
    id: 2,
    question:
      "Is your CIR Plan physically accessible in the event all other means of access it are compromised?",
    best_answer: "Yes",
    worst_answer: "No , IDK",
    action_plan:
      "Print a copy of the Incident Response Plan and ensure every person tasked with managing an incident has their own copy of an updated IR Plan.",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer:
      "If you have a Cyber Incident Response Plan, but it is not in a hardcopy version, you risk not being able to access it when you need it. ",

    action_title: "Get Regular Checkups",
    action_icon: "10-Get-Regular-Checkups",
  },
  {
    id: null,
    question:
      "Does your CIR Plan include making outside counsel your first phone call?",
    best_answer: null,
    worst_answer: null,
    action_plan:
      "Outside counsel should be the first phone call to protect privilege and to ensure you are getting the best advice possible when an incident occurs.",
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: null,
    action_icon: null,
  },
  {
    id: 3,
    question:
      "Does your CIR Plan include your company's position on paying ransoms?",
    best_answer: "Yes",
    worst_answer: "No , IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Make Remote Access Secure Access.",
    action_icon: "31-Make-Remote-Access-Secure-Access",
  },
  {
    id: 4,
    question:
      "Does your CIR Plan include your company's regulatory reporting requirements?",
    best_answer: "Yes",
    worst_answer: "No , IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Hire the Pros",
    action_icon: "12-Hire-the-Pros",
  },
  {
    id: 5,
    question:
      "Does your CIR Plan include your company's position on public acknowledgement of an incident?",
    best_answer: "Yes",
    worst_answer: "No, I did not finish it, Never heard of it.",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Do Your Homework.",
    action_icon: "06-do-your-homework",
  },
  {
    id: 6,
    question:
      "Does your CIR include your company's position on notification of affected individuals?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "With Change Comes Compliance",
    action_icon: "with-change-comes-compliance",
  },
  {
    id: 7,
    question:
      "Does your CIR Plan contain a Standard Operating Procedure including roles and responsibilties, backup communications systems, physical assets and resources, and temporary facilities that can be used in the event of an Incident?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Variety is the Spice of Authentication",
    action_icon: "variety-is-the-spice-of-authentication",
  },
  {
    id: 8,
    question:
      "Has your CIR plan been reviewed by outside counsel for compliance with all relevant laws and regulations?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Get Quarterly Scans.",
    action_icon: "Get-Quarterly-Scans",
  },
  {
    id: 9,
    question:
      "Do you confirm once a month that the people and resources outlined in your Standard Operating Procedure are available?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Shore Up Your Defenses",
    action_icon: "13-Shore-Up-Your-Defenses",
  },
  {
    id: 10,
    question:
      "Do you review and/or update your CIR plan at least once per quarter to ensure accuracy?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Segment Your Wi-Fi.",
    action_icon: "18-Segment-Your-Wi-fi",
  },
  {
    id: 11,
    question:
      "Do you have templates developed and readily accesssible in the even of a cyber incident?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Get Virtual.",
    action_icon: "14-Get-Virtual",
  },
  {
    id: 12,
    question:
      "Does your CIR Plan contain Standard Operating Procedure including roles and responsibilties, backup communications systems, physical assets and resources, and temporary facilities that can be used in with event Smart an Incident?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Be Smart with your Smart Card",
    action_icon: "29-Watch-Out-For-Skimmers",
  },
  {
    id: 13,
    question:
      "Have you formed a dedicated team consisting of Incident Response and Executives with authority to manage and respond to an incident?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Encryption is the Key",
    action_icon: "the-key-to-encryption",
  },
  {
    id: 14,
    question:
      "Do you hold breach or incident simulations to test the effectiveness of your incident response plan at least once per quarter?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "More Than a Token of Your Security",
    action_icon: "a-token-of-security",
  },
  {
    id: 15,
    question:
      "Do staff involved in managing incidents receive training at least once per quarter?",
    best_answer: "Yes",
    worst_answer: "No or IDK",
    action_plan: null,
    criticality: null,
    solution_provider_bucket: null,
    Notes: null,
    hover_question: null,
    hover_answer: null,

    action_title: "Lock Up Tight.",
    action_icon: "28-lock-up-tight",
  },
];
export const qList = {
  GetQuestions: async (assessment_type) => {
    // console.info("NEW QUESTION FORMAT TYPE", assessment_type);
    let question = await postRequest("get-assessment-question", {
      assessment_type,
    });
    if (question) {
      return question.data.data.length === 0 ||
        question.data.data.length === undefined
        ? []
        : question.data.data;
    } else {
      return [];
    }

    // switch (assessment_type) {
    //   case "sample":
    //     // const r = o;
    //     return o;
    //   case "pci":
    //     let question = await postRequest("get-assessment-question", {
    //       assessment_type,
    //     });
    //     return question.data.data.length === 0 ||
    //       question.data.data.length === undefined
    //       ? []
    //       : question.data.data;
    //   case "csf":
    //     let questionCsf = await postRequest("get-assessment-question", {
    //       assessment_type,
    //     });
    //     return questionCsf.data.data.length === 0 ||
    //       questionCsf.data.data.length === undefined
    //       ? []
    //       : questionCsf.data.data;
    //   case "breach":
    //     let questionBreach = await postRequest("get-assessment-question", {
    //       assessment_type,
    //     });
    //     return questionBreach;
    //   case "pre":
    //     let questionPre = await postRequest("get-assessment-question", {
    //       assessment_type,
    //     });
    //     return questionPre.data.data.length === 0 ||
    //       questionPre.data.data.length === undefined
    //       ? []
    //       : questionPre.data.data;
    //   default:
    //     return questionPre;
    // }
  },
};
