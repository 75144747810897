// import PROFILE from "../data_sources/fake.js";

import { utils } from "./_utils";
import { postRequest, getRequest, signupPostRequest } from "./ApiRequest";
import setAuthToken from "../config/setAuthToken";
import jwt_decode from "jwt-decode";
import { features } from "./_features";

export const _auth = {
  // async signup(u, p, f, c, a, successCallback, errorCallback) {
  //   let request_type = "signup";
  //   // try {
  //   let bodyData = {
  //     username: u,
  //     password: p,
  //     fullname: f,
  //     forceSignUp: true,

  //     company: c,
  //     access_code: a,
  //   };

  //   await post(SIGN_UP, bodyData, successCallback, errorCallback).then(
  //     (response) => {
  //       console.log("RESPTEST DATA", response.data);
  //       successCallback(response.data);
  //     }
  //   );
  // },
  async verifyAuth(userId, name, token) {
    try {
      let data = {
        userId,
        name,
        guestToken: token,
      };
      let response = await postRequest("verify-guest");
      return response;
    } catch (err) {
      return [err];
    }
  },
  async verifyToken(token, userId) {
    try {
      let data = {
        id: userId,
        token: token,
      };
      let response = await postRequest("users/verify-token", data);
      if (response) {
        console.log("/users/verify-token", response.data.data.data);
      }
      return response.data.data.data;
    } catch (err) {
      return [err];
    }
  },

  async signup(
    email,
    password,
    fullName,
    companyName,
    accessCode,
    successCallback,
    errorCallback
  ) {
    try {
      let userData = {
        email: email,
        password: password,
        name: fullName,
        company: companyName,
        role: "Admin",
      };
      this.loader = true;
      console.info(userData);
      await signupPostRequest(
        "new-customer-registration",
        userData,
        successCallback,
        errorCallback
      )
        .then((response) => {
          console.log("RESPTEST DATA", response.data);
          successCallback(response.data);
        })
        .catch((error) => {
          errorCallback(error.response.data);
          // return error.response.data;
        });

      // let response = await postRequest("create-admin-tenant", userData);

      // if (response && response.data.status === 200) {
      //   console.log("Sending Back Response", response);
      //   this.loader = false;
      //   return true;
      // } else {
      //   console.log("There was an error creating the user");
      //   return false;
      // }
    } catch (e) {
      return false;
    }
  },
  async getAuth(history) {
    try {
      let token = localStorage.getItem("jwtToken");
      if (!token) {
        return false;
      } else {
        setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

        let response = await getRequest(`get-auth`);
        console.log(response, ">resppp");
        if (response && response.data) {
          return true;
        } else {
          return false;
        }
      }
    } catch (e) {
      console.log(e, ">resppp");
    }
  },
  async login(userData) {
    try {
      // features.getFeatureFlags();
      features.socketListener();
      this.loader = true;
      // console.info(userData);
      let response = await postRequest("login", userData);

      return response;
    } catch (e) {
      console.log("Returning error", e);
      return [false, e];
    }
  },

  async forgotPassword(obj) {
    try {
      let response = await postRequest("forgot-password", obj);
      return response;
    } catch (e) {
      return {
        error: e,
        success: false,
      };
    }
  },

  async resetPassword(data) {
    try {
      let response = await postRequest("reset-password", data);
      return response;
    } catch (e) {
      return {
        e: e,
        error: true,
      };
    }
  },
  async resetPasswordEmail(data, history) {
    try {
      let response = await postRequest("reset-new-user-password", data);
      console.log(response, "response..");
      if (response) {
        return response;
      }
    } catch (e) {
      return false;
    }
  },

  async logout(history) {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("profile");
    // history.push({
    //   pathname: "/"
    // });
  },
  loader: false,
  setUserProfile(profile) {
    localStorage.removeItem("profile");
    utils.setLocalStorage("profile", profile);
    return true;
  },
  getUserProfile(u) {
    let profile = utils.getLocalStorage("profile");
    return profile;
  },
  getTenantProfile(tenant) {
    return true;
  },
  getAccessCode() {
    return "457";
  },
  getToken() {
    let token = localStorage.getItem("jwtToken");
    if (token == null) {
      return null;
    }
    token = token.replace(/"([^"]+(?="))"/g, "$1");
    //remove quotes from token
    // token.replace(/['"]+/g, null).trimEnd
    return token;
  },

  async MFA(otp, history) {
    try {
      this.loader = true;
      let data = {
        otp,
      };
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("mfa-otp", data);
      if (response) {
        console.log("mfa response _auth", response.data.status);
      }
      if (response && response.data.status === 200) {
        // Set token to ls
        // history.push({
        //   path: "/",
        // });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("mfa error", e);
      return false;
    }
  },

  async signup2(email, password, fullName, companyName, accessCode) {
    try {
      let userData = {
        email: email,
        password: password,
        name: fullName,
        company: companyName,
        role: "CompanyAdmin",
        type: "new",
      };
      this.loader = true;
      // console.info(userData);
      let response = await postRequest("new-customer-registration", userData);

      if (response && response.response && response.response.status !== 200) {
        return response.response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error, "error");
      return error.data;
    }
  },
  async addUser(email, password, fullName, role) {
    try {
      let userData = {
        email: email,
        password: password,
        name: fullName,
        role: role,
      };
      this.loader = true;
      // console.info(userData);
      let response = await postRequest("new-customer-registration", userData);

      if (response && response.response && response.response.status !== 200) {
        return response.response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error, "error");
      return error.data;
    }
  },
  async getAllUsers(tenantId) {
    try {
      let response = await getRequest("get-all-users", {
        tenantId: tenantId,
      });
      return response;
    } catch (e) {
      return false;
    }
  },
  async acceptInvite(id, token, tenantId) {
    console.log("Accepting invite", id, token, tenantId);
    let data = {
      id: id,
      token: token,
      tenantId: tenantId,
    };
    // try {
    let response = await postRequest("accept-invite", data);
    if (response) {
      console.info("Response", response);
    }
    return response;
    // } catch (e) {
    //   return false;
  },

  checkNaughtyList(email) {
    console.info("Checking naughty list for", email);
    let domain = email.split("@")[1];
    console.info("Checking naughty list for", domain);
    let domains = utils.list();
    if (domains.indexOf(domain) != -1) {
      // console.info("Found domain in list", domain);
      return true;
    } else {
      // console.info("Did not find domain in list", domain);
      return false;
    }
  },

  async verifyGuest(userId, token, name, email, inviteId) {
    try {
      let data = {
        userId,
        name,
        guestToken: token,
        email,
        inviteId,
      };
      //debugger; //
      let response = await postRequest("verify-guest", data);
      return response;
    } catch (e) {
      return e;
    }
  },

  async getTentants(path, data) {
    path = "get-tenants";
    console.log("Getting Tenants", data);
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response;

    response = await postRequest(path, data);

    if (response.data.status === 200) {
      console.info("Response Returned - Got Tenants", data);
      return response.data.data.data;
    } else {
      console.info("Bad Response Returned", response);
      return response;
    }
  },
};
