/* This example requires Tailwind CSS v2.0+ */

import { Icon } from "@iconify/react";

export default function BenefitsWithBackground({
  title,
  header,
  description,
  data,
  variant,
  orientation,
  backgroundImage,
  layout,
  gradient,
  featureImage,
  bgColor,
  showBackground,
}) {
  const showBackgroundImage =
    showBackground && showBackground == false ? false : true;
  const backgroundBaseColor = bgColor
    ? bgColor
    : variant == "light"
    ? "white"
    : "slate-800";
  const backgroundColor = "bg-" + backgroundBaseColor;
  const gridCols = layout === "grid" ? "grid-cols-2" : "grid-cols-1";
  const titleTextColor =
    variant == "light" ? "text-indigo-500" : "text-indigo-400";
  const headerTextColor = variant == "light" ? "text-slate-800" : "text-white";
  const descriptionTextColor =
    variant == "light" ? "text-gray-800" : "text-gray-300";
  const featureHeaderTextColor =
    variant == "light" ? "text-slate-800" : "text-white text-opacity-90";
  const featureDescriptionTextColor =
    variant == "light" ? "text-gray-500" : "text-gray-300";

  const flexDirection =
    orientation === "reverse" ? "flex flex-row-reverse" : "flex flex-row";
  return (
    <div
      id="main-div"
      className={` relative ${backgroundColor}
        
      }`}
    >
      {" "}
      <div id="second-div" className="relative w-full grid grid-cols-1">
        <div
          id="imageDiv"
          className={`h-80 w-full ${
            featureImage ? "block" : "hidden"
          }  lg:block absolute bottom-0 xl:inset-0 xl:h-full`}
        >
          <div
            id="direct-image-grand-parent"
            className="h-full w-full xl:grid xl:grid-cols-2"
          >
            {!featureImage && (
              <div
                id="direct-image-parent"
                className={`h-full xl:relative ${
                  orientation === "reverse"
                    ? "xl:col-start-1"
                    : "xl:col-start-2"
                } `}
              >
                <img
                  className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                  src={`${
                    backgroundImage
                      ? backgroundImage.src
                      : "https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                  }`}
                  alt="People working on laptops"
                />
                {orientation == "reverse" ? (
                  <div
                    aria-hidden="true"
                    className={`absolute right-0 top-0 h-32 ${
                      gradient &&
                      " bg-gradient-to-b xl:bg-gradient-to-l from-slate-800 "
                    } xl:inset-y-0 xl:right-0 xl:h-full xl:w-32`}
                  />
                ) : (
                  <div
                    aria-hidden="true"
                    className={`absolute inset-x-0  top-0 h-32 bg-gradient-to-b xl:bg-gradient-to-r from-slate-800 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32`}
                  />
                )}
              </div>
            )}
            {featureImage && (
              <div
                className={`h-full w-full p-12 xl:relative ${
                  orientation === "reverse"
                    ? "xl:col-start-1"
                    : "xl:col-start-2"
                } `}
              >
                <img
                  src={featureImage}
                  className=" rounded-3xl shadow-xl object-contain object-scale-down"
                />
              </div>
            )}
          </div>
        </div>
        <div
          id="data-div"
          className={`max-w-4xl mx-auto ${
            orientation === "reverse"
              ? "pl-8 pr-4 sm:pl-8 sm:pr-6"
              : "px-4 sm:px-6"
          }  lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8`}
        >
          <div
            className={`relative pt-12 pb-64 sm:pt-24 sm:pb-64 ${
              orientation === "reverse"
                ? "xl:col-start-2 pl-8"
                : "xl:col-start-1"
            } xl:pb-24`}
          >
            {" "}
            {/* {orientation == "reverse" ? (
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b xl:bg-gradient-to-l to-slate-800 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 "
              />
            ) : null} */}
            <h2
              className={`text-sm ${titleTextColor} font-semibold tracking-wide uppercase`}
            >
              {title}
            </h2>
            <p
              className={`mt-3 text-3xl ${
                !featureImage ? " font-extrabold " : " font-normal "
              }${headerTextColor}`}
            >
              {header}
            </p>
            <p className={`mt-5 text-lg ${descriptionTextColor}`}>
              {description}
            </p>
            <div
              id="ptrBanner"
              className={`mt-12 grid grid-cols-1 gap-y-12 gap-x-6 ${
                "xl:" + gridCols
              }`}
            >
              {data.map((feature) => (
                <p key={feature.name}>
                  <Icon
                    icon={feature.icon}
                    className={`h-8 w-8 flex-none ${featureHeaderTextColor}`}
                    aria-hidden="true"
                  />
                  <span
                    className={`block text-2xl font-bold ${featureHeaderTextColor}`}
                  >
                    {feature.name}
                  </span>
                  <span
                    className={`mt-1 block text-base ${featureDescriptionTextColor}`}
                  >
                    {feature.description}
                  </span>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
