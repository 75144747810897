import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const _teams = {
  async getTeams() {
    console.log("get Teams");
    let profile = _auth.getUserProfile();

    let data;
    data = {
      companyId: profile.companyId,
    };
    console.log("get Teams", data);
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("teams/get-teams", data);

    if (response) {
      console.log("/teams/get-teams", response.data.data);
      return response.data.data;
    } else {
      return [];
    }
  },
};
