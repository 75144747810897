/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/24/outline";
import { getThemeStyles } from "../modules/_themeStyles";

export default function FeatureList({
  title,
  header,
  description,
  features,
  bgColor,
  variant,
}) {
  const {
    backgroundColor,
    gridCols,
    titleTextColor,
    headerTextColor,
    descriptionTextColor,
    featureHeaderTextColor,
    featureDescriptionTextColor,
  } = getThemeStyles(variant, bgColor);
  return (
    <div className={`${backgroundColor}`}>
      <div className="max-w-7xl mx-auto pb-0 px-4 sm:px-6  lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div className="col-span-full text-center">
          <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">
            {title}
          </h2>
          <p className={`mt-2 text-3xl font-extrabold text-slate-800`}>
            {header}
          </p>
          <p className="mt-4 text-lg text-gray-500">
            {description && description}
          </p>
        </div>
        <div className="mt-12 lg:mt-12 lg:col-span-3">
          <dl className="pb-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="relative flex flex-col items-center justify-center bg-white border border-gray-200 shadow-xl rounded-xl p-6 sm:p-8 lg:p-8 "
              >
                <dt>
                  {/* <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  /> */}
                  <p className="ml-9 text-lg leading-6 font-medium text-slate-800">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
