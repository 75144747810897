import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../images/logo.png";
import SidebarLinkGroup from "./SidebarLinkGroup";
import Transition from "../utils/Transition";
import { _navigation } from "../modules/_navigation";
import { audit } from "../modules/_audit";
import { _auth } from "../modules/_auth";
import { FeatureFlag } from "../components/FeatureFlag";
import { Icon } from "@iconify/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import HeaderLinks from "../parts/HeaderLinks";
import LightLogo from "../images/BreachConnectLogoSidebar.png";
import Button from "../elements/Button";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Sidebar({
  sidebarOpen,
  setSidebarOpen,
  setSidebarClosed,
  navigation,
  isScrolled,
  scrollTo,
  handleNavClick,
  handleMobileNavClick,
  props,
}) {
  const location = useLocation();
  const { pathname } = location;

  const profile = _auth.getUserProfile();

  // const navigation =
  //   profile !== null
  //     ? _navigation.getNavLinksByRole(profile.role, profile.showHome)
  //     : [];

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = sessionStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null || storedSidebarExpanded === false
      ? false
      : storedSidebarExpanded === "true"
  );

  // // close on click outside
  // useEffect(() => {
  //   const clickHandler = ({ target }) => {
  //     console.log("clickHandler", target);
  //     if (!sidebar.current || !trigger.current) return;
  //     if (
  //       !sidebarOpen ||
  //       sidebar.current.contains(target) ||
  //       trigger.current.contains(target)
  //     )
  //       return;
  //     setSidebarOpen(false);
  //   };
  //   document.addEventListener("click", () => clickHandler());
  //   return () => document.removeEventListener("click", clickHandler);
  // });

  // // close if the esc key is pressed
  // useEffect(() => {
  //   const keyHandler = ({ keyCode }) => {
  //     if (!sidebarOpen || keyCode !== 27) return;
  //     setSidebarOpen(false);
  //   };
  //   document.addEventListener("keydown", keyHandler);
  //   return () => document.removeEventListener("keydown", keyHandler);
  // });

  useEffect(() => {
    sessionStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  const typeIcon = (type) => {
    switch (type) {
      case "home":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
        );
      case "dashboard":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
            />
          </svg>
        );
      case "employees":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        );
      case "regulation":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
            />
          </svg>
        );
      case "assessment":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
            />
          </svg>
        );
      case "learning":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
            />
          </svg>
        );
      case "marketplace":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
            />
          </svg>
        );
      case "support":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        );
    }
  };

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        onClick={(e) => setSidebarClosed(e)}
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40  transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      {/* <div className="flex flex-col h-full justify-between"> */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col  fixed z-40 right-0 top-0  xl:hidden  transform h-screen overflow-y-scroll  no-scrollbar 
        w-full  shrink-0 bg-gray-800 bg-opacity-[95%] p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start pr-3 sm:px-2">
            <img src={LightLogo} alt="Logo" className="inline-flex h-8 mr-3" />
            <h2 className="text-3xl font-semibold text-white ">Breachlink</h2>

            {/* Logo */}
            {/* <NavLink exact to="/" className="block"> */}

            {/* </NavLink> */}
          </div>
          <div className="flex items-center justify-end">
            {/* Close button */}
            <button
              ref={trigger}
              className=" text-white hover:text-white"
              onClick={(e) => setSidebarClosed(e)}
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
            >
              <span className="sr-only">Close sidebar</span>
              <XMarkIcon className=" w-6 h-6" />
              {/* <svg
              className="w-6 h-6 fill-white"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg> */}
            </button>
          </div>
        </div>

        {/* Links */}
        <div className="space-y-10">
          {/* Pages group */}
          <div>
            <ul className="mt-3 text-white text-opacity-90">
              {navigation &&
                navigation.length > 0 &&
                navigation.map((item) => {
                  console.log("item to click", item);
                  return (
                    <a
                      key={item.name}
                      href={
                        `#${item.scrollTo}`
                        //will need to change this to handle times when
                        //we need to naviagte to a different page
                      }
                      onClick={(e) => {
                        handleMobileNavClick(item.scrollTo, item.href);
                      }}
                      className={classNames(
                        pathname.includes(item.href) &&
                          item.scrollTo === scrollTo
                          ? "bg-white text-slate-900"
                          : "text-white hover:bg-white hover:bg-opacity-60 hover:text-slate-900",
                        "rounded-md py-3 px-3 inline-flex items-center text-sm font-medium w-full mb-1 relative" // Added 'relative' here
                      )}
                    >
                      <div className="flex items-center w-full h-full">
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true" />

                        <span className="text-sm font-medium ml-3 duration-200">
                          {item.name}
                        </span>
                      </div>
                    </a>
                  );
                })}
            </ul>
          </div>
          {/* More group */}
          {/* <div>
            <ul className="mt-3 text-white">
              <nav
                className=" lg:py-2 lg:flex lg:space-x-8 text-white"
                aria-label="Global"
              >
                {navigation &&
                  navigation.length > 0 &&
                  navigation.map(
                    (item) =>
                      // (item.show ||
                      //   (item.show === false &&
                      //     item.status === "beta" &&
                      //     process.env !== "production")) && (
                      item.status == "coming soon" && (
                        // <FeatureFlag featureName={item.name} type="flag">
                        <NavLink
                          exact
                          onClick={() => {
                            audit.logAction("View " + item.name);
                          }}
                          to={
                            item.status
                              ? item.status == "coming soon"
                                ? "#"
                                : item.href
                              : item.href
                          }
                          aria-current={item.current ? "page" : undefined}
                          // className={`block text-slate-900 hover:text-primary-500 truncate transition duration-150 ${pathname === item.href && 'text-primary-500 hover:text-primary-500 border-primary-500'}`}
                          className={classNames(
                            pathname.includes(item.href)
                              ? "bg-brand-200 text-white"
                              : item.status === "coming soon" //disable coming soon
                              ? "text-white opacity-20 cursor-not-allowed"
                              : "text-white hover:bg-white hover:text-slate-900",
                            // item.status === "coming soon"
                            //   ? "text-slate-400 hover:text-slate-400 hover:bg-white cursor-not-allowed"
                            //   : null,
                            "rounded-md py-2 px-3 inline-flex items-center text-sm font-medium w-full mb-1"
                          )}
                        >
                          <div className="flex items-center">
                            {item.icon && <span>{typeIcon(item.icon)}</span>}

                            <span className=" text-sm font-medium ml-3  duration-200">
                              {item.name}
                              <span className="hidden  xl:inline-flex 2xl:inline-flex">
                                {item.status == "coming soon" ? (
                                  <span className="ml-2  rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-slate-400">
                                    Coming Soon
                                  </span>
                                ) : item.status == "beta" ? (
                                  <span className="ml-2 rounded-full bg-primary-100 px-2 text-xs font-semibold leading-5 text-primary-500">
                                    Preview
                                  </span>
                                ) : item.status == "early access" ? (
                                  <span className="ml-2   rounded-full bg-primary-100 px-2 text-xs font-semibold leading-5 text-primary-500">
                                    Early Access
                                  </span>
                                ) : null}
                              </span>
                            </span>
                          </div>
                        </NavLink>
                        // </FeatureFlag>
                      )
                    // )
                  )}
              </nav>
            </ul>
          </div> */}
        </div>
        {/* Expand / collapse button */}
        {/* <div className="fixed bottom-4 w-full px-4">
          <button
            fullWidth
            color="green"
            className="btn bg-green-500 hover:bg-green-600 text-white w-full"
            onClick={() => setSidebarExpanded(!sidebarExpanded)}
          >
            {sidebarExpanded ? "Collapse" : "Expand"}
          </button>
        </div> */}
      </div>
    </div>
    // </div>
  );
}

export default Sidebar;
