import { React, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import DynamicImage from "./DynamicImage";

import { _auth } from "../modules/_auth";
import Button from "../elements/Button";
import ContactConfirm from "./ContactConfirm";
import ModalBasic from "./ModalBasic";
import { FeatureFlag } from "./FeatureFlag";

export default function ActionPlanExplanation(props) {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const profile = _auth.getUserProfile();
  console.info("ASMT-EXPLANATION", props);
  const index = props.index;
  const title = props.action_title;
  const action_plan = props.action_plan;
  const answered_by = props.answered_by;
  const marketplace = props.marketplace
    ? props.marketplace
    : ["View Marketplace"];
  const baseURL = "../images/pci_action_icons/";
  const handleHelpModalClick = () => {
    setShowModal(true);
  };
  const handleHelpModalClose = () => {
    setShowModal(false);
  };
  const viewMarketplace = (e, item) => {
    e.preventDefault();
    if (item == "View Marketplace") {
      item = "View All";
    }

    // console.info("VIEW MARKETPLACE", item);
    history.push({
      pathname: "/marketplace",
      state: { filter: item },
    });
  };

  return (
    <li key={index} className="py-4">
      <div className="flex space-x-3">
        <div className="flex-1 space-y-1">
          <div className="mt-8 mx-auto grid grid-cols-4 gap-6 sm:px-6">
            {props.action_icon && (
              <div>
                <DynamicImage imageName={props.action_icon} type={props.type} />
              </div>
            )}
            <div className=" col-span-3">
              <div className="">
                <h2 className=" flex leading-6 text-slate-600 font-semibold tracking-wide uppercase mb-5">
                  {title}{" "}
                  {answered_by &&
                    answered_by != _auth.getUserProfile().name && (
                      <div className="ml-2 text-primary-500 text-sm font-thin">
                        (Answered by {answered_by})
                      </div>
                    )}
                </h2>
                {/* <h3 className="text-sm font-medium mb-5"></h3> */}
              </div>
              <div className="mb-5">
                <p className="text-sm  text-slate-500">{action_plan}</p>
              </div>

              <div>
                <div className="m-1.5 text-sm text-slate-600 font-medium">
                  {props.marketplace
                    ? "Need help? Click below and we'll help you get connected with vendors who can help."
                    : "Need help? Check out our marketplace for vendors that can help."}
                </div>
                <div className="m-1.5 space-x-2 space-y-2">
                  {marketplace.map((item, index) => {
                    {
                      return (
                        <FeatureFlag type="flag" featureName="Marketplace">
                          <Button
                            size="sm"
                            color="purple"
                            handleClick={(e) => viewMarketplace(e, item)}
                          >
                            {item}
                          </Button>
                        </FeatureFlag>
                      );
                    }
                  })}
                  <Button
                    // size="sm"
                    color="lime"
                    handleClick={handleHelpModalClick}
                  >
                    Get help with this Action Plan
                  </Button>
                  {/* <Link to="/contact">
                    <button className="btn-sm bg-lime-500 hover:bg-lime-600 text-white ml-auto">
                      Get Help
                    </button>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalBasic
        id="support_modal"
        modalOpen={showModal}
        setModalOpen={setShowModal}
        title={"Contact Breachlink"}
        showClose={false}
      >
        <ContactConfirm
          setModalOpen={setShowModal}
          company={"Breachlink"}
          email={profile !== null && _auth.getUserProfile().email}
          vendor_email={"vendor_email"}
          type="support"
        />
      </ModalBasic>
    </li>
  );
}
