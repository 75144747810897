import { _auth } from "./_auth";

export const _pricing = {
  getPricing() {
    const pricing = {
      tiers: [
        {
          title: "Start",
          price: 0,
          frequency: "/month",
          description: "For the attorney who just needs the basics.",
          features: [
            "Single user",
            "Unlimited projects",
            "Send and track bid requests",
            "48-hour support response time",
          ],
          cta: "Get started",
          mostPopular: false,
        },
        {
          title: "Grow",
          price: 3,
          frequency: "/month",
          description: "A plan that grows as you grow your business.",
          features: [
            "Up to 10 users",
            "Unlimited projects",
            "Send and track bid requests",
            "24-hour support response time",
            "Collaborate with your team",
            "Send and receive follow-up questions",
            "Some other great feature",
          ],
          cta: "Get started",
          mostPopular: true,
        },
        {
          title: "Scale",
          price: 5,
          frequency: "/month",
          description: "Everything you need and then some.",
          features: [
            "Unlimited users",
            "Unlimited projects",
            "Send and track bid requests",
            "24-hour support response time",
            "Collaborate with your team",
            "Send and receive follow-up questions",
            "Some other great feature",
            "And another great feature",

            "And maybe some integrations",
          ],
          cta: "Get started",
          mostPopular: false,
        },
      ],
    };
    return pricing;
  },
};
