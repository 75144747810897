import pci from "../images/pci_action_icons/index.js";
import breach from "../images/breach_action_icons/index.js";
import nav from "../images/nav_images/index.js";
import meter from "../images/score_meters/index.js";
import csf from "../images/csf_action_icons/index.js";

export default function DynamicImage(props) {
  console.info("DynamicImage props", props);

  const imageName = props.imageName;
  console.info("DynamicImageL", imageName);
  const imageType = props.type;

  const type = (imageType) => {
    switch (imageType) {
      case "pci":
        return pci;
      case "csf":
        return csf;
      case "nist":
        return csf;
      case "breach":
        return breach;
      case "nav":
        return nav;
      case "meter":
        return meter;
      default:
        return null;
    }
  };

  const img = (
    <img
      src={
        type(imageType)[imageName] && type(imageType)[imageName].default
        // : "/static/media/27-get-personal.8284ed6e.jpg"
      }
      // src="/static/media/27-get-personal.8284ed6e.jpg"
      alt=""
    />
  );

  console.info("DynamicImageR", type(imageType)[imageName]);
  return img;
}
