import { _assessment } from "../modules/_assessment";
import { assessments } from "../modules/assessments";
import utils from "./_utils";
import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";

import { _auth } from "./_auth";

export const _dashboard = {
  async getBurndownData(admin) {
    let profile = _auth.getUserProfile();

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let body = {
        tenantId: profile.tenantId,
        company_id: profile.companyId,
        admin: admin,
      };

      console.log("Get Burndown data ", body);

      let response = await postRequest(
        "/dashboard/get-impediment-burden-history",
        body
      );
      //   console.log("/impediments/get", response);
      if (response) {
        console.log(
          "/dashboard/get-impediment-burden-history",
          response.data.data
        );
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },

  convertDates(data) {
    let tempArr = [];
    Object.entries(assessments.getDateTotals(data)).forEach((date) => {
      console.info("GOT A DATE -->", date);

      var d = new Date(date[0]),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      let newDate = [month, day, year].join("-");
      console.info("OTHER NEW DATE -->", newDate);
      let total = date[1];
      console.info("Got getDate New Date", newDate, total);
      if (newDate != "Invalid Date" && date[0] != undefined) {
        tempArr.push([newDate, total]);
      }
    });
    return tempArr;
  },
  todaysHistory(data) {
    let todaysHistory = data.filter((item) => {
      return (
        item.assessment_date ===
        new Date().toLocaleDateString("en-us", {
          // weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
    });
    return todaysHistory;
  },
  calculateAverageRisk(data) {
    let countOfEach = this.getCountOfEach(data);
    console.info("COUNT OF EACH RISK", countOfEach);
    let avgRisk =
      Object.keys(countOfEach).length > 0
        ? Object.keys(countOfEach).reduce((a, b) =>
            countOfEach[a] > countOfEach[b] ? a : b
          )
        : null;
    console.info("AVG RISK", avgRisk);
    return avgRisk;
  },
  getCountOfEach(data) {
    const riskArray = [];
    data.length > 0 &&
      data.map((item) => {
        item.risk_score != null &&
          item.risk_score != "null" &&
          riskArray.push(item.risk_score);
      });
    console.info("RISK ARRAY", riskArray);

    const countOfEach = {};
    for (const num of riskArray) {
      countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
    }
    return countOfEach;
  },
  getUnfinishedToday(data) {
    const unfinishedToday =
      data.length > 0 &&
      data.filter((item) => {
        return item.risk_score === "null";
      });
    return unfinishedToday;
  },
  getAllUnfinishedAssessments(data) {
    let unfinished =
      data.length > 0 &&
      data.filter((item) => {
        return item.risk_score === "null";
      });
    return unfinished;
  },
};
