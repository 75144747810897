import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const Solicitations = {
  profile() {
    return _auth.getUserProfile();
  },
  async getById(data) {
    console.log("Call API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", data);
    let response = await postRequest("solicitations/get-by-id", data);
    if (response) {
      console.log("Get solicitation got response");
      return response.data.data;
    } else {
      return response;
    }
  },

  async create(data) {
    console.log("Call API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", data);
    let response = await postRequest("solicitations/create", data);
    if (response) {
      console.log("Create solicitation got response");
      return response;
    } else {
      return response;
    }
  },
  async list() {
    let data = {};
    console.log("Call API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body - list projects", data);
    let response = await postRequest("projects/get-projects", data);
    if (response) {
      return response.data.data;
    } else {
      return response;
    }
  },
};
