import React, { useState } from "react";
import Transition from "../utils/Transition.js";

function Tooltip({
  children,
  className,
  bg,
  size,
  position,
  title,
  description,
  open,
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const positionOuterClasses = (position) => {
    switch (position) {
      case "right":
        return "left-full top-1/2 transform -translate-y-1/2";
      case "left":
        return "right-full top-1/2 transform -translate-y-1/2";
      case "bottom":
        return "top-full left-1/2 transform -translate-x-1/2";
      default:
        return "bottom-full left-1/2 transform -translate-x-1/2";
    }
  };

  const sizeClasses = (size) => {
    switch (size) {
      case "xl":
        return "w-96  p-3";
      case "lg":
        return "min-w-72  p-3";
      case "md":
        return "min-w-56 p-3";
      case "sm":
        return "min-w-44 p-2";
      default:
        return "p-2";
    }
  };

  const positionInnerClasses = (position) => {
    switch (position) {
      case "right":
        return "ml-2";
      case "left":
        return "mr-2";
      case "bottom":
        return "mt-2";
      default:
        return "mb-2";
    }
  };

  return (
    <div
      className={`relative ${className}`}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      onFocus={() => setTooltipOpen(true)}
      onBlur={() => setTooltipOpen(false)}
    >
      {children}
      {/* {title} */}
      <div className={`z-20 absolute ${positionOuterClasses(position)}`}>
        <Transition
          show={tooltipOpen}
          tag="div"
          className={`rounded overflow-hidden ${"bg-slate-800"} ${sizeClasses(
            size
          )} ${positionInnerClasses(position)}`}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div id="tooltip-hover" role="tooltip" className="text-xs">
            <div className="font-medium text-slate-200 mb-0.5">{title}</div>
            <div className="text-slate-400 ">
              {description}
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          </div>
          {/* {children} */}
        </Transition>
      </div>
    </div>
    // <div
    //   id="tooltip-animation"
    //   role="tooltip"
    //   class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-slate-800 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
    // >
    //   {children}
    //   <div class="tooltip-arrow" data-popper-arrow></div>
    // </div>
  );
}

export default Tooltip;
