/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArchiveBoxIcon,
  BackwardIcon,
  Bars3BottomLeftIcon,
  Bars4Icon,
  ClockIcon,
  HomeIcon,
  UserCircleIcon as UserCircleIconOutline,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowUturnLeftIcon,
  BellIcon,
  CalendarIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  LockOpenIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  TagIcon,
  UserCircleIcon as UserCircleIconMini,
} from "@heroicons/react/20/solid";
import { data } from "autoprefixer";
import Button from "../elements/Button";

const navigation = [
  { name: "All Issues", href: "#", icon: HomeIcon, current: true },
  { name: "My Issues", href: "#", icon: Bars4Icon, current: false },
  { name: "Assigned", href: "#", icon: UserCircleIconOutline, current: false },
  { name: "Closed", href: "#", icon: ArchiveBoxIcon, current: false },
  { name: "Recent", href: "#", icon: ClockIcon, current: false },
];
const projects = [
  { id: 1, name: "GraphQL API", href: "#" },
  { id: 2, name: "iOS App", href: "#" },
  { id: 3, name: "Marketing Site Redesign", href: "#" },
  { id: 4, name: "Customer Portal", href: "#" },
];
const activity = [
  {
    id: 1,
    type: "comment",
    person: { name: "Eduardo Benz", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. ",
    date: "6d ago",
  },
  {
    id: 2,
    type: "assignment",
    person: { name: "Hilary Mahy", href: "#" },
    assigned: { name: "Kristin Watson", href: "#" },
    date: "2d ago",
  },
  {
    id: 3,
    type: "tags",
    person: { name: "Hilary Mahy", href: "#" },
    tags: [
      { name: "Bug", href: "#", color: "bg-rose-500" },
      { name: "Accessibility", href: "#", color: "bg-indigo-500" },
    ],
    date: "6h ago",
  },
  {
    id: 4,
    type: "comment",
    person: { name: "Jason Meyers", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.",
    date: "2h ago",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ImpedimentDetailsBody(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const handleBack = () => {
    props.handleBack();
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-screen bg-white">
        {/* <div className="flex w-0 flex-1 flex-col lg:pl-64"> */}
        <main className="flex-1">
          <div className="px-8 pt-4">
            <Button
              color="link"
              shadow={false}
              handleClick={handleBack}
              // className="btn bg-primary-500 hover:bg-primary-600 text-white"
            >
              <ChevronLeftIcon className="h-5 w-5" />
              <span className="hidden xs:block ml-2">Back to Impediments</span>
            </Button>
          </div>
          <div className="py-4 xl:py-6">
            <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 xl:grid xl:max-w-full xl:grid-cols-3">
              {/* <aside className=" xl:block xl:pl-8">
                <h2 className="sr-only">Details</h2>
                <div className="space-y-5">
                  <div className="flex items-center space-x-2">
                    <LockOpenIcon
                      className="h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-green-700">
                      {data.Resolved ? "Resolved" : "Open"}
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <LockOpenIcon
                      className="h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-green-700">
                      Burden: {data.score}
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <LockOpenIcon
                      className="h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-green-700">
                      Effort: {data.effort}
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <ChatBubbleLeftEllipsisIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-slate-800">
                      {data.Comments ? data.Comments.length : 0} comments
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <CalendarIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-slate-800">
                      Resolved{" "}
                      <time
                        dateTime={data.resolveDate ? data.resolveDate : null}
                      >
                        {data.resolveDate ? data.resolveDate : null}
                      </time>
                    </span>
                  </div>
                </div>
                <div className="mt-6 space-y-8 border-t border-gray-200 py-6">
                  <div>
                    <h2 className="text-sm font-medium text-gray-500">Team</h2>
                    <ul role="list" className="mt-3 space-y-3">
                      <li className="flex justify-start">
                        <a href="#" className="flex items-center space-x-3">
                          <div className="flex-shrink-0">
                            <img
                              className="h-5 w-5 rounded-full"
                              src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                              alt=""
                            />
                          </div>
                          <div className="text-sm font-medium text-slate-800">
                            {data.Claimed_by_team
                              ? data.Claimed_by_team.tenantTitle
                              : "Not Claimed"}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-sm font-medium text-gray-500">Tags</h2>
                    <ul role="list" className="mt-2 leading-8">
                      <li className="inline">
                        <a
                          href="#"
                          className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                        >
                          <div className="absolute flex flex-shrink-0 items-center justify-center">
                            <span
                              className="h-1.5 w-1.5 rounded-full bg-rose-500"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3.5 text-sm font-medium text-slate-800">
                            {data.Competency && data.Competency.Name}
                          </div>
                        </a>{" "}
                      </li>
                      <li className="inline">
                        <a
                          href="#"
                          className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                        >
                          <div className="absolute flex flex-shrink-0 items-center justify-center">
                            <span
                              className="h-1.5 w-1.5 rounded-full bg-indigo-500"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3.5 text-sm font-medium text-slate-800">
                            Accessibility
                          </div>
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </aside> */}
              <div className="xl:col-span-2 xl:border-r xl:border-gray-200 xl:pr-8">
                <div>
                  <div>
                    <div className="w-full md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                      <div className="w-full">
                        <h1 className="text-2xl w-full font-bold text-slate-800">
                          <p className="text-ellipsis text-wrap truncate">
                            {data.Description}
                          </p>
                          {/* {data.Description} */}
                        </h1>
                        <ul role="list" className="mt-2 leading-8">
                          <li className="inline">
                            <a
                              href="#"
                              className="relative inline-flex items-center rounded-full  px-1 py-0.5"
                            >
                              <div className="absolute flex flex-shrink-0 items-center justify-center">
                                <span
                                  className="h-1.5 w-1.5 rounded-full bg-rose-500"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3.5 text-sm font-medium text-slate-800">
                                {data.Category ? data.Category : "No Category"}
                              </div>
                            </a>{" "}
                          </li>
                          {data.Subcategory && (
                            <li className="inline">
                              <a
                                href="#"
                                className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                              >
                                <div className="absolute flex flex-shrink-0 items-center justify-center">
                                  <span
                                    className="h-1.5 w-1.5 rounded-full bg-indigo-500"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-3.5 text-sm font-medium text-slate-800">
                                  {data.Subcategory && data.Subcategory}
                                </div>
                              </a>{" "}
                            </li>
                          )}
                        </ul>
                      </div>
                      {/* <div className="mt-4 flex space-x-3 md:mt-0">
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:ring-offset-2"
                          >
                            <PencilIcon
                              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span>Edit</span>
                          </button>
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:ring-offset-2"
                          >
                            <BellIcon
                              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span>Subscribe</span>
                          </button>
                        </div> */}
                    </div>
                    <aside className="mt-8 xl:hidden">
                      <h2 className="sr-only">Details</h2>
                      <div className="space-y-5">
                        <div className="flex items-center space-x-2">
                          <LockOpenIcon
                            className="h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          <span className="text-sm font-medium text-green-700">
                            Open Issue
                          </span>
                        </div>
                        <div className="flex items-center space-x-2">
                          <ChatBubbleLeftEllipsisIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="text-sm font-medium text-slate-800">
                            4 comments
                          </span>
                        </div>
                        <div className="flex items-center space-x-2">
                          <CalendarIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="text-sm font-medium text-slate-800">
                            Created on{" "}
                            <time dateTime="2020-12-02">Dec 2, 2020</time>
                          </span>
                        </div>
                      </div>
                      <div className="mt-6 space-y-8 border-t border-b border-gray-200 py-6">
                        <div>
                          <h2 className="text-sm font-medium text-gray-500">
                            Assignees
                          </h2>
                          <ul role="list" className="mt-3 space-y-3">
                            <li className="flex justify-start">
                              <a
                                href="#"
                                className="flex items-center space-x-3"
                              >
                                <div className="flex-shrink-0">
                                  <img
                                    className="h-5 w-5 rounded-full"
                                    src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                                    alt=""
                                  />
                                </div>
                                <div className="text-sm font-medium text-slate-800">
                                  Eduardo Benz
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div>
                          <h2 className="text-sm font-medium text-gray-500">
                            Tags
                          </h2>
                          <ul role="list" className="mt-2 leading-8">
                            <li className="inline">
                              <a
                                href="#"
                                className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                              >
                                <div className="absolute flex flex-shrink-0 items-center justify-center">
                                  <span
                                    className="h-1.5 w-1.5 rounded-full bg-rose-500"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-3.5 text-sm font-medium text-slate-800">
                                  Bug
                                </div>
                              </a>{" "}
                            </li>
                            <li className="inline">
                              <a
                                href="#"
                                className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                              >
                                <div className="absolute flex flex-shrink-0 items-center justify-center">
                                  <span
                                    className="h-1.5 w-1.5 rounded-full bg-indigo-500"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-3.5 text-sm font-medium text-slate-800">
                                  Accessibility
                                </div>
                              </a>{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </aside>
                    <div className="py-3 xl:pt-6 xl:pb-0">
                      <h2 className="font-medium">Description</h2>
                      <div className="prose max-w-none">
                        <p>{data.Description}</p>
                        {/* <ul role="list">
                            <li>
                              Tempor ultrices proin nunc fames nunc ut auctor
                              vitae sed. Eget massa parturient vulputate
                              fermentum id facilisis nam pharetra. Aliquet leo
                              tellus.
                            </li>
                            <li>
                              Turpis ac nunc adipiscing adipiscing metus
                              tincidunt senectus tellus.
                            </li>
                            <li>
                              Semper interdum porta sit tincidunt. Dui
                              suspendisse scelerisque amet metus eget sed. Ut
                              tellus in sed dignissim.
                            </li>
                          </ul> */}
                      </div>
                    </div>
                    <div className="py-3 xl:pt-6 xl:pb-0">
                      <h2 className="font-medium">Root Cause</h2>
                      <div className="prose max-w-none">
                        <p>
                          {data.Root_cause ? data.Root_cause : "No root cause"}
                        </p>
                      </div>
                    </div>
                    <div className="py-3 xl:pt-6 xl:pb-0">
                      <h2 className="font-medium">Proposed Solution</h2>
                      <div className="prose max-w-none">
                        <p>
                          {data.Proposed_solution
                            ? data.Proposed_solution
                            : "No proposed solution"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <section
                  aria-labelledby="activity-title"
                  className="mt-8 xl:mt-10"
                >
                  <div>
                    <div className="divide-y divide-gray-200">
                      <div className="pb-4">
                        <h2
                          id="activity-title"
                          className="text-lg font-medium text-slate-800"
                        >
                          Activity
                        </h2>
                      </div>
                      <div className="pt-6">
                        <div className="flow-root">
                          <ul role="list" className="-mb-8">
                            {activity.map((item, itemIdx) => (
                              <li key={item.id}>
                                <div className="relative pb-8">
                                  {itemIdx !== activity.length - 1 ? (
                                    <span
                                      className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                      aria-hidden="true"
                                    />
                                  ) : null}
                                  <div className="relative flex items-start space-x-3">
                                    {item.type === "comment" ? (
                                      <>
                                        <div className="relative">
                                          <img
                                            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                            src={item.imageUrl}
                                            alt=""
                                          />

                                          <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                            <ChatBubbleLeftEllipsisIcon
                                              className="h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </div>
                                        <div className="min-w-0 flex-1">
                                          <div>
                                            <div className="text-sm">
                                              <a
                                                href={item.person.href}
                                                className="font-medium text-slate-800"
                                              >
                                                {item.person.name}
                                              </a>
                                            </div>
                                            <p className="mt-0.5 text-sm text-gray-500">
                                              Commented {item.date}
                                            </p>
                                          </div>
                                          <div className="mt-2 text-sm text-gray-700">
                                            <p>{item.comment}</p>
                                          </div>
                                        </div>
                                      </>
                                    ) : item.type === "assignment" ? (
                                      <>
                                        <div>
                                          <div className="relative px-1">
                                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                              <UserCircleIconMini
                                                className="h-5 w-5 text-gray-500"
                                                aria-hidden="true"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="min-w-0 flex-1 py-1.5">
                                          <div className="text-sm text-gray-500">
                                            <a
                                              href={item.person.href}
                                              className="font-medium text-slate-800"
                                            >
                                              {item.person.name}
                                            </a>{" "}
                                            assigned{" "}
                                            <a
                                              href={item.assigned.href}
                                              className="font-medium text-slate-800"
                                            >
                                              {item.assigned.name}
                                            </a>{" "}
                                            <span className="whitespace-nowrap">
                                              {item.date}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div>
                                          <div className="relative px-1">
                                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                              <TagIcon
                                                className="h-5 w-5 text-gray-500"
                                                aria-hidden="true"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="min-w-0 flex-1 py-0">
                                          <div className="text-sm leading-8 text-gray-500">
                                            <span className="mr-0.5">
                                              <a
                                                href={item.person.href}
                                                className="font-medium text-slate-800"
                                              >
                                                {item.person.name}
                                              </a>{" "}
                                              added tags
                                            </span>{" "}
                                            <span className="mr-0.5">
                                              {item.tags.map((tag) => (
                                                <Fragment key={tag.name}>
                                                  <a
                                                    href={tag.href}
                                                    className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                                                  >
                                                    <span className="absolute flex flex-shrink-0 items-center justify-center">
                                                      <span
                                                        className={classNames(
                                                          tag.color,
                                                          "h-1.5 w-1.5 rounded-full"
                                                        )}
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                    <span className="ml-3.5 font-medium text-slate-800">
                                                      {tag.name}
                                                    </span>
                                                  </a>{" "}
                                                </Fragment>
                                              ))}
                                            </span>
                                            <span className="whitespace-nowrap">
                                              {item.date}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="mt-6">
                          <div className="flex space-x-3">
                            <div className="flex-shrink-0">
                              <div className="relative">
                                <img
                                  className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                  src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                                  alt=""
                                />

                                <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                  <ChatBubbleLeftEllipsisIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="min-w-0 flex-1">
                              <form action="#">
                                <div>
                                  <label htmlFor="comment" className="sr-only">
                                    Comment
                                  </label>
                                  <textarea
                                    id="comment"
                                    name="comment"
                                    rows={3}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-800 focus:ring-slate-800 sm:text-sm"
                                    placeholder="Leave a comment"
                                    defaultValue={""}
                                  />
                                </div>
                                <div className="mt-6 flex items-center justify-end space-x-4">
                                  <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:ring-offset-2"
                                  >
                                    <CheckCircleIcon
                                      className="-ml-1 mr-2 h-5 w-5 text-green-500"
                                      aria-hidden="true"
                                    />
                                    <span>Close issue</span>
                                  </button>
                                  <button
                                    type="submit"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-slate-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-slate-800 focus:ring-offset-2"
                                  >
                                    Comment
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section> */}
              </div>
              <aside className="hidden xl:block xl:pl-8">
                <h2 className="sr-only">Details</h2>
                <div className="space-y-5">
                  <div className="flex items-center space-x-2">
                    <LockOpenIcon
                      className="h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-green-700">
                      {data.Resolved ? "Resolved" : "Open"}
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <LockOpenIcon
                      className="h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-green-700">
                      Burden: {data.score}
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <LockOpenIcon
                      className="h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-green-700">
                      Effort: {data.effort}
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <ChatBubbleLeftEllipsisIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-slate-800">
                      {data.Comments ? data.Comments.length : 0} comments
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <CalendarIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-slate-800">
                      Resolved{" "}
                      <time
                        dateTime={data.resolveDate ? data.resolveDate : null}
                      >
                        {data.resolveDate ? data.resolveDate : null}
                      </time>
                    </span>
                  </div>
                </div>
                <div className="mt-6 space-y-8 border-t border-gray-200 py-6">
                  <div>
                    <h2 className="text-sm font-medium text-gray-500">Team</h2>
                    <ul role="list" className="mt-3 space-y-3">
                      <li className="flex justify-start">
                        <a href="#" className="flex items-center space-x-3">
                          <div className="flex-shrink-0">
                            <img
                              className="h-5 w-5 rounded-full"
                              src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                              alt=""
                            />
                          </div>
                          <div className="text-sm font-medium text-slate-800">
                            {data.Claimed_by_team
                              ? data.Claimed_by_team.tenantTitle
                              : "Not Claimed"}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-sm font-medium text-gray-500">Tags</h2>
                    <ul role="list" className="mt-2 leading-8">
                      <li className="inline">
                        <a
                          href="#"
                          className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                        >
                          <div className="absolute flex flex-shrink-0 items-center justify-center">
                            <span
                              className="h-1.5 w-1.5 rounded-full bg-rose-500"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3.5 text-sm font-medium text-slate-800">
                            {data.Competency && data.Competency.Name}
                          </div>
                        </a>{" "}
                      </li>
                      <li className="inline">
                        <a
                          href="#"
                          className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                        >
                          <div className="absolute flex flex-shrink-0 items-center justify-center">
                            <span
                              className="h-1.5 w-1.5 rounded-full bg-indigo-500"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3.5 text-sm font-medium text-slate-800">
                            Accessibility
                          </div>
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </main>
        {/* </div> */}
      </div>
    </>
  );
}
