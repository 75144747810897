import React from "react";
import Skeleton from "../../components/Skeleton";
import { NavLink } from "react-router-dom";

function DashboardCard10(props) {
  console.info("DB CARD 10", props);
  const loading = props.loading;
  const title = props.title;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;

  const data = props.data;
  const noShadow = props.noShadow ? true : false;
  const arr_history = [];
  console.info("DB Card 10 HISTORY", data);

  // data !== "loading" &&
  //   props.from !== "employees" &&
  // data.map((item) => {
  //   const histObj = {};
  //   // console.info("DB Card 10 HISTORY ITEM", item);
  //   histObj["id"] = item.assessment_id;
  //   histObj["type"] = item.assessment_type;
  //   histObj["status"] = item.assessment_status;
  //   histObj["score"] = item.risk_score;
  //   histObj["person"] = item.taken_by;
  //   histObj["date"] = item.assessment_date;
  //   arr_history.push(histObj);
  // });

  // data.map((item) => {
  //   const histObj = {};
  //   // console.info("DB Card 10 HISTORY ITEM", item);
  //   histObj["name"] = item[0];
  //   histObj["burden"] = item[1];
  //   histObj["effort"] = item[1];

  //   arr_history.push(histObj);
  // });

  console.info("HISTORY ARRAY", arr_history);

  return (
    <div
      className={`row-span-${rowspan}  col-span-full xl:col-span-${colSpan} bg-white ${
        noShadow ? "" : "shadow-lg border border-gray-200"
      } rounded-sm  overflow-hidden grow-0`}
    >
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="flex items-left font-semibold text-slate-800">
          {title}
        </h2>
      </header>
      {loading ? (
        <Skeleton type="spinner" color="gray" fill="purple" />
      ) : (
        <div className="p-3">
          {/* Card content */}
          {/* "Today" group */}
          <div>
            {/* <header className="text-xs uppercase text-slate-400 bg-gray-50 rounded-sm font-semibold p-2">
              Today
            </header> */}
            <ul className="my-1">
              {/* Item */}
              {data.map((i) => {
                return (
                  <li className="flex px-2">
                    <div
                      className={`items-center m-auto justify-center text-center p-auto w-9 h-9 rounded-full shrink-0 ${
                        i.status == "Finished" ? "bg-green-500" : "bg-green-500"
                      } my-2 mr-3`}
                    >
                      {i.status && i.status == "Finished" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-green-50 ml-1.5 mt-1.5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      ) : (
                        // <svg
                        //   xmlns="http://www.w3.org/2000/svg"
                        //   class="text-primary-50 m-auto"
                        //   fill="none"
                        //   // viewBox="0 0 40 40"
                        //   stroke="currentColor"
                        //   stroke-width="2"
                        // >
                        //   <path
                        //     stroke-linecap="round"
                        //     stroke-linejoin="round"
                        //     d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        //   />
                        // </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-primary-50 ml-2.5 mt-2.5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                        // <svg
                        //   className="w-9 h-9 fill-current text-primary-50"
                        //   // viewBox="0 0 36 36"
                        // >
                        //   <path d="M18 10c-4.4 0-8 3.1-8 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L18.9 22H18c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                        // </svg>
                      )}
                    </div>
                    <div className="grow flex items-center border-b border-gray-100 text-sm py-2">
                      <div className="grow flex justify-between">
                        <div className="self-center">
                          <a
                            className="font-medium text-slate-800 hover:text-slate-800"
                            href="#0"
                          ></a>{" "}
                          We suggest working on{" "}
                          <span className="font-bold">{i.Name} </span>because
                          the current burden is{" "}
                          <span className="font-bold">
                            {i.Score} hours per week
                          </span>{" "}
                          and the effort is{" "}
                          <span className="font-bold">{i.Effort} days</span> .
                          You will break even{" "}
                          <span className="font-bold">
                            {i["calculations"][
                              "Break even point in weeks"
                            ].toFixed(2)}{" "}
                            weeks after completion
                          </span>{" "}
                          saving you{" "}
                          <span className="font-bold">
                            {i["calculations"][
                              "First year savings (days)"
                            ].toFixed(2)}{" "}
                            days
                          </span>{" "}
                          the remainder of the year.
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* "Yesterday" group */}
          {/* <div>
          <header className="text-xs uppercase text-slate-400 bg-gray-50 rounded-sm font-semibold p-2">
            Yesterday
          </header>
          <ul className="my-1">
            <li className="flex px-2">
              <div className="w-9 h-9 rounded-full shrink-0 bg-light-blue-500 my-2 mr-3">
                <svg
                  className="w-9 h-9 fill-current text-light-blue-50"
                  viewBox="0 0 36 36"
                >
                  <path d="M23 11v2.085c-2.841.401-4.41 2.462-5.8 4.315-1.449 1.932-2.7 3.6-5.2 3.6h-1v2h1c3.5 0 5.253-2.338 6.8-4.4 1.449-1.932 2.7-3.6 5.2-3.6h3l-4-4zM15.406 16.455c.066-.087.125-.162.194-.254.314-.419.656-.872 1.033-1.33C15.475 13.802 14.038 13 12 13h-1v2h1c1.471 0 2.505.586 3.406 1.455zM24 21c-1.471 0-2.505-.586-3.406-1.455-.066.087-.125.162-.194.254-.316.422-.656.873-1.028 1.328.959.878 2.108 1.573 3.628 1.788V25l4-4h-3z" />
                </svg>
              </div>
              <div className="grow flex items-center border-b border-gray-100 text-sm py-2">
                <div className="grow flex justify-between">
                  <div className="self-center">
                    <a
                      className="font-medium text-slate-800 hover:text-slate-800"
                      href="#0"
                    >
                      240+
                    </a>{" "}
                    users have subscribed to{" "}
                    <a className="font-medium text-slate-800" href="#0">
                      Newsletter #1
                    </a>
                  </div>
                  <div className="shrink-0 self-end ml-2">
                    <a
                      className="font-medium text-primary-500 hover:text-primary-600"
                      href="#0"
                    >
                      View<span className="hidden sm:inline"> -&gt;</span>
                    </a>
                  </div>
                </div>
              </div>
            </li>

            <li className="flex px-2">
              <div className="w-9 h-9 rounded-full shrink-0 bg-primary-500 my-2 mr-3">
                <svg
                  className="w-9 h-9 fill-current text-primary-50"
                  viewBox="0 0 36 36"
                >
                  <path d="M18 10c-4.4 0-8 3.1-8 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L18.9 22H18c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                </svg>
              </div>
              <div className="grow flex items-center text-sm py-2">
                <div className="grow flex justify-between">
                  <div className="self-center">
                    The post{" "}
                    <a className="font-medium text-slate-800" href="#0">
                      Post Name
                    </a>{" "}
                    was suspended by{" "}
                    <a
                      className="font-medium text-slate-800 hover:text-slate-800"
                      href="#0"
                    >
                      Nick Mark
                    </a>
                  </div>
                  <div className="shrink-0 self-end ml-2">
                    <a
                      className="font-medium text-primary-500 hover:text-primary-600"
                      href="#0"
                    >
                      View<span className="hidden sm:inline"> -&gt;</span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div> */}
        </div>
      )}
    </div>
  );
}

export default DashboardCard10;
