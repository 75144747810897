import React, { useEffect } from "react";
import Skeleton from "../../components/Skeleton";
import { NavLink } from "react-router-dom";
import StackedListTwoColumn from "../../components/StackedListTwoColumn";
import RightActionArea from "../../components/ActionAreaRight";
import { _impediments } from "../../modules/impediments";
import Tabs from "../../pages/component/Tabs";

function Leaderboard(props) {
  console.info("Leaderboard", props);
  //   const loading = props.loading;

  const title = props.title;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;
  const data = props.data;
  const noShadow = props.noShadow ? true : false;
  const arr_history = [];
  const [initialImpediments, setInitialImpediments] = React.useState([]);
  const [impediments, setImpediments] = React.useState([]);
  const [competencies, setCompetencies] = React.useState(props.data);
  const [loading, setLoading] = React.useState(true);
  const [selectedTab, setSelectedTab] = React.useState("Maturity");

  const tabs = [
    {
      name: "Maturity",
      href: "#",
      current: true,
    },
    {
      name: "Burden",
      href: "#",
      current: true,
    },
  ];

  useEffect(() => {
    (async () => {
      // setInitialImpediments(props.data);
      // setCompetencies(props.data);
      // setImpediments(props.data);

      const impediments = await _impediments.getImpedimentSummary();
      if (impediments && impediments.data) {
        setInitialImpediments(impediments.data.resArray);
        setImpediments(
          impediments.data.resArray
            .sort((a, b) => {
              return b.resolvedBurden - a.resolvedBurden;
            })
            .slice(0, 5)
        );
        setLoading(false);
        console.info("Leaderboard data", impediments.data.resArray);
      }
    })();
  }, []);

  const handleSetSelectedTab = (tab, index) => {
    console.info("handleSetSelectedTab", tab, index);
    //change sort of impediments based on selectedTab
    // if (tab === "Resolved Burden") {
    //   setType("burden");
    //   setImpediments(
    //     initialImpediments
    //       .sort((a, b) => {
    //         return b.resolvedBurden - a.resolvedBurden;
    //       })
    //       .slice(0, 5)
    //   );
    // }
    // if (tab === "Total Burden") {
    //   setType("burden");
    //   setImpediments(
    //     initialImpediments
    //       .sort((a, b) => {
    //         return b.burden - a.burden;
    //       })
    //       .slice(0, 5)
    //   );
    // }

    // if (tab === "Maturity") {
    //   setType("competencies");
    //   setImpediments(
    //     props.data
    //       .sort((a, b) => {
    //         return b.setMaturityScore - a.setMaturityScore;
    //       })
    //       .slice(0, 5)
    //   );
    // }
    setSelectedTab(tab);
  };

  return (
    <div
      className={`row-span-${rowspan}  col-span-full xl:col-span-${colSpan} bg-white ${
        noShadow ? "" : "shadow-lg border border-gray-200"
      } rounded-sm  overflow-hidden grow-0`}
    >
      <header className="flex items-center justify-between  px-5 py-4 border-b border-gray-100">
        <h2 className="  font-semibold text-slate-800">{title}</h2>
        <RightActionArea>
          <Tabs
            tabs={tabs}
            setSelectedTab={handleSetSelectedTab}
            selectedTab={selectedTab}
          />
        </RightActionArea>
      </header>
      {loading ? (
        <Skeleton type="spinner" color="gray" fill="purple" />
      ) : (
        <div className="p-3">
          <div>
            {selectedTab === "Maturity" && (
              <StackedListTwoColumn
                key={"stacked_list"}
                data={props.data}
                type={"competencies"}
              />
            )}
            {selectedTab === "Burden" && (
              <StackedListTwoColumn
                key={"stacked_list"}
                data={impediments}
                type={"burden"}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Leaderboard;
