import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { _auth } from "../modules/_auth";
import { _onboarding_options } from "../modules/_onboarding_options";

const roles = _onboarding_options.getEmployeeRoles();
const departments = _onboarding_options.getEmployeeDepartments();
const companySize = _onboarding_options.getCompanySize();
const companyLocation = _onboarding_options.getCompanyLocation();

const mailingLists = [
  {
    id: 1,
    title: "Food & Beverage",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 2,
    title: "Hospitality",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 3,
    title: "Retail",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 4,
    title: "Education",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 5,
    title: "Healthcare",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 6,
    title: "Financial",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 7,
    title: "Consumer Services",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 8,
    title: "Manufacturing",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 9,
    title: "Government",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 10,
    title: "Transportation",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 11,
    title: "Media",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 12,
    title: "Communications",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  // {
  //   id: 13,
  //   title: "Energy",
  //   description: "Last message sent an hour ago",
  //   users: "621 users",
  // },
  // {
  //   id: 14,
  //   title: "Technology",
  //   description: "Last message sent 2 weeks ago",
  //   users: "1200 users",
  // },
  // {
  //   id: 15,
  //   title: "Other",
  //   description: "Last message sent 4 days ago",
  //   users: "2740 users",
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SingleOptions(props) {
  const options = props.options;
  const setStep = props.setStep;
  const [selectedOption, setselectedOption] = useState(
    props.selected ? props.selected : ""
  );
  console.log(selectedOption, "selected role");
  console.log(props.selected, "prop role");
  let profile = _auth.getUserProfile();
  const handleClick = (e) => {
    console.log(e, "erole");
    setselectedOption(e.name);
    props.onSelectOption(e.name);
    console.log(props.currentStep);
    console.log(profile.role);
    ////debugger;

    if (props.currentStep < props.steps.length) {
      // setTimeout(function () {
      setStep(props.currentStep + 1);
      // }, 250);
    }
  };
  return (
    <div className="space-y-4 mb-5 ">
      <h1 className="text-3xl text-slate-800 font-bold mb-6">{props.title}</h1>
      <div
      // className="overflow-y-auto"
      // style={{ padding: "25px", minHeight: "60vh", maxHeight: "60vh" }}
      >
        <RadioGroup value={props.selected} onChange={handleClick}>
          {/* <RadioGroup.Label className="text-base font-medium text-slate-800">
            Select your role
          </RadioGroup.Label> */}

          <div className="mt-4 grid grid-cols-3 gap-y-6  sm:grid-cols-2 sm:gap-x-8">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.id}
                value={option}
                className={({ checked, active }) =>
                  classNames(
                    checked || props.selected === option.name
                      ? "border-transparent"
                      : "border-gray-300",
                    active ? "ring-2 ring-primary-500" : "",
                    "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                  )
                }
              >
                {({ checked, active }) => (
                  <>
                    <div className="flex flex  items-center">
                      <div className="flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className=" text-sm font-medium text-slate-800 "
                        >
                          {option.name}
                        </RadioGroup.Label>
                      </div>
                    </div>
                    <CheckCircleIcon
                      className={classNames(
                        !checked ? "invisible" : "",
                        "h-5 w-5 text-primary-600"
                      )}
                      aria-hidden="true"
                    />
                    <div
                      className={classNames(
                        active ? "border" : "border-2",
                        checked || props.selected === option.name
                          ? "border-primary-500"
                          : "border-transparent",
                        "absolute -inset-px rounded-lg pointer-events-none"
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>

    // <div className="space-y-4 mb-20 ">
    //   <h1 className="text-3xl text-slate-800 font-bold mb-6">{title}</h1>
    //   <div
    //     className="overflow-y-auto"
    //     style={{ padding: "25px", maxHeight: "50vh" }}
    //   >
    //     {questions.map((question) => (
    //       <div className="mb-10">
    //         <p className="text-sm leading-5 text-slate-500">{question.title}</p>
    //         <fieldset className="mt-4">
    //           <legend className="sr-only">Notification method</legend>

    //           <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
    //             {answerOpts.map((department) => (
    //               <RadioGroup
    //                 value={selectedOption}
    //                 onChange={setselectedOption}
    //               >
    //                 <RadioGroup.Option
    //                   key={question.id + "-" + department.id}
    //                   value={question.id + "-" + department.id}
    //                   className={({ checked, active }) =>
    //                     classNames(
    //                       checked ? "border-transparent" : "border-gray-300",
    //                       active ? "ring-2 ring-primary-500" : "",
    //                       "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
    //                     )
    //                   }
    //                 >
    //                   {({ checked, active }) => (
    //                     <>
    //                       <div className="flex-1 flex  items-center">
    //                         <div className="flex flex-col">
    //                           <RadioGroup.Label
    //                             as="span"
    //                             className="block text-sm font-medium text-slate-800 "
    //                           >
    //                             {department.title}
    //                           </RadioGroup.Label>
    //                         </div>
    //                       </div>
    //                       <CheckCircleIcon
    //                         className={classNames(
    //                           !checked ? "invisible" : "",
    //                           "h-5 w-5 text-primary-600"
    //                         )}
    //                         aria-hidden="true"
    //                       />
    //                       <div
    //                         className={classNames(
    //                           active ? "border" : "border-2",
    //                           checked
    //                             ? "border-primary-500"
    //                             : "border-transparent",
    //                           "absolute -inset-px rounded-lg pointer-events-none"
    //                         )}
    //                         aria-hidden="true"
    //                       />
    //                     </>
    //                   )}
    //                 </RadioGroup.Option>
    //               </RadioGroup>
    //             ))}
    //           </div>
    //         </fieldset>
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
}

export default SingleOptions;
