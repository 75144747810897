import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";

export default function FlyoutMenu({
  title,
  options,
  className,
  isScrolled,
  onClick,
}) {
  const [scrolled, setScrolled] = React.useState(isScrolled);
  const [isOpen, setIsOpen] = useState(false); // State to control Popover open state

  useEffect(() => {
    setScrolled(isScrolled);
  }, [isScrolled]);
  return (
    <Popover
      className="relative"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Popover.Button
        onMouseEnter={() => setIsOpen(true)} // Open Popover on hover
        onMouseLeave={() => setIsOpen(false)} // Close Popover when mouse leaves
        className={`border-none focus:outline-none inline-flex items-center gap-x-1  font-semibold leading-6 ${
          scrolled ? "text-gray-700" : "text-white"
        } `}
      >
        <span>{title}</span>
        <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
      </Popover.Button>

      <Popover.Panel
        transition
        onMouseEnter={() => setIsOpen(true)} // Keep Popover open when mouse is over the panel
        onMouseLeave={() => setIsOpen(false)} // Close Popover when mouse leaves the panel
        className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="w-screen max-w-sm flex-auto rounded-3xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
          {options &&
            options.map((item) => (
              <div
                key={item.name}
                className="relative rounded-lg p-4 hover:bg-gray-50 text-wrap"
              >
                <a
                  href={"#"}
                  onClick={() => {
                    onClick(item.id, item.href);
                  }}
                  className="font-semibold text-gray-900"
                >
                  {item.name}
                  <span className="absolute inset-0" />
                </a>
                <p className="mt-1 text-gray-600 whitespace-normal">
                  {item.description}
                </p>
              </div>
            ))}
        </div>
      </Popover.Panel>
    </Popover>
  );
}
