import React from "react";

const DevNote = ({ children }) => {
  const showNotes = true;
  return showNotes ? (
    <div className="z-50 absolute top-0 right-0 w-96 bg-brandGreen-500 text-white p-4">
      <div className="text-xl pb-2">Dev Note:</div>
      {children}
    </div>
  ) : null;
};

export default DevNote;
