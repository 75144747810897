export function Paragraph({ children, variant }) {
  return (
    <p
      className={`mt-6 text-lg leading-8 ${
        variant == "dark" ? "text-slate-300" : "text-slate-700"
      }`}
    >
      {children}
    </p>
  );
}
